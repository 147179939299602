import React, { useState } from 'react'
import TopRightProfile from '../../TopRightProfile';
import { toast } from 'react-toastify';
import { axiosClientPy } from '../../../utils';

export default function CreateProjectDaoStep4({ projectData, setStep }) {
  const [input, setInput] = useState({
    VoteAgainstList: false,
    VoteForList: false,
    ListOfVote: false,
    ListMyPost: false
  })

  const onSubmit = async (e) => {
    e.preventDefault();
    const headers = {
      Authorization: "Token " + window.sessionStorage.getItem("USER_TOKEN")
    }
    try {
      let typeRes = await axiosClientPy.get("/main/project-function", { headers });
      let selectedType = typeRes?.data?.data;
      selectedType = selectedType?.filter(ele => input[ele.name]);
      selectedType = [...selectedType, ...projectData?.contract_functions_details];
      console.log(selectedType);
      const mData = {
        "project_id": projectData?.uuid,
        "contract_functions": selectedType.map(ele => ele.uuid),
        "step_count": 7
      }
      if (selectedType.length > 0) {
        const res = await axiosClientPy.patch("/project/project-creation", mData, { headers });
        window.location.reload();
      } else {
        toast.error("Please select a valid Contract Type!!!");
      }
    } catch (error) {
      toast.error("Internal Server Error!!!")
    }
  }
  return (
    <section className="pe-0 main-content">
      <div className="pt-0 main-content-inner box-steps--illustration">
        <TopRightProfile />
        {/*----- Completion stats row -----*/}
        <div className="primary-box completion-row completion-row--back">
          <div className="completion-row__left">
            <a href="#" className="redirect-back">
              <img src="../assets/images/icons/back-arrow.svg" alt="Back arrow" />
            </a>
            <div className>
              <h1 className="fw600 text-white">DAO</h1>
              <p className="m-0 grayText">
                Merging technology to remove third parties and centralized
                institutions from financial transactions.
              </p>
            </div>
          </div>
          <div className="completion-row__right">
            <img className="logo-crypto" src="../assets/images/defi-logo.svg" alt="" />
          </div>
        </div>
        {/* ============================== */}
        <div className="box-steps">
          <h2 className="text-center mb-0 fst-italic fw700">
            Assign your Token a unique Name!
          </h2>
          <p className="text-center grayText--lighter">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vehicula
            massa in enim luctus. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Vehicula massa in enim luctus. Rutrum arcu.
          </p>
          <div className="w-100 form-box form-box--purple">
            <form onSubmit={onSubmit} className="form">
              <div className="top">
                <div className="fw600 m-0 form-group form-group--checkbox">
                  <input id="one" type="checkbox" checked={input.VoteAgainstList} onChange={() => { setInput(prev => ({ ...prev, VoteAgainstList: !prev.VoteAgainstList })) }} />
                  <label htmlFor="one">
                    <b />
                    Vote Against List
                  </label>
                </div>
                <div className="mt-4 fw600 m-0 form-group form-group--checkbox">
                  <input id="two" type="checkbox" checked={input.VoteForList} onChange={() => { setInput(prev => ({ ...prev, VoteForList: !prev.VoteForList })) }} />
                  <label htmlFor="two">
                    <b />
                    Vote For List
                  </label>
                </div>
              </div>
              <div className="top">
                <div className="mt-4 fw600 m-0 form-group form-group--checkbox">
                  <input id="three" type="checkbox" checked={input.ListOfVote} onChange={() => { setInput(prev => ({ ...prev, ListOfVote: !prev.ListOfVote })) }} />
                  <label htmlFor="three">
                    <b />
                    List Of Vote
                  </label>
                </div>
                <div className="mt-4 fw600 m-0 form-group form-group--checkbox">
                  <input id="four" type="checkbox" checked={input.ListMyPost} onChange={() => { setInput(prev => ({ ...prev, ListMyPost: !prev.ListMyPost })) }} />
                  <label htmlFor="four">
                    <b />
                    List My Post
                  </label>
                </div>
              </div>
              <div className="btnGroup mt-5">
                <button type="submit" className="btn rounded-0 primary-box btn--primary">
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}
