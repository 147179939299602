import { axiosClient, axiosClientPy } from "./axiosClient";
import { APP_DATA } from "./constants";
import countryList from "./countryList.json";

export const networks = ["binance", "ethereum", "polygon", "cardano"];
// export const networksChainID = { "binance": 56, "ethereum": 1, "polygon": 137, "cardano": 0 };
export const networksChainID = { "binance": 97, "ethereum": 5, "polygon": 80001, "cardano": 0 };


// export const networkDetails = {
//   "Binance": [{
//     chainId: '0x38',
//     chainName: 'BNB Smart Chain Mainnet',
//     nativeCurrency: {
//       name: 'BNB',
//       symbol: 'BNB',
//       decimals: 18
//     },
//     rpcUrls: ['https://bsc-dataseed1.binance.org/'],
//     blockExplorerUrls: ['https://bscscan.com/']
//   }],
//   "Polygon": [{
//     chainId: '0x89',
//     chainName: 'Polygon Mainnet',
//     nativeCurrency: {
//       name: 'MATIC',
//       symbol: 'MATIC',
//       decimals: 18
//     },
//     rpcUrls: ['https://polygon-rpc.com/'],
//     blockExplorerUrls: ['https://polygonscan.com/']
//   }],
//   "Ethereum": [{
//     chainId: '0x1',
//     chainName: 'Ethereum Mainnet',
//     nativeCurrency: {
//       name: 'ETH',
//       symbol: 'ETH',
//       decimals: 18
//     },
//     rpcUrls: ['https://mainnet.infura.io/v3/'],
//     blockExplorerUrls: ['https://etherscan.io']
//   }]
// }
export const networkDetails = {
  "Binance": [{
    chainId: '0x61',
    chainName: 'BNB Smart Chain Testnet',
    nativeCurrency: {
      name: 'tBNB',
      symbol: 'tBNB',
      decimals: 18
    },
    rpcUrls: ['https://data-seed-prebsc-1-s3.binance.org:8545/'],
    blockExplorerUrls: ['https://testnet.bscscan.com/']
  }],
  "Polygon": [{
    chainId: '0x13881',
    chainName: 'Polygon Mumbai',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18
    },
    rpcUrls: ['https://matic-mumbai.chainstacklabs.com'],
    blockExplorerUrls: ['https://mumbai.polygonscan.com/']
  }],
  "Ethereum": [{
    chainId: '0x5',
    chainName: 'Goerli test network',
    nativeCurrency: {
      name: 'Goerli',
      symbol: 'GETH',
      decimals: 18
    },
    rpcUrls: ['https://goerli.blockpi.network/v1/rpc/public'],
    blockExplorerUrls: ['https://goerli.etherscan.io/']
  }]
}


function formatAMPM(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'P.M.' : 'A.M.';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  let strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

export const getDateFormat = (data) => {
  const mS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  const date = new Date(data);
  const d1 = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "-" + (mS[date.getMonth()]) + "-" + (date.getFullYear());
  const d2 = `${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}hrs:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}min (IST)`;
  const d3 = formatAMPM(date);
  const d4 = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " " + (mS[date.getMonth()]) + " " + (date.getFullYear());

  const d5 = `${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()} `;
  return [d1, d2, d3, d4, d5];
}

export { axiosClient, axiosClientPy, APP_DATA, countryList };