import React, { useState } from 'react'
import { createGlobalStyle } from 'styled-components';
import TopRightProfile from '../../TopRightProfile';
import useRazorpay from 'react-razorpay';
import PaymentOptionsModal from '../Modals/PaymentOptionsModal'
import { ethers } from 'ethers';
import { APP_DATA, axiosClientPy } from '../../../utils';
import { toast } from 'react-toastify';
import Loading from '../../Loading';
import useFetch from '../../../utils/hooks/useFetch';

const GlobalStyle = createGlobalStyle(`
.ckeditorSection .ck.ck-editor__main > .ck-editor__editable {
  min-height: 30vh;
}
`);

export default function CreateProjectServiceType1({ projectData, step, setStep }) {
  const [loading, setLoading] = useState(false);
  const Razorpay = useRazorpay();
  const services = useFetch("/project/service");
  const headers = {
    Authorization: "Token " + window.sessionStorage.getItem("USER_TOKEN")
  }
  const web2Fun = async () => {
    let orderInfo = {};
    try {
      const apiRes = await axiosClientPy.post("/main/init-payment", { project_id: projectData?.uuid }, { headers: headers });
      orderInfo = apiRes?.data?.data;
      console.log(orderInfo);
      const options = {
        key: orderInfo.mrchant_key,
        amount: orderInfo.paise_amount,
        currency: APP_DATA.currency,
        name: "Chainery",
        description: "Test Transaction",
        image: window.location.host + "/assets/images/logo-white.png",
        order_id: orderInfo.razorpay_order_id,
        handler: function (response) {
          axiosClientPy.post("/main/payment-callback", response, { headers }).then((paymentCallbackResponse) => {
            axiosClientPy.patch("/project/project-creation", { project_id: projectData?.uuid, transaction: paymentCallbackResponse?.data?.data?.uuid }, { headers }).then(finalRes => {
              window.location.reload();
            }).catch(err => {
              toast.error("Payment Done But Linked With Your Account Failed!!!");
            })
          }).catch(err => {
            toast.error("Payment Failed!!!");
          })
        },
        prefill: {
          name: "Aditya Sharma",
          email: "aditya242401@gmail.com",
          contact: "6392919732",
        },
        theme: {
          color: "#000000",
        },
      };

      const rzp1 = new Razorpay(options);

      rzp1.on("payment.failed", function (response) {
        toast.error(response.error.description);
      });

      rzp1.open();
    } catch (error) {
      console.log(error, "Aditya");
    }
  }
  const web3Fun = async () => {
    if (window.ethereum) {
      setLoading(true);
      document.getElementById("paymentOptionsModalClose").click();
      try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const network = await provider.getNetwork();
        const signer = await provider.getSigner();
        let smart_contract, selectedNetwork;
        if (projectData?.contract_network_details?.name) {
          selectedNetwork = projectData?.contract_network_details?.name;
          if (selectedNetwork === "Binance") smart_contract = new ethers.Contract(APP_DATA.bscSM, APP_DATA.abi, signer);
          else if (selectedNetwork === "Ethereum") smart_contract = new ethers.Contract(APP_DATA.goerliSM, APP_DATA.abi, signer);
          else if (selectedNetwork === "Polygon") smart_contract = new ethers.Contract(APP_DATA.polygonSM, APP_DATA.abi, signer);
        }
        const inp = ethers.parseUnits("0.000005", "ether");
        const trans = await smart_contract.payment(inp, { value: inp });
        const dTrans = await trans.wait();
        const apiReq = {
          project_id: projectData?.uuid,
          web3_transaction_id: dTrans?.hash ? dTrans.hash : dTrans.transactionHash
        }
        const orderData = await axiosClientPy.post("/main/create-order", apiReq, { headers });
        console.log(orderData);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  }

  const mScript = `
  let mEditor;
  CKEDITOR.ClassicEditor.create(document.getElementById("editorTnc"), {
    toolbar: {
      items: [
        "exportPDF",
        "exportWord",
        "|",
        "findAndReplace",
        "selectAll",
        "|",
        "heading",
        "|",
        "bold",
        "italic",
        "strikethrough",
        "underline",
        "code",
        "subscript",
        "superscript",
        "removeFormat",
        "|",
        "bulletedList",
        "numberedList",
        "todoList",
        "|",
        "outdent",
        "indent",
        "|",
        "undo",
        "redo",
        "-",
        "fontSize",
        "fontFamily",
        "fontColor",
        "fontBackgroundColor",
        "highlight",
        "|",
        "alignment",
        "|",
        "link",
        "insertImage",
        "blockQuote",
        "insertTable",
        "mediaEmbed",
        "codeBlock",
        "htmlEmbed",
        "|",
        "specialCharacters",
        "horizontalLine",
        "pageBreak",
        "|",
        "textPartLanguage",
        "|",
        "sourceEditing",
      ],
      shouldNotGroupWhenFull: true,
    },
    list: {
      properties: {
        styles: true,
        startIndex: true,
        reversed: true,
      },
    },
    heading: {
      options: [
        { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        {
          model: "heading5",
          view: "h5",
          title: "Heading 5",
          class: "ck-heading_heading5",
        },
        {
          model: "heading6",
          view: "h6",
          title: "Heading 6",
          class: "ck-heading_heading6",
        },
      ],
    },
    placeholder: "Write the details here..",
    fontFamily: {
      options: [
        "default",
        "Arial, Helvetica, sans-serif",
        "Courier New, Courier, monospace",
        "Georgia, serif",
        "Lucida Sans Unicode, Lucida Grande, sans-serif",
        "Tahoma, Geneva, sans-serif",
        "Times New Roman, Times, serif",
        "Trebuchet MS, Helvetica, sans-serif",
        "Verdana, Geneva, sans-serif",
      ],
      supportAllValues: true,
    },
    fontSize: {
      options: [10, 12, 14, "default", 18, 20, 22],
      supportAllValues: true,
    },
    htmlSupport: {
      allow: [
        {
          name: /.*/,
          attributes: true,
          classes: true,
          styles: true,
        },
      ],
    },
    htmlEmbed: {
      showPreviews: true,
    },
    link: {
      decorators: {
        addTargetToExternalLinks: true,
        defaultProtocol: "https://",
        toggleDownloadable: {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "file",
          },
        },
      },
    },
    mention: {
      feeds: [
        {
          marker: "@",
          feed: [
            "@apple",
            "@bears",
            "@brownie",
            "@cake",
            "@cake",
            "@candy",
            "@canes",
            "@chocolate",
            "@cookie",
            "@cotton",
            "@cream",
            "@cupcake",
            "@danish",
            "@donut",
            "@dragée",
            "@fruitcake",
            "@gingerbread",
            "@gummi",
            "@ice",
            "@jelly-o",
            "@liquorice",
            "@macaroon",
            "@marzipan",
            "@oat",
            "@pie",
            "@plum",
            "@pudding",
            "@sesame",
            "@snaps",
            "@soufflé",
            "@sugar",
            "@sweet",
            "@topping",
            "@wafer",
          ],
          minimumCharacters: 1,
        },
      ],
    },
    removePlugins: [
      "CKBox",
      "CKFinder",
      "EasyImage",
      "RealTimeCollaborativeComments",
      "RealTimeCollaborativeTrackChanges",
      "RealTimeCollaborativeRevisionHistory",
      "PresenceList",
      "Comments",
      "TrackChanges",
      "TrackChangesData",
      "RevisionHistory",
      "Pagination",
      "WProofreader",
      "MathType",
    ],
  }).then( newEditor => {
    mEditor = newEditor;
    mEditor.setData("${services.data?.filter(ele => ele.name === projectData?.name).length > 0 ? services.data?.filter(ele => ele.name === projectData?.name)[0].default : ""}")
  })
  `;

  React.useEffect(() => {
    if (services.data) {
      if (!document.getElementById("CkEditorMarketing")) {
        const script = document.createElement("script");
        script.innerHTML = mScript;
        script.id = "CkEditorMarketing";
        document.body.appendChild(script);
      } else {
        document.body.removeChild(document.getElementById("CkEditorMarketing"));
        const script = document.createElement("script");
        script.innerHTML = mScript;
        script.id = "CkEditorMarketing";
        document.body.appendChild(script);
      }
    }
  }, [step, services.data]);

  const onClickOverview = () => {
    setStep(2);
  }

  const publish = async () => {
    if (services.data?.filter(ele => ele.name === projectData?.name).length > 0) {
      try {
        setLoading(true);
        const serviceId = services.data?.filter(ele => ele.name === projectData?.name)[0].uuid;
        const details = document.getElementsByClassName("ck-restricted-editing_mode_standard")[0].innerHTML;
        const projectId = projectData?.uuid;
        const mData = new FormData();
        mData.append("service_id", serviceId);
        mData.append("project_id", projectId);
        mData.append("details", details);
        let mFiles = [];
        for (let i = 0; i < document.getElementById("inputFileUpload").files.length; i++) {
          mFiles.push(document.getElementById("inputFileUpload").files[i]);
        }
        mData.append("files", mFiles);

        const res = await axiosClientPy.post("/project/service", mData, { headers: { ...headers, 'Content-Type': 'multipart/form-data' } });
        setLoading(false);
        setStep(3);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  }

  return (
    <>
      <GlobalStyle />
      <Loading loading={loading} />
      <section className="pe-0 main-content stepper-content">
        <div className="main-content-inner">
          <TopRightProfile />
          <div className="stepperForm">
            <div className="leftStepper">
              {/*----- Completion stats row -----*/}
              <div className="primary-box py-3 mt-0 completion-row completion-row--back">
                <div className="completion-row__left">
                  <a href="/new-project" className="redirect-back">
                    <img src="../assets/images/icons/back-arrow.svg" alt="Back arrow" />
                  </a>
                  <div >
                    <h1 className="fw600 text-white">
                      Project By Services - {projectData?.name}
                    </h1>
                    <p className="m-0">List on DEX, Task Management, Tokenomics</p>
                    <div className="mb-0">
                      <span className="fw700">Last Updated: </span>
                      <span className="ms-1"> 01-June-2022 - 03hr:55min (EST)</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile-edit bg-white">
                <div className="white-paper">
                  <div className="editable-section editable-latest">
                    <h3 className="fw700 text-center">
                      Write your requirements here!
                    </h3>
                    <div className="mb-3 ckeditorSection">
                      <div id="editorTnc" />
                    </div>
                    <div className="row mb-4 justify-content-end">
                      <div className="col-lg-3" />
                      <div className="col-lg-6 text-end order-lg-3">
                        <a onClick={onClickOverview} role="button" className="btn me-3 rounded-0 btn--primary-outlined">
                          Overview
                        </a>
                        {
                          projectData?.transaction ?
                            <button onClick={() => { publish() }} type="button" className="btn rounded-0 border-0 primary-box btn--primary">
                              Publish
                            </button>
                            :
                            <>
                              <button data-bs-target="#paymentOptionsModal" data-bs-toggle="modal" className="btn rounded-0 border-0 primary-box btn--primary me-3">
                                <span className="fw400">Proceed To Pay</span>
                              </button>
                              <span className="btn btn--notpaid btn--notpaid-bgw me-3">
                                <span className="btn__helper">
                                  <img className="btn__lock" alt="pay first" src="../assets/images/icons/lock-dark.svg" />
                                  <span className="btn__helptxt">
                                    <span className="d-block mb-2">Proceed with your payment first</span>
                                    <button data-bs-target="#paymentOptionsModal" data-bs-toggle="modal" className="btn btn--primary">
                                      <span className="fw600">Proceed To Pay</span>
                                    </button>
                                  </span>
                                </span>
                                <span className="btn__text">Publish</span>
                              </span>
                            </>
                        }
                      </div>
                      <div className="col-lg-3 order-lg-2 pt-5 pt-lg-0">
                        <h3 className="text-center mb-0 mt-2 fw700">Or</h3>
                      </div>
                    </div>
                    <div className="primaryText w-100 mw-100 chainery-upload-card chainery-upload-card--red upload-box upload-services-box">
                      <form encType='multipart/form-data'>
                        <input id="inputFileUpload" multiple={true} type="file" className="opacity-0 position-absolute w-100 h-100" />
                        <div className="chainery-upload-card__right text-center">
                          <div className="title">
                            <h2 className="fw600">
                              You can upload multiple documents to understand your
                              requirements well!
                            </h2>
                          </div>
                          <p className="fst-italic mw-100 mb-3 grayText--lighter file-size">
                            Upload your documents that tell us what you need well!
                          </p>
                          <button type="button" className="btn rounded-0 btn--primary-outlined">
                            Upload
                          </button>
                          <p className="fst-italic mw-100 mb-0 mt-2 grayText--lighter file-size">
                            File size max 2mb, jpg , png, jpeg.
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <PaymentOptionsModal web3Fun={web3Fun} web2Fun={web2Fun} />
    </>
  )
}
