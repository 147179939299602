import Cookies from 'js-cookie';
import React from 'react'
import TopRightProfile from '../TopRightProfile';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import { axiosClientPy } from '../../utils';

export default function CreateProjectStep3() {
  const [searchParams] = useSearchParams();
  const projectID = searchParams.get("projectID");

  const goToNextStep = async (data) => {
    const headers = {
      Authorization: "Token " + window.sessionStorage.getItem("USER_TOKEN")
    }
    try {
      let typeRes = await axiosClientPy.get("/main/contract-type", { headers });
      let selectedType = typeRes?.data?.data;
      selectedType = selectedType?.filter(ele => ele.name.toLowerCase() === data.toLowerCase());
      if (selectedType.length > 0) {
        const mData = new FormData();
        mData.append("project_id", projectID);
        mData.append("contract_type", selectedType[0].uuid);
        mData.append("step_count", 3);
        const res = await axiosClientPy.patch("/project/project-creation", mData, { headers });
        window.location.reload()
      } else {
        toast.error("Please select a valid Contract Type!!!");
      }
    } catch (error) {

    }
  }

  return (
    <>
      {/*------- Middle content -------*/}
      <section className="pe-0 main-content">
        <div className="pt-0 main-content-inner">
          <TopRightProfile />
          {/*----- Completion stats row -----*/}
          <div className="primary-box completion-row">
            <div className="completion-row__left">
              <button onClick={() => { window.location.href = "/new-project"; }} className="redirect-back">
                <img src="./assets/images/icons/back-arrow.svg" alt="Back arrow" />
              </button>
              <div >
                <h1 className="text-white fw600">Smart Contract List</h1>
                <p className="m-0 grayText">Create products or services</p>
                <div className="mb-0">
                  <span className="fw700">Last Updated: </span>
                  <span className="ms-1"> 01-June-2022 - 03hr:55min (EST)</span>
                </div>
              </div>
            </div>
          </div>
          {/* ============================== */}
          <div className="smart-contract">
            <div className="smart-contract__left">
              <ul className="list-inline smart-contract-cards">
                <li>
                  <a onClick={(e) => { e.preventDefault(); goToNextStep("ERC721"); }} className="cursor-pointer card-wrapper card-box--lightblue">
                    <div className="top-row-card">
                      <h3 className="primaryText mb-0 fw600 lh1">ERC - 721</h3>
                      <div className="redirect-link">
                        <img src="assets/images/icons/arrow-right.svg" alt="" />
                      </div>
                    </div>
                    <p className="grayText">
                      Basic NFT Standard, used to create unique tokens,
                      distinguished from others in the same collection.
                    </p>
                    <div className="bottom-row-card">
                      <img src="./assets/images/icons/wolf-logo-black.svg" alt="img" />
                      <img src="./assets/images/icons/nft-logo-black.svg" alt="img" />
                    </div>
                  </a>
                </li>
                <li>
                  <a onClick={(e) => { e.preventDefault(); goToNextStep("ERC1155"); }} className="cursor-pointer card-wrapper card-box--purple">
                    <div className="top-row-card">
                      <h3 className="primaryText mb-0 fw600 lh1">ERC - 1155</h3>
                      <div className="redirect-link">
                        <img src="assets/images/icons/arrow-right.svg" alt="" />
                      </div>
                    </div>
                    <p className="grayText">
                      A single interface that manages any combination of
                      multiple token types( fungible, non-fungible, etc).
                    </p>
                    <div className="bottom-row-card">
                      <img src="./assets/images/icons/multi-standard-token.svg" alt="img" />
                    </div>
                  </a>
                </li>
                <li>
                  <a onClick={(e) => { e.preventDefault(); goToNextStep("ERC20"); }} className="cursor-pointer card-wrapper card-box--red">
                    <div className="top-row-card">
                      <h3 className="primaryText mb-0 fw600 lh1">ERC - 20</h3>
                      <div className="redirect-link">
                        <img src="assets/images/icons/arrow-right.svg" alt="" />
                      </div>
                    </div>
                    <p className="grayText">
                      Most basic token standard, used to create interchangeable
                      tokens.
                    </p>
                    <div className="bottom-row-card">
                      <img src="./assets/images/icons/fungible-token.svg" alt="img" />
                    </div>
                  </a>
                </li>
                <li>
                  <a role="button" onClick={(e) => { e.preventDefault(); goToNextStep("DeFi"); }} className="cursor-pointer card-wrapper card-box--blue">
                    <div className="top-row-card">
                      <h3 className="primaryText mb-0 fw600 lh1">DeFi</h3>
                      <div className="redirect-link">
                        <img src="assets/images/icons/arrow-right.svg" alt="" />
                      </div>
                    </div>
                    <p className="grayText">
                      Users are then able to generate their own stable tokens by
                      staking the volatile tokens as collateral.
                    </p>
                    <div className="bottom-row-card">
                      <img src="./assets/images/icons/pegged-token.svg" alt="img" />
                    </div>
                  </a>
                </li>
                <li>
                  <a onClick={(e) => { e.preventDefault(); goToNextStep("Dao"); }} className="cursor-pointer card-wrapper card-box--pink">
                    <div className="top-row-card">
                      <h3 className="primaryText mb-0 fw600 lh1">DAO</h3>
                      <div className="redirect-link">
                        <img src="assets/images/icons/arrow-right.svg" alt="" />
                      </div>
                    </div>
                    <p className="grayText">
                      Decentralized autonomous organization is an entity
                      structure in which tokenholders participate in the
                      management and decision-making of an entity.
                    </p>
                    <div className="bottom-row-card">
                      <img src="./assets/images/icons/multi-standard-token.svg" alt="img" />
                    </div>
                  </a>
                </li>
                <li>
                  <a onClick={(e) => { e.preventDefault(); goToNextStep("Marketplace721"); }} role="button" className="card-wrapper card-box--purpledark">
                    <div className="top-row-card">
                      <h3 className="primaryText mb-0 fw600 lh1">
                        NFT Marketplace for 721
                      </h3>
                      <div className="redirect-link">
                        <img src="assets/images/icons/arrow-right.svg" alt="" />
                      </div>
                    </div>
                    <p className="grayText">
                      Multi-token standard that allows the creation of fungible,
                      non-fungible, and semi-fungible tokens all in one
                      contract.
                    </p>
                    <div className="bottom-row-card">
                      <img src="./assets/images/icons/multi-standard-token.svg" alt="img" />
                    </div>
                  </a>
                </li>
                <li>
                  <a onClick={(e) => { e.preventDefault(); goToNextStep("Marketplace1155"); }} role="button" className="card-wrapper card-box--purpledark">
                    <div className="top-row-card">
                      <h3 className="primaryText mb-0 fw600 lh1">
                        NFT Marketplace for 1155
                      </h3>
                      <div className="redirect-link">
                        <img src="assets/images/icons/arrow-right.svg" alt="" />
                      </div>
                    </div>
                    <p className="grayText">
                      Multi-token standard that allows the creation of fungible,
                      non-fungible, and semi-fungible tokens all in one
                      contract.
                    </p>
                    <div className="bottom-row-card">
                      <img src="./assets/images/icons/multi-standard-token.svg" alt="img" />
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div className="smart-contract__right">
              <h3 className="fw600">
                Keep your Smart Contract secure and flexible!
              </h3>
              <p className="grayText--lighter">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                aliquam erat volutpat.
              </p>
              <img src="./assets/images/animations/smart-contract-safe/Smart-Contract-Illustration.svg" alt="" />
            </div>
          </div>
        </div>
      </section>
      {/* ====================================== */}
    </>
  )
}
