import React, { useEffect, useState } from 'react'
import LeftSidebar from '../components/LeftSidebar'
import { axiosClientPy, countryList, getDateFormat } from '../utils';
import { toast } from 'react-toastify';

const mScript = (myPer) => `
if (document.getElementById("progressChart1")) {
  let progressChartOption = {
    borderWidth: 0,
    responsive: true,

    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false, // <-- this option disables tooltips
      },
      datalabels: {
        formatter: (value, progPieChart) => {
          let sum = 0;
          let dataArr = progPieChart.chart.data.datasets[0].data;
          dataArr.map((data) => {
            sum += data;
          });
          let percentage =  "${myPer}%";
          return percentage;
        },
        color: ["#0D121C", "rgba(0,0,0,0)"],
        font: {
          weight: "bold",
          size: 12,
        },
      },
    },
  };

  let progressChart1Data = {
    labels: ["Progress", ""],
    datasets: [
      {
        label: "",
        data: [${myPer}, ${100 - parseInt(myPer)}],
        backgroundColor: ["#EBEFF4", "rgba(255, 255, 255, 0)"],
      },
    ],
  };

  let progressChart1 = document
    .getElementById("progressChart1")
    .getContext("2d");

  var myChart = new Chart(progressChart1, {
    type: "pie",
    options: progressChartOption,
    plugins: [ChartDataLabels],
    data: progressChart1Data,
  });
}

// Dropdowns in profile edit //
$(document).ready(function () {
  // To show first dropdown
  $(".custom-dd").on("click", function () {
    $(".custom-menu01").toggleClass("show");
  });
  
  // To show next male/female dropdowns
  $(".custom-item-female, .custom-item-male").on("click", function () {
    var menuClass = $(this).hasClass("custom-item-female")
      ? ".custom-menu-female"
      : ".custom-menu-male";
    $(menuClass).addClass("show");
  });

  // Remove dropdowns on clicking anywhere outside of them
  $(document).on("click", function (event) {
    var targetElement = $(event.target);
    var desiredElements = [
      ".custom-menu01",
      ".custom-menu-female",
      ".custom-menu-male",
    ]; // Replace with your desired elements' selectors

    if (
      !targetElement.closest(".custom-dd").length &&
      !targetElement.is(".custom-dd") &&
      !desiredElements.some(function (element) {
        return (
          targetElement.closest(element).length || targetElement.is(element)
        );
      })
    ) {
      // Add code to remove class from the desired elements
      desiredElements.forEach(function (element) {
        $(element).removeClass("show");
      });
    }
  });

  // Back btn redirects to first dropdown
  $(".custom-menu-male .back-button, .custom-menu-female .back-button").on(
    "click",
    function () {
      $(this)
        .closest(".custom-menu-male, .custom-menu-female")
        .removeClass("show");
      $(".custom-menu01").addClass("show");
    }
  );
});
// =================================================================== //
`;
export default function EditProfile() {
  const [profilePer, setProfilePer] = useState(36);
  const [data, setData] = useState(null);
  const [input, setInput] = useState({
    name: '',
    email: '',
    country: '',
    phone: '',
    gender: 'male',
    zip: '',
    country: 'India',
    address: '',
    profile: '',
    countryCode: '+91'
  })
  const [myCountryList, setMyCountryList] = useState(countryList);

  const onChangeHand = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value })
  }

  const getProfile = () => {
    const headers = {
      Authorization: "Token " + window.sessionStorage.getItem("USER_TOKEN")
    }
    axiosClientPy.get("/account/get-profile", { headers }).then(res => {
      const mData = res.data.data;
      const mInput = {
        name: mData?.full_name ? mData?.full_name : "",
        email: mData?.email ? mData?.email : "",
        phone: mData?.phone ? mData?.phone : "",
        gender: mData?.gender ? mData?.gender : "male",
        address: mData?.complete_address ? mData?.complete_address : "",
        zip: mData?.zipcode ? mData?.zipcode : "",
        country: mData?.country ? mData?.country : "India",
        profile: mData?.profile_image ? mData?.profile_image : "/assets/images/avatar.png"
      }
      setInput({ ...input, ...mInput });
      setData(res.data.data);

      let mPer = 36;
      if (mData.full_name) mPer += 16;
      if (mData.complete_address) mPer += 8;
      if (mData.country) mPer += 8;
      if (mData.gender) mPer += 8;
      if (mData.phone) mPer += 8;
      if (mData.zipcode) mPer += 8;
      if (mData?.profile_image) mPer += 8;
      setProfilePer(mPer);

      const script = document.createElement("script");
      script.innerHTML = mScript(mPer);
      script.async = true;
      document.body?.append(script);
    }).catch(err => {
      console.log(err);
      const script = document.createElement("script");
      script.innerHTML = mScript(profilePer);
      script.async = true;
      document.body?.append(script);
    })
  }

  useEffect(() => {
    getProfile();
  }, [])

  const onSubmit = async (e) => {
    e.preventDefault();
    const headers = {
      Authorization: "Token " + window.sessionStorage.getItem("USER_TOKEN"),
      "Content-Type": "multipart/form-data"
    }
    try {
      const formData = new FormData();
      formData.append("full_name", input.name);
      formData.append("phone", input.phone);
      formData.append("gender", input.gender);
      formData.append("complete_address", input.address);
      formData.append("country", input.country);
      formData.append("zipcode", input.zip);
      formData.append("profile_image", input.profile);

      const res = await axiosClientPy.patch("/account/user-onboarding", formData, { headers })
      toast.success("Profile Updated Successfully!!!");
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  }

  const onCountryChange = (data) => {
    setInput({ ...input, country: data.name, countryCode: data.code });
  }

  const onGenderChange = (data) => {
    setInput({ ...input, gender: data });
  }

  const search = (e) => {
    const mSearch = e.target.value.toLowerCase();
    const searchRes = countryList.filter(ele => ele.name.toLowerCase().startsWith(mSearch));
    setMyCountryList(searchRes);
  }

  return (
    <section>
      <main className="page-wrapper">
        <LeftSidebar active="profile" />
        {/*------- Middle content -------*/}
        <section className="pe-0 main-content">
          <div className="main-content-inner">
            <div className="title-top-row">
              <div className="title-top-row__left">
                <h1 className="lh-1 fw600">My Account</h1>
                <p>
                  <img src="assets/images/icons/last-visited.svg" alt="Calendar Icon denoting when was last updated" />
                  <span>Last visited {getDateFormat(new Date())[2]} - {getDateFormat(new Date())[3]}</span>
                </p>
              </div>
              <div className="title-top-row__right">
                <div className="position-relative form-group mb-0">
                  <input role="search" type="text" className="ts03 rounded-0 form-control" placeholder="Search project or service" />
                  <div className="ts03 search-icon">
                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.9398 17.2693L24 22.3282L22.3282 24L17.2693 18.9398C15.3869 20.4488 13.0456 21.2696 10.6331 21.2661C4.76361 21.2661 0 16.5025 0 10.6331C0 4.76361 4.76361 0 10.6331 0C16.5025 0 21.2661 4.76361 21.2661 10.6331C21.2696 13.0456 20.4488 15.3869 18.9398 17.2693ZM16.5699 16.3926C18.0692 14.8507 18.9066 12.7838 18.9032 10.6331C18.9032 6.06321 15.2017 2.3629 10.6331 2.3629C6.06321 2.3629 2.3629 6.06321 2.3629 10.6331C2.3629 15.2017 6.06321 18.9032 10.6331 18.9032C12.7838 18.9066 14.8507 18.0692 16.3926 16.5699L16.5699 16.3926Z" />
                    </svg>
                  </div>
                  <button type='button' className="reset-input">
                    <svg xmlns="http://www.w3.org/2000/svg" width="29.25" height="29.25" viewBox="0 0 29.25 29.25">
                      <path id="Icon_ionic-md-close-circle" data-name="Icon ionic-md-close-circle" d="M18,3.375A14.625,14.625,0,1,0,32.625,18,14.574,14.574,0,0,0,18,3.375Zm7.313,19.89-2.048,2.047L18,20.047l-5.265,5.265-2.048-2.047L15.953,18l-5.265-5.265,2.048-2.047L18,15.953l5.265-5.265,2.048,2.047L20.047,18Z" transform="translate(-3.375 -3.375)" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            {/*----- Completion stats row -----*/}
            <div className="primary-box completion-row">
              <div className="completion-row__left">
                <div>
                  <h1 className="text-white fw600">Profile Completion - {profilePer}%</h1>
                  <p className="m-0 grayText">
                    Verify your Company’s Introduction, statutory detail to
                    complete your company’s profile
                  </p>
                </div>
              </div>
              <div className="completion-row__right">
                <div className="progressPieChart">
                  <canvas id="progressChart1" />
                </div>
              </div>
            </div>
            {/* ============================== */}
            <div className="profile-edit">
              <div className="bg-white profile-edit__left">
                <form className="form" onSubmit={onSubmit}>
                  <img src={input.profile} style={{ width: "150px", height: "150px", objectFit: 'cover', borderRadius: "50%" }} alt="" className="profile-edit-illustration" />
                  <div className="upload-section">
                    <div className="btnGroup">
                      <div className="position-relative c-dropdown">
                        <div className="btn rounded-0 btn--primary-outlined custom-dd">
                          Change Photo
                        </div>
                        {/* Dropdown first */}
                        <div className="position-absolute dd-menu custom-menu01">
                          <h3 className="fw700 mb-3 fz12 text-center">
                            Select Avatars from provided list
                          </h3>
                          <div className="top-one">
                            <button className="next-dd-btn custom-item-female" type="button">
                              <img src="./../assets/images/avatar-female-default.svg" alt="avatar female default" />
                              <div className="fw600 fz14">
                                <span>Female</span>
                                <div className="arrow">
                                  <img src="../assets/images/icons/arrow-right.svg" alt="" />
                                </div>
                              </div>
                            </button>
                            <button type="button" className="next-dd-btn custom-item-male">
                              <img src="./../assets/images/avatar-male-default.svg" alt="avatar male default" />
                              <div className="fw600 fz14">
                                <span>Male</span>
                                <div className="arrow">
                                  <img src="../assets/images/icons/arrow-right.svg" alt="" />
                                </div>
                              </div>
                            </button>
                          </div>
                          <h3 className="fw700 mt-3 pb-1 fz12 text-center">
                            OR
                          </h3>
                          <h3 className="fw700 mb-3 fz12 text-center">
                            Upload photo from your computer
                          </h3>
                          <div className="position-relative mx-auto upload">
                            <input type="file" onChange={(e) => setInput({ ...input, profile: e.target.files[0] })} />
                            <button className="btn rounded-0 btn--white" type="button">
                              Upload
                            </button>
                          </div>
                          <p className="mb-0 grayText--lighter">
                            Max size 2mb, jpeg, png, jpg
                          </p>

                        </div>
                        {/* Dropdown second - female */}
                        <div className="position-absolute dd-menu custom-menu-female">
                          <button type="button" className="custom-item back-button bg-transparent text-white fw700 fz12">
                            Back
                          </button>
                          <h3 className="fw700 mb-4 fz12 text-center">
                            Select Male Avatars from provided list
                          </h3>
                          <ul className="list-inline avatars">
                            <li>
                              <button onClick={() => { setInput(prev => ({ ...prev, profile: window.location.href.replace("/edit-profile", "") + "/assets/images/avatar-f01.svg" })) }} type='button'>
                                <img src="./../assets/images/avatar-f01.svg" alt="" />
                              </button>
                            </li>
                            <li>
                              <button onClick={() => { setInput(prev => ({ ...prev, profile: window.location.href.replace("/edit-profile", "") + "/assets/images/avatar-f02.svg" })) }} type='button'>
                                <img src="./../assets/images/avatar-f02.svg" alt="" />
                              </button>
                            </li>
                            <li>
                              <button onClick={() => { setInput(prev => ({ ...prev, profile: window.location.href.replace("/edit-profile", "") + "/assets/images/avatar-f03.svg" })) }} type='button'>
                                <img src="./../assets/images/avatar-f03.svg" alt="" />
                              </button>
                            </li>
                            <li>
                              <button onClick={() => { setInput(prev => ({ ...prev, profile: window.location.href.replace("/edit-profile", "") + "/assets/images/avatar-f04.svg" })) }} type='button'>
                                <img src="./../assets/images/avatar-f04.svg" alt="" />
                              </button>
                            </li>
                            <li>
                              <button onClick={() => { setInput(prev => ({ ...prev, profile: window.location.href.replace("/edit-profile", "") + "/assets/images/avatar-f05.svg" })) }} type='button'>
                                <img src="./../assets/images/avatar-f05.svg" alt="" />
                              </button>
                            </li>
                            <li>
                              <button onClick={() => { setInput(prev => ({ ...prev, profile: window.location.href.replace("/edit-profile", "") + "/assets/images/avatar-f06.svg" })) }} type='button'>
                                <img src="./../assets/images/avatar-f06.svg" alt="" />
                              </button>
                            </li>
                            <li>
                              <button onClick={() => { setInput(prev => ({ ...prev, profile: window.location.href.replace("/edit-profile", "") + "/assets/images/avatar-f07.svg" })) }} type='button'>
                                <img src="./../assets/images/avatar-f07.svg" alt="" />
                              </button>
                            </li>
                            <li>
                              <button onClick={() => { setInput(prev => ({ ...prev, profile: window.location.href.replace("/edit-profile", "") + "/assets/images/avatar-f08.svg" })) }} type='button'>
                                <img src="./../assets/images/avatar-f08.svg" alt="" />
                              </button>
                            </li>
                            <li>
                              <button onClick={() => { setInput(prev => ({ ...prev, profile: window.location.href.replace("/edit-profile", "") + "/assets/images/avatar-f09.svg" })) }} type='button'>
                                <img src="./../assets/images/avatar-f09.svg" alt="" />
                              </button>
                            </li>
                            <li>
                              <button onClick={() => { setInput(prev => ({ ...prev, profile: window.location.href.replace("/edit-profile", "") + "/assets/images/avatar-f10.svg" })) }} type='button'>
                                <img src="./../assets/images/avatar-f10.svg" alt="" />
                              </button>
                            </li>
                          </ul>
                          <div className="mt-auto text-center pb-4">
                            <button className="btn rounded-0 btn--white" type="submit">
                              Done
                            </button>
                          </div>
                        </div>
                        {/* Dropdown second - male */}
                        <div className="position-absolute dd-menu custom-menu-male">
                          <button type="button" className="custom-item back-button bg-transparent text-white fw700 fz12">
                            Back
                          </button>
                          <h3 className="fw700 mb-4 fz12 text-center">
                            Select Male Avatars from provided list
                          </h3>
                          <ul className="list-inline avatars">
                            <li>
                              <button type='button' onClick={() => { setInput(prev => ({ ...prev, profile: window.location.href.replace("/edit-profile", "") + "/assets/images/avatar-m01.svg" })) }}>
                                <img src="./../assets/images/avatar-m01.svg" alt="" />
                              </button>
                            </li>
                            <li>
                              <button type='button' onClick={() => { setInput(prev => ({ ...prev, profile: window.location.href.replace("/edit-profile", "") + "/assets/images/avatar-m02.svg" })) }}>
                                <img src="./../assets/images/avatar-m02.svg" alt="" />
                              </button>
                            </li>
                            <li>
                              <button type='button' onClick={() => { setInput(prev => ({ ...prev, profile: window.location.href.replace("/edit-profile", "") + "/assets/images/avatar-m03.svg" })) }}>
                                <img src="./../assets/images/avatar-m03.svg" alt="" />
                              </button>
                            </li>
                            <li>
                              <button type='button' onClick={() => { setInput(prev => ({ ...prev, profile: window.location.href.replace("/edit-profile", "") + "/assets/images/avatar-m04.svg" })) }}>
                                <img src="./../assets/images/avatar-m04.svg" alt="" />
                              </button>
                            </li>
                            <li>
                              <button type='button' onClick={() => { setInput(prev => ({ ...prev, profile: window.location.href.replace("/edit-profile", "") + "/assets/images/avatar-m05.svg" })) }}>
                                <img src="./../assets/images/avatar-m05.svg" alt="" />
                              </button>
                            </li>
                            <li>
                              <button type='button' onClick={() => { setInput(prev => ({ ...prev, profile: window.location.href.replace("/edit-profile", "") + "/assets/images/avatar-m06.svg" })) }}>
                                <img src="./../assets/images/avatar-m06.svg" alt="" />
                              </button>
                            </li>
                            <li>
                              <button type='button' onClick={() => { setInput(prev => ({ ...prev, profile: window.location.href.replace("/edit-profile", "") + "/assets/images/avatar-m07.svg" })) }}>
                                <img src="./../assets/images/avatar-m07.svg" alt="" />
                              </button>
                            </li>
                            <li>
                              <button type='button' onClick={() => { setInput(prev => ({ ...prev, profile: window.location.href.replace("/edit-profile", "") + "/assets/images/avatar-m08.svg" })) }}>
                                <img src="./../assets/images/avatar-m08.svg" alt="" />
                              </button>
                            </li>
                            <li>
                              <button type='button' onClick={() => { setInput(prev => ({ ...prev, profile: window.location.href.replace("/edit-profile", "") + "/assets/images/avatar-m09.svg" })) }}>
                                <img src="./../assets/images/avatar-m09.svg" alt="" />
                              </button>
                            </li>
                            <li>
                              <button type='button' onClick={() => { setInput(prev => ({ ...prev, profile: window.location.href.replace("/edit-profile", "") + "/assets/images/avatar-m10.svg" })) }}>
                                <img src="./../assets/images/avatar-m10.svg" alt="" />
                              </button>
                            </li>
                          </ul>
                          <div className="mt-auto text-center pb-4">
                            <button className="btn rounded-0 btn--white" type="submit">
                              Done
                            </button>
                          </div>
                        </div>
                      </div>
                      <button type="button" className="primary-box btn rounded-0 btn--primary">
                        Remove
                      </button>
                    </div>
                    <p className="grayText">File size max 2mb, jpg , png, jpeg.</p>
                  </div>

                  <div className="form-group">
                    <div className="form-group__inner">
                      <label className="label">
                        <div className="primary-box label__icon">
                          <img src="./assets/images/icons/user.svg" alt="User icon" />
                        </div>
                        <strong className="fw600">Full name</strong>
                      </label>
                      <input type="text" name='name' onChange={onChangeHand} value={input.name} className="border-0 rounded-0 form-control italic-placeholder" />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group__inner">
                      <label className="label">
                        <div className="primary-box label__icon">
                          <img src="./assets/images/icons/email.svg" alt="Email icon" />
                        </div>
                        <strong className="fw600">Email Address</strong>
                      </label>
                      <input type="text" name='email' readOnly onChange={onChangeHand} value={input.email} className="border-0 rounded-0 form-control italic-placeholder" placeholder="johnadams@example.com" />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group__inner">
                      <label className="label">
                        <div className="primary-box label__icon">
                          <img src="./assets/images/icons/location.svg" alt="Location icon" />
                        </div>
                        <strong className="fw600">Country</strong>
                      </label>
                      <div className="btn-group dropdown dropstart">
                        <div className="dd-value">
                          <span className="fst-italic fw300">{input.country}</span>
                        </div>
                        <button className="btn ms-auto rounded-0 p-0 primary-box dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => { document.getElementById("inputCountrySearch").value = ""; setMyCountryList(countryList); }}>
                          <img src="assets/images/icons/arrow-right.svg" alt="" />
                        </button>
                        <ul className="pt-0 dropdown-menu">
                          <li><input type="text" id="inputCountrySearch" onChange={search} placeholder='Search' className="dropdown-item fw600" /></li>
                          {
                            myCountryList.sort((a, b) => a.name.localeCompare(b.name)).map(ele => (
                              <li><button type="button" onClick={() => onCountryChange(ele)} className="dropdown-item">{ele.name}</button></li>
                            ))
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group__inner">
                      <label className="label">
                        <div className="primary-box label__icon">
                          <img src="./assets/images/icons/phone-call.svg" alt="Phone icon" />
                        </div>
                        <strong className="fw600">Phone</strong>
                      </label>
                      <div className="btn-group">
                        <div className="dd-value">
                          <span className="fst-italic">{input.countryCode}</span>
                        </div>
                        <input type="text" name='phone' maxLength="10" onChange={onChangeHand} value={input.phone} className="border-0 rounded-0 form-control italic-placeholder" placeholder="Mobile Number" />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group__inner">
                      <label className="label">
                        <div className="primary-box label__icon">
                          <img src="./assets/images/icons/gender.svg" alt="Gender icon" />
                        </div>
                        <strong className="fw600">Gender</strong>
                      </label>
                      <div className="btn-group dropdown dropstart">
                        <div className="dd-value">
                          <span className="fst-italic fw300">{input.gender}</span>
                        </div>
                        <button className="btn ms-auto rounded-0 p-0 primary-box dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          <img src="assets/images/icons/arrow-right.svg" alt="" />
                        </button>
                        <ul className="dropdown-menu">
                          <li><button type='button' className="dropdown-item" onClick={() => onGenderChange("male")}>Male</button></li>
                          <li><button type='button' className="dropdown-item" onClick={() => onGenderChange("female")}>Female</button></li>
                          <li><button type='button' className="dropdown-item" onClick={() => onGenderChange("rather not say")}>Rather not say</button></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group__inner">
                      <label className="label">
                        <div className="primary-box label__icon">
                          <img src="./assets/images/icons/zipcode.svg" alt="ZIP icon" />
                        </div>
                        <strong className="fw600">ZIP</strong>
                      </label>
                      <input type="text" name='zip' onChange={onChangeHand} value={input.zip} className="border-0 rounded-0 form-control italic-placeholder" placeholder="001122" />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group__inner">
                      <label className="label">
                        <strong className="fw600">Complete Address</strong>
                      </label>
                      <input type="text" name='address' onChange={onChangeHand} value={input.address} className="border-0 rounded-0 form-control italic-placeholder" placeholder='Address' />
                    </div>
                  </div>
                  <div className="text-end">
                    <button type="submit" className="btn rounded-0 border-0 primary-box btn--primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
              <div className="text-center profile-edit__right">
                <h3 className="fw600">Tell Us About Yourself!</h3>
                <p className="grayText--lighter">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting.
                </p>
                <img src="./assets/images/animations/security/security.svg" alt="" />
              </div>
            </div>
          </div>
        </section>
        {/* ====================================== */}
      </main>
    </section>
  )
}
