import React from 'react'
import { axiosClientPy } from '../../../utils';
import { toast } from 'react-toastify';

export default function CreateProjectERC1155Step2({ projectData, setStep }) {
  const [tSymbol, setTSymbol] = React.useState(projectData?.token_symbol ? projectData.token_symbol : "");

  const onSubmit = async (e) => {
    e.preventDefault();
    const headers = {
      Authorization: "Token " + window.sessionStorage.getItem("USER_TOKEN")
    }
    try {
      const mData = new FormData();
      mData.append("project_id", projectData?.uuid);
      mData.append("token_symbol", tSymbol);
      mData.append("step_count", 5);
      const res = await axiosClientPy.patch("/project/project-creation", mData, { headers });
      setStep(3);
    } catch (error) {
      toast.error("Internal Server Error!!!")
    }
  }
  return (
    <section className="pe-0 main-content stepper-content">
      <div className="main-content-inner">
        <div className="with-user-row">
          <div className="title-top-row">
            <div className="title-top-row__left">
              <h1 className="lh-1 fw600">Create New Project</h1>
              <p>
                <img src="assets/images/icons/last-visited.svg" alt="Calendar Icon denoting when was last updated" />
                <span>Last updated 09:00 A.M. - 04 Apr 2021</span>
              </p>
            </div>
            <div className="title-top-row__right">
              <div className="position-relative form-group mb-0">
                <input role="search" type="text" className="ts03 rounded-0 form-control" placeholder="Search project or service" />
                <div className="ts03 search-icon">
                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.9398 17.2693L24 22.3282L22.3282 24L17.2693 18.9398C15.3869 20.4488 13.0456 21.2696 10.6331 21.2661C4.76361 21.2661 0 16.5025 0 10.6331C0 4.76361 4.76361 0 10.6331 0C16.5025 0 21.2661 4.76361 21.2661 10.6331C21.2696 13.0456 20.4488 15.3869 18.9398 17.2693ZM16.5699 16.3926C18.0692 14.8507 18.9066 12.7838 18.9032 10.6331C18.9032 6.06321 15.2017 2.3629 10.6331 2.3629C6.06321 2.3629 2.3629 6.06321 2.3629 10.6331C2.3629 15.2017 6.06321 18.9032 10.6331 18.9032C12.7838 18.9066 14.8507 18.0692 16.3926 16.5699L16.5699 16.3926Z" />
                  </svg>
                </div>
                <a href="#" className="reset-input">
                  <svg xmlns="http://www.w3.org/2000/svg" width="29.25" height="29.25" viewBox="0 0 29.25 29.25">
                    <path id="Icon_ionic-md-close-circle" data-name="Icon ionic-md-close-circle" d="M18,3.375A14.625,14.625,0,1,0,32.625,18,14.574,14.574,0,0,0,18,3.375Zm7.313,19.89-2.048,2.047L18,20.047l-5.265,5.265-2.048-2.047L15.953,18l-5.265-5.265,2.048-2.047L18,15.953l5.265-5.265,2.048,2.047L20.047,18Z" transform="translate(-3.375 -3.375)" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          {/* <div class="separator-v"></div> */}
          <div className="me-0 sidebar-right-inner__top">
            <img className="rounded-circle user" src="assets/images/avatar.png" alt="user display picture" />
            <div className="title-row name-info">
              <h2 className="fw700">Welcome Back, Adam!</h2>
              <p className="mb-0">
                <img src="assets/images/icons/last-visited.svg" alt="Calendar Icon denoting when was last updated" />
                <span>09:00 A.M. - 04 Apr 2021</span>
              </p>
            </div>
            <div className="position-relative ms-auto notify">
              <span className="position-absolute rounded-circle dot" />
              <img src="assets/images/icons/notification.svg" alt="Bell icon" />
            </div>
            <div className="dropdown">
              <a className="btn fw500 border-0 p-0 dropdown-toggle" href="#" role="button" id="languageLink" data-bs-toggle="dropdown" aria-expanded="false">
                EN
              </a>
              <ul className="dropdown-menu" aria-labelledby="languageLink">
                <li><a className="fw500 dropdown-item" href="#">EN</a></li>
                <li><a className="fw500 dropdown-item" href="#">FR</a></li>
                <li>
                  <a className="fw500 dropdown-item" href="#">DU</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="stepperForm">
          <div className="leftStepper">
            {/*----- Completion stats row -----*/}
            <div className="primary-box completion-row completion-row--back">
              <div className="completion-row__left">
                <button onClick={() => setStep(1)} className="redirect-back">
                  <img src="./assets/images/icons/back-arrow.svg" alt="Back arrow" />
                </button>
                <div>
                  <h1 className="fw600 text-white">ERC - 1155</h1>
                  <p className="m-0 grayText">
                    Most basic token standard, used to create interchangeable
                    tokens.
                  </p>
                </div>
              </div>
              <div className="completion-row__right">
                <img className="#" src="./assets/images/icons/multi-standard-token-white.svg" alt="" />
              </div>
            </div>
            <div className="profile-edit erc721">
              <div className="left_stepperwrapper">
                <h3 className="fw700 grayText--lighter fz14">
                  Step 2.
                  <strong className="darkText fw700 fz14">Token Symbol</strong>
                </h3>
                <p className="grayText--lighter fz12">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vehicula massa in enim luctus. Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit. Vehicula massa in enim luctus.
                  Rutrum arcu.
                </p>
                <div className="form-box form-box--violet profile-edit__left stepper-form-details">
                  <form onSubmit={onSubmit} className="form">
                    <div className="mb-5 form-section">
                      <p className="grayText fz12">
                        Give unique name to your token, assign symbol and
                        adjust decimal to start with your ERC - 1155
                      </p>
                    </div>
                    <div className="top">
                      <div className="form-group">
                        <div className="form-group__inner">
                          <label className="label">
                            <div className="primary-box label__icon">
                              <img src="./assets/images/icons/nft-label.svg" alt="User icon" />
                            </div>
                          </label>
                          <input type="text" value={tSymbol} onChange={(e) => setTSymbol(e.target.value)} className="rounded-0 border-0 bg-transparent p-0 form-control italic-placeholder" placeholder="Symbol" />
                        </div>
                      </div>
                    </div>
                    <div className="btnGroup">
                      <button type="button" className="btn rounded-0 px-2 text-nowrap btn--transparent-outlined">
                        Cancel
                      </button>
                      <button type="submit" className="btn rounded-0 primary-box btn--primary">
                        Next
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="right_stepperwrapper">
                <h3 className="fw700 fz14">Next Step: Functions</h3>
                <p className="grayText--lighter fz12">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vehicula massa in enim luctus. Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit. Vehicula massa in enim luctus.
                  Rutrum arcu.
                </p>
                <div className="text-center profile-edit__right">
                  <img className="w-100" src="./assets/images/stepper1.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
          {/* leftStepper */}
          <div className="rightStepper">
            <div className="stepper_Steps">
              <div className="stepper-head">
                <a href="#" className="stepper-card-head">
                  <div className="d-flex justify-content-between title-row stepper_Links">
                    <button className="stepper-redirect-link">
                      <img className="rounded mx-auto d-block" style={{ transform: 'rotate(180deg)' }} src="assets/images/icons/arrow-right.svg" alt="" />
                    </button>
                    <h2 className="fw700">Smart Contract Steps</h2>
                    <button className="stepper-redirect-link">
                      <img className="rounded mx-auto d-block" src="assets/images/icons/arrow-right.svg" alt="" />
                    </button>
                  </div>
                </a>
              </div>
              <hr className="mt-0" />
              <div className="stepper-counts">
                <div className="outlined-card">
                  <a role="button" className="stepper-card stepper-card--pink">
                    <div className="title-row stepper_Links">
                      <div className="number">
                        <h2>1.</h2>
                      </div>
                      <h2 className="fw500">Token Name</h2>
                    </div>
                  </a>
                </div>
                <div className="outlined-card">
                  <a role="button" className="stepper-card stepper-card--orange">
                    <div className="title-row stepper_Links">
                      <div className="number">
                        <h2>2.</h2>
                      </div>
                      <h2 className="fw500">Token Symbol</h2>
                    </div>
                  </a>
                </div>
                <div className="outlined-card outlined-card--inactive">
                  <a role="button" className="stepper-card stepper-card--yellow">
                    <div className="title-row stepper_Links">
                      <div className="number">
                        <h2>3.</h2>
                      </div>
                      <h2 className="fw500">Functions</h2>
                    </div>
                  </a>
                </div>
                <div className="outlined-card outlined-card--inactive">
                  <a role="button" className="stepper-card stepper-card--blue">
                    <div className="title-row stepper_Links">
                      <div className="number">
                        <h2>4.</h2>
                      </div>
                      <h2 className="fw500">Overview</h2>
                    </div>
                  </a>
                </div>
                <div className="outlined-card outlined-card--inactive">
                  <a role="button" className="stepper-card stepper-card--pink">
                    <div className="title-row stepper_Links">
                      <div className="number">
                        <h2>5.</h2>
                      </div>
                      <h2 className="fw500">Deploy ERC-1155</h2>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* rightStepper */}
        </div>
      </div>
    </section>
  )
}
