import React, { useState } from 'react'
import TopRightProfile from '../../TopRightProfile'
import { axiosClientPy } from '../../../utils';
import { toast } from 'react-toastify';

export default function CreateProjectMarketplace721Step3({ projectData }) {
  const [input, setInput] = useState({
    Auction: false,
    Bid: false,
    EndAuction: false,
    ChangeAuctionFee: false,
    FetchAuctionItem: false,
    FetchListedMyAuctionItem: false
  })

  const onSubmit = async (e) => {
    e.preventDefault();
    const headers = {
      Authorization: "Token " + window.sessionStorage.getItem("USER_TOKEN")
    }
    try {
      let typeRes = await axiosClientPy.get("/main/project-function", { headers });
      let selectedType = typeRes?.data?.data;
      selectedType = selectedType?.filter(ele => input[ele.name]);
      const mData = {
        "project_id": projectData?.uuid,
        "contract_functions": selectedType.map(ele => ele.uuid),
        "step_count": 6
      }
      const res = await axiosClientPy.patch("/project/project-creation", mData, { headers });
      window.location.reload();
    } catch (error) {
      toast.error("Internal Server Error!!!")
    }
  }
  return (
    <section className="pe-0 main-content">
      <div className="pt-0 main-content-inner box-steps--illustration">
        <TopRightProfile />
        {/*----- Completion stats row -----*/}
        <div className="primary-box completion-row completion-row--back">
          <div className="completion-row__left">
            <a href="/new-project" className="redirect-back">
              <img src="../assets/images/icons/back-arrow.svg" alt="Back arrow" />
            </a>
            <div className>
              <h1 className="fw600 text-white">Marketplace 721</h1>
              <p className="m-0 grayText">
                Merging technology to remove third parties and centralized
                institutions from financial transactions.
              </p>
            </div>
          </div>
          <div className="completion-row__right">
            <img className="logo-crypto" src="../assets/images/defi-logo.svg" alt="" />
          </div>
        </div>
        {/* ============================== */}
        <div className="box-steps">
          <h2 className="text-center mb-0 fst-italic fw700">
            Active Functions For Your Marketplace 721
          </h2>
          <p className="text-center grayText--lighter">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vehicula
            massa in enim luctus. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Vehicula massa in enim luctus. Rutrum arcu.
          </p>
          <div className="w-100 form-box form-box--purple">
            <form onSubmit={onSubmit} className="form">
              <div className='top'>
                <div className="fw600 justify-content-center m-0 form-group form-group--checkbox">
                  <input id="one" type="checkbox" checked={input.Auction} onChange={() => { setInput(prev => ({ ...prev, Auction: !prev.Auction, Bid: !prev.Auction, EndAuction: !prev.Auction })) }} />
                  <label htmlFor="one">
                    <b />
                    Auction
                  </label>
                </div>
              </div>
              <div className='d-flex'>
                {
                  input.Auction &&
                  <>
                    <div style={{ flex: 1 }}>
                      <div className="mt-4 fw600 m-0 form-group form-group--checkbox">
                        <input id="two" type="checkbox" checked={input.Bid} disabled />
                        <label htmlFor="two">
                          <b />
                          Bid
                        </label>
                      </div>
                      <div className="mt-4 fw600 m-0 form-group form-group--checkbox">
                        <input id="three" type="checkbox" checked={input.EndAuction} disabled />
                        <label htmlFor="three">
                          <b />
                          End Auction
                        </label>
                      </div>
                      <div className="mt-4 fw600 m-0 form-group form-group--checkbox">
                        <input id="four" type="checkbox" checked={input.ChangeAuctionFee} onChange={() => { setInput(prev => ({ ...prev, ChangeAuctionFee: !prev.ChangeAuctionFee })) }} />
                        <label htmlFor="four">
                          <b />
                          ChangeAuctionFee
                        </label>
                      </div>
                    </div>
                    <div style={{ flex: 1 }}>
                      <div className="mt-4 fw600 m-0 form-group form-group--checkbox">
                        <input id="five" type="checkbox" checked={input.FetchAuctionItem} onChange={() => { setInput(prev => ({ ...prev, FetchAuctionItem: !prev.FetchAuctionItem })) }} />
                        <label htmlFor="five">
                          <b />
                          FetchAuctionItem
                        </label>
                      </div>
                      <div className="mt-4 fw600 m-0 form-group form-group--checkbox">
                        <input id="six" type="checkbox" checked={input.FetchListedMyAuctionItem} onChange={() => { setInput(prev => ({ ...prev, FetchListedMyAuctionItem: !prev.FetchListedMyAuctionItem })) }} />
                        <label htmlFor="six">
                          <b />
                          Fetch Listed My Auction Item
                        </label>
                      </div>
                    </div>
                  </>
                }
              </div>
              <div className="btnGroup mt-5">
                <button type="submit" className="btn rounded-0 primary-box btn--primary">
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}
