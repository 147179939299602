import Cookies from 'js-cookie';
import React from 'react'
import TopRightProfile from '../../TopRightProfile';
import { toast } from 'react-toastify';
import { axiosClientPy } from '../../../utils';

export default function CreateProjectDeFiStep4({ projectData, setStep }) {
  const [MinimumDeposit, setMinimumDeposit] = React.useState(projectData?.minimum_deposit ? projectData.minimum_deposit === 'true' : false);

  const onSubmit = async (e) => {
    e.preventDefault();
    const headers = {
      Authorization: "Token " + window.sessionStorage.getItem("USER_TOKEN")
    }
    try {
      const mData = new FormData();
      mData.append("project_id", projectData?.uuid);
      mData.append("minimum_deposit", MinimumDeposit);
      mData.append("step_count", 7);
      const res = await axiosClientPy.patch("/project/project-creation", mData, { headers });
      setStep(5);
    } catch (error) {
      toast.error("Internal Server Error!!!")
    }
  }

  return (
    <section className="pe-0 main-content">
      <div className="pt-0 main-content-inner box-steps--setting-illustration">
        <TopRightProfile />
        {/*----- Completion stats row -----*/}
        <div className="primary-box completion-row completion-row--back">
          <div className="completion-row__left">
            <button onClick={() => setStep(3)} className="redirect-back">
              <img src="./assets/images/icons/back-arrow.svg" alt="Back arrow" />
            </button>
            <div >
              <h1 className="fw600 text-white">DeFi</h1>
              <p className="m-0 grayText">
                Merging technology to remove third parties and centralized
                institutions from financial transactions.
              </p>
            </div>
          </div>
          <div className="completion-row__right">
            <img className="logo-crypto" src="./assets/images/defi-logo.svg" alt="" />
            {/* <button className="btn rounded-0 btn--white-outlined">
              <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.3337 10C18.3337 10 15.8337 15 10.0003 15C4.16699 15 1.66699 10 1.66699 10C1.66699 10 4.16699 5 10.0003 5C15.8337 5 18.3337 10 18.3337 10Z" strokeLinecap="round" />
                <circle cx={10} cy={10} r="2.5" strokeLinecap="round" />
              </svg>
              <span className="fw600">Show code</span>
            </button> */}
          </div>
        </div>
        {/* ============================== */}
        <div className="box-steps">
          <h2 className="text-center mb-0 fst-italic fw700">
            Active Functions for your DeFi- Token
          </h2>
          <p className="text-center grayText--lighter">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vehicula
            massa in enim luctus. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Vehicula massa in enim luctus. Rutrum arcu.
          </p>
          <div className="w-100 form-box form-box--withCheckbox form-box--brown">
            <form onSubmit={onSubmit} className="form">
              <div className="top">
                <div className="fw600 form-group form-group--checkbox">
                  <input id="one" type="checkbox" checked={MinimumDeposit} onChange={() => { setMinimumDeposit(prev => !prev) }} />
                  <label htmlFor="one">
                    <b />
                    Minimum Deposit
                  </label>
                </div>
                <p className="text-center grayText--lighter fz12 mx-auto">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vehicula massa in enim luctus. Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit. Vehicula massa in enim luctus.
                  Rutrum arcu.
                </p>
              </div>
              <div className="btnGroup">
                <button type="submit" className="btn rounded-0 primary-box btn--primary">
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}