import React from 'react'
import { useParams } from 'react-router-dom';
import Loading from '../components/Loading';
import LeftSidebar from '../components/LeftSidebar';
import useFetch from '../utils/hooks/useFetch';
import TopRightProfile from '../components/TopRightProfile';
import PaymentOptionsModal from '../components/CreateProject/Modals/PaymentOptionsModal';
import { APP_DATA, axiosClientPy } from '../utils';
import { toast } from 'react-toastify';
import useRazorpay from 'react-razorpay';

export default function TutorialView() {
  const Razorpay = useRazorpay();
  const { tutorialId } = useParams();
  const { data, loading } = useFetch("/courses/courses?course_id=" + tutorialId);
  const headers = {
    Authorization: "Token " + window.sessionStorage.getItem("USER_TOKEN")
  }
  const web2Fun = async () => {
    let orderInfo = {};

    try {
      const apiRes = await axiosClientPy.post("/courses/init-enrollment", { course_id: data?.uuid }, { headers: headers });
      orderInfo = apiRes?.data?.data;
      console.log(orderInfo);
      const options = {
        key: orderInfo.mrchant_key,
        amount: orderInfo.paise_amount,
        currency: APP_DATA.currency,
        name: "Chainery",
        description: "Test Transaction",
        image: window.location.host + "/assets/images/logo-white.png",
        order_id: orderInfo.razorpay_order_id,
        handler: function (response) {
          axiosClientPy.post("/courses/course-callback", response, { headers }).then((paymentCallbackResponse) => {
            window.location.reload();
          }).catch(err => {
            toast.error("Payment Failed!!!");
          })
        },
        prefill: {
          name: "Aditya Sharma",
          email: "aditya242401@gmail.com",
          contact: "6392919732",
        },
        theme: {
          color: "#000000",
        },
      };

      const rzp1 = new Razorpay(options);

      rzp1.on("payment.failed", function (response) {
        toast.error(response.error.description);
      });

      rzp1.open();
    } catch (error) {
      console.log(error, "Aditya");
    }
  }

  return (
    <>
      <Loading loading={loading} />
      <div className="mob-menu-btn"><span /><span /><span /></div>
      <section className='page-wrapper'>
        <LeftSidebar active={"tutorials"} />

        <section className="pe-0 main-content">
          <div className="pt-0 main-content-inner">
            <TopRightProfile />
            {/*----- Completion stats row -----*/}
            <div className="d-none d-lg-flex primary-box completion-row completion-row--back">
              <div className="completion-row__left">
                <a href="/tutorials" className="redirect-back">
                  <img src="../assets/images/icons/back-arrow.svg" alt="Back arrow" />
                </a>
                <div className>
                  <h1 className="fw600 text-white">Courses</h1>
                  <p className="m-0 grayText">
                    Watch &amp; learn from categorzed courses
                  </p>
                  <div className="mb-0">
                    <span className="fw700">Last Updated: </span>
                    <span className="ms-1"> 01-June-2022 - 03hr:55min (EST)</span>
                  </div>
                </div>
              </div>
            </div>
            {/* ============================== */}
            <div className="tuts">
              <section className="tuts__course course-01">
                <div className="top-row">
                  <button className="btn bg-transparent p-0 btn-title" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCourse01" aria-expanded="false" aria-controls="collapseCourse01">
                    <h2 className="fw600 m-0">{data?.course_name}</h2>
                    <span>
                      <img className="ts03" src="./../assets/images/icons/icon-dd-arrow.svg" alt="" />
                    </span>
                  </button>
                  <div className="ms-auto right">
                    {
                      !data?.is_enrolled && (
                        <button type="button" data-bs-target="#paymentOptionsModal" data-bs-toggle="modal" className="btn rounded-0 border-0 primary-box btn--primary pay-now">
                          Proceed to Pay
                        </button>
                      )
                    }
                    <div className="dropdown">
                      <a href="#" className={`btn rounded-0 border-0 btn--primary resources ${data?.is_enrolled ? "" : "locked"}`} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="../assets/images/icons/lock-dark.svg" alt="" />
                        <div>
                          Resources
                          <span />
                        </div>
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item">Assignments</a>
                        </li>
                        <li>
                          <a className="dropdown-item">Videos</a>
                        </li>
                        <li>
                          <a className="dropdown-item">Other resources</a>
                        </li>
                      </ul>
                    </div>

                    <a href="#" className={`btn rounded-0 border-0 p-0 btn--primary-outlined ${data?.is_enrolled ? "" : "locked"}`} role="button">
                      <img src="../assets/images/icons/lock-dark.svg" alt="" />
                      <div>Exams</div>
                    </a>
                  </div>
                </div>
                <div className="w-100 collapse" id="collapseCourse01">
                  <div className="desc">
                    <p className="m-0 fz16" style={{fontSize: 'clamp(14px, 0.5vw + 10px, 16px)' }}>
                      {data?.description}
                    </p>
                  </div>
                </div>
                <div className="tuts__course__videos">
                  <div className="row d-flex justify-content-between">
                    <div className=" col-8">
                      <div className="content-wrapper">
                        <div className="content">
                          <h3 className="fz16" >
                            What you'll learn
                          </h3>
                        </div>
                        <div className="row mt-4">
                          <div className="col-6">
                            <ul className="topics">
                              <li className="mb-3 topic-content">
                                <img src="../assets/images/icons/topics-tick.png" />
                                <p>Why this is important</p>
                              </li>
                              <li className="mb-3 topic-content">
                                <img src="../assets/images/icons/topics-tick.png" />
                                <p>Create a Blockchain</p>
                              </li>
                              <li className="mb-3 topic-content">
                                <img src="../assets/images/icons/topics-tick.png" />
                                <p>Understand the theory behind Cryptocurrency Transactions</p>
                              </li>
                              <li className="topic-content">
                                <img src="../assets/images/icons/topics-tick.png" />
                                <p>Understand the theory behind Smart Contracts</p>
                              </li>
                            </ul>
                          </div>
                          <div className="col-6">
                            <ul className="topics">
                              <li className="mb-3 topic-content">
                                <img src="../assets/images/icons/topics-tick.png" />
                                <p>Understand the theory behind Blockchain</p>
                              </li>
                              <li className="mb-3 topic-content">
                                <img src="../assets/images/icons/topics-tick.png" />
                                <p>Understand the theory behind Cryptocurrency's</p>
                              </li>
                              <li className="mb-3 topic-content">
                                <img src="../assets/images/icons/topics-tick.png" />
                                <p>Create their own currency</p>
                              </li>
                              <li className="topic-content">
                                <img src="../assets/images/icons/topics-tick.png" />
                                <p>Create their own Smart Contracts</p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="item">
                        <div className="item--inner">
                          <span className="locker">
                            <img src="../assets/images/icons/icon-lock.svg" alt="" />
                          </span>
                          <div className="content">
                            <div className="position-relative img-wrap">
                              {data?.course_lectures?.length > 0 && data?.course_lectures[0].video_url.includes('youtube') ?
                                <iframe width={560} height={315} src={data?.course_lectures[0]?.video_url} title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                                : <video src={data?.course_lectures[0]?.video_url} />
                              }
                            </div>
                            <div className="video-info">
                              <div className="top">
                                <div className="fz14 fw600 numCover">1</div>
                                <h3 className="mb-0 fz16 fw600" style={{fontSize: 'clamp(14px, 0.5vw + 10px, 16px)' }}>
                                  {data?.course_lectures[0]?.course_name}
                                </h3>
                              </div>
                              <div className="fz12 fw500 mt-1 grayText bottom">
                                <div className="views">20.7k views</div>
                                <div className="timestamp">11 days ago</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    {
                      data?.course_lectures?.slice(1).map((ele, index) => (
                        <div className="col-4 mt-3">
                          <div className="item">
                            <div className={`item--inner ${data?.is_enrolled ? "" : "locked"}`}>
                              <span className="locker">
                                <img src="../assets/images/icons/icon-lock.svg" alt="" />
                              </span>
                              <div className="content">
                                <div className="position-relative img-wrap">
                                  {ele.video_url.includes('youtube') ?
                                    <iframe width={560} height={315} src={ele.video_url} title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                                    : <video src={ele.video_url} />
                                  }
                                </div>
                                <div className="video-info">
                                  <div className="top">
                                    <div className="fz14 fw600 numCover">{index + 2}</div>
                                    <h3 className="mb-0 fz16 fw600" style={{fontSize: 'clamp(14px, 0.5vw + 10px, 16px)' }}>{ele.course_name}</h3>
                                  </div>
                                  <div className="fz12 fw500 mt-1 grayText bottom">
                                    <div className="views">30.47k views</div>
                                    <div className="timestamp">20 days ago</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>

      </section>

      <PaymentOptionsModal web2Fun={web2Fun} />
    </>
  )
}
