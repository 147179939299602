import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { axiosClientPy } from '../../utils';

export default function LiveSupportStep1({ topic, setTopic, setStep }) {
  const [message, setMessage] = useState("");
  const headers = { Authorization: "Token " + window.sessionStorage.getItem("USER_TOKEN") }

  const createTicket = async () => {
    try {
      const mData = {
        topic,
        message
      }
      const res = await axiosClientPy.post("/main/support-ticket", mData, { headers });
      window.location.href = "/live-support?ticketId=" + res.data?.data?.uuid;
    } catch (error) {
      console.log(error);
      toast.error("Internal Server Error!!!");
    }
  }

  const onClickTopic = (data) => {
    if (data.status) {
      setTopic(data.topic);
      setStep(2);
    } else {
      setTopic(data.topic);
    }
  }

  return (
    <>
      <section className="main-content">
        <div className="pb-0 main-content-inner">
          <div className="title-top-row">
            <div className="title-top-row__left">
              <h1 className="lh-1 fw600">Live Support</h1>
              <p>
                <span>24/7 available for your service</span>
              </p>
            </div>
            <div className="title-top-row__right">
              <div className="position-relative form-group mb-0">
                <input role="search" type="text" className="ts03 rounded-0 form-control" placeholder="Search project or service" />
                <div className="ts03 search-icon">
                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.9398 17.2693L24 22.3282L22.3282 24L17.2693 18.9398C15.3869 20.4488 13.0456 21.2696 10.6331 21.2661C4.76361 21.2661 0 16.5025 0 10.6331C0 4.76361 4.76361 0 10.6331 0C16.5025 0 21.2661 4.76361 21.2661 10.6331C21.2696 13.0456 20.4488 15.3869 18.9398 17.2693ZM16.5699 16.3926C18.0692 14.8507 18.9066 12.7838 18.9032 10.6331C18.9032 6.06321 15.2017 2.3629 10.6331 2.3629C6.06321 2.3629 2.3629 6.06321 2.3629 10.6331C2.3629 15.2017 6.06321 18.9032 10.6331 18.9032C12.7838 18.9066 14.8507 18.0692 16.3926 16.5699L16.5699 16.3926Z" />
                  </svg>
                </div>
                <a href="#" className="reset-input">
                  <svg xmlns="http://www.w3.org/2000/svg" width="29.25" height="29.25" viewBox="0 0 29.25 29.25">
                    <path id="Icon_ionic-md-close-circle" data-name="Icon ionic-md-close-circle" d="M18,3.375A14.625,14.625,0,1,0,32.625,18,14.574,14.574,0,0,0,18,3.375Zm7.313,19.89-2.048,2.047L18,20.047l-5.265,5.265-2.048-2.047L15.953,18l-5.265-5.265,2.048-2.047L18,15.953l5.265-5.265,2.048,2.047L20.047,18Z" transform="translate(-3.375 -3.375)" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="mb-0 chainery-card chainery-card--gray live-support">
            <div className="live-support__inner">
              <h3 className="text-center fw500 fz16">
                <img src="./../assets/images/icons//spark-icon.svg" alt="" />
                How may we help you?
              </h3>
              <ul className="w-100 list-unstyled accordion accordion-flush help-list" id="accordionHelpQueries">
                <li className="border-0 bg-transparent accordion-item">
                  <div className="position-relative p-0 accordion-header help-blocks help-blocks--red">
                    <button className="text-center bg-transparent shadow-none accordion-button collapsed help-blocks__top" type="button" data-bs-toggle="collapse" data-bs-target="#flush-help01" aria-expanded="false" aria-controls="flush-help01">
                      <div className="position-absolute primary-box">
                        <div className="ts03 arrow" />
                      </div>
                      <h4 className="fz14 fw600">Need Project assistance.</h4>
                      <p className="fz12 mb-0">
                        Issues regarding your project whether it’s product or
                        services you are getting!
                      </p>
                    </button>
                    <div className="accordion-collapse collapse help-blocks__bottom" id="flush-help01" data-bs-parent="#accordionHelpQueries">
                      <div >
                        <button data-bs-toggle="collapse" data-bs-target="#flush-help01" onClick={() => onClickTopic({ topic: "Need help related to specific project", status: true })} type="button" className="btn rounded-0 border-0 text-center bg-white inner-block">
                          <h5 className="fz14 fw600 mb-0">
                            Need help related to specific project
                          </h5>
                          <p>
                            It will enlist all your products and services that
                            will help to assist you regarding your issues.
                          </p>
                        </button>
                        <button data-bs-toggle="collapse" data-bs-target="#flush-help01" onClick={() => onClickTopic({ topic: "How to create new product based project?", status: true })} type="button" className="btn rounded-0 border-0 text-center bg-white inner-block">
                          <h5 className="fz14 fw600 mb-0">
                            How to create new product based project?
                          </h5>
                        </button>
                        <button data-bs-toggle="collapse" data-bs-target="#flush-help01" onClick={() => onClickTopic({ topic: "How can I get multiple Services?", status: true })} type="button" className="btn rounded-0 border-0 text-center bg-white inner-block">
                          <h5 className="fz14 fw600 mb-0">
                            How can I get multiple Services?
                          </h5>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="border-0 bg-transparent accordion-item">
                  <div className="position-relative p-0 accordion-header help-blocks help-blocks--blue">
                    <button className="text-center bg-transparent shadow-none accordion-button collapsed help-blocks__top" type="button" data-bs-toggle="collapse" data-bs-target="#flush-help02" aria-expanded="false" aria-controls="flush-help02">
                      <div className="position-absolute primary-box">
                        <div className="ts03 arrow" />
                      </div>
                      <h4 className="fz14 fw600">
                        Need Profile Completion assistance.
                      </h4>
                      <p className="fz12 mb-0">
                        Issues regarding your personal or company profile
                        completion.
                      </p>
                    </button>
                    <div className="accordion-collapse collapse help-blocks__bottom" id="flush-help02" data-bs-parent="#accordionHelpQueries">
                      <div >
                        <button onClick={() => onClickTopic({ topic: "How to create company profile?" })} type="button" className="btn rounded-0 border-0 text-center bg-white inner-block">
                          <h5 className="fz14 fw600 mb-0">
                            How to create company profile?
                          </h5>
                        </button>
                        <button onClick={() => onClickTopic({ topic: "Can we create both personal and company profile?" })} type="button" className="btn rounded-0 border-0 text-center bg-white inner-block">
                          <h5 className="fz14 fw600 mb-0">
                            Can we create both personal and company profile?
                          </h5>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="border-0 bg-transparent accordion-item">
                  <div className="position-relative p-0 accordion-header help-blocks help-blocks--yellow">
                    <button className="text-center bg-transparent shadow-none accordion-button collapsed help-blocks__top" type="button" data-bs-toggle="collapse" data-bs-target="#flush-help03" aria-expanded="false" aria-controls="flush-help03">
                      <div className="position-absolute primary-box">
                        <div className="ts03 arrow" />
                      </div>
                      <h4 className="fz14 fw600">Need Report assistance.</h4>
                      <p className="fz12 mb-0">
                        Issues regarding your report and sales.
                      </p>
                    </button>
                    <div className="accordion-collapse collapse help-blocks__bottom" id="flush-help03" data-bs-parent="#accordionHelpQueries">
                      <div >
                        <button type="button" className="btn rounded-0 border-0 text-center bg-white inner-block">
                          <h5 className="fz14 fw600 mb-0">
                            Unable to download report in pdf.
                          </h5>
                        </button>
                        <button type="button" className="btn rounded-0 border-0 text-center bg-white inner-block">
                          <h5 className="fz14 fw600 mb-0">
                            How can I filter reports on monthly basis?
                          </h5>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="border-0 bg-transparent accordion-item">
                  <div className="position-relative p-0 accordion-header help-blocks help-blocks--purple">
                    <button className="text-center bg-transparent shadow-none accordion-button collapsed help-blocks__top" type="button" data-bs-toggle="collapse" data-bs-target="#flush-help04" aria-expanded="false" aria-controls="flush-help04">
                      <div className="position-absolute primary-box">
                        <div className="ts03 arrow" />
                      </div>
                      <h4 className="fz14 fw600">
                        Need login or sign up assistance.
                      </h4>
                      <p className="fz12 mb-0">
                        Issues regarding login or signup or your otp generation.
                      </p>
                    </button>
                    <div className="accordion-collapse collapse help-blocks__bottom" id="flush-help04" data-bs-parent="#accordionHelpQueries">
                      <div >
                        <button type="button" className="btn rounded-0 border-0 text-center bg-white inner-block">
                          <h5 className="fz14 fw600 mb-0">
                            Unable to download report in pdf.
                          </h5>
                        </button>
                        <button type="button" className="btn rounded-0 border-0 text-center bg-white inner-block">
                          <h5 className="fz14 fw600 mb-0">
                            How can I filter reports on monthly basis?
                          </h5>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="border-0 bg-transparent accordion-item">
                  <div className="position-relative p-0 accordion-header help-blocks help-blocks--brown">
                    <button className="text-center bg-transparent shadow-none accordion-button collapsed help-blocks__top" type="button" data-bs-toggle="collapse" data-bs-target="#flush-help05" aria-expanded="false" aria-controls="flush-help05">
                      <div className="position-absolute primary-box">
                        <div className="ts03 arrow" />
                      </div>
                      <h4 className="fz14 fw600">Need upgrade assistance.</h4>
                      <p className="fz12 mb-0">Want to upgrade to new version.</p>
                    </button>
                    <div className="accordion-collapse collapse help-blocks__bottom" id="flush-help05" data-bs-parent="#accordionHelpQueries">
                      <div >
                        <button type="button" className="btn rounded-0 border-0 text-center bg-white inner-block">
                          <h5 className="fz14 fw600 mb-0">
                            Unable to download report in pdf.
                          </h5>
                        </button>
                        <button type="button" className="btn rounded-0 border-0 text-center bg-white inner-block">
                          <h5 className="fz14 fw600 mb-0">
                            How can I filter reports on monthly basis?
                          </h5>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="border-0 bg-transparent accordion-item">
                  <div className="position-relative p-0 accordion-header help-blocks help-blocks--gray">
                    <button className="text-center bg-transparent shadow-none accordion-button collapsed help-blocks__top" type="button" data-bs-toggle="collapse" data-bs-target="#flush-help06" aria-expanded="false" aria-controls="flush-help06">
                      <div className="position-absolute primary-box">
                        <div className="ts03 arrow" />
                      </div>
                      <h4 className="fz14 fw600">
                        Need privacy policy assistance.
                      </h4>
                      <p className="fz12 mb-0">
                        What sort of data can be collected.
                      </p>
                    </button>
                    <div className="accordion-collapse collapse help-blocks__bottom" id="flush-help06" data-bs-parent="#accordionHelpQueries">
                      <div >
                        <button type="button" className="btn rounded-0 border-0 text-center bg-white inner-block">
                          <h5 className="fz14 fw600 mb-0">Is my date is secure?</h5>
                        </button>
                        <button type="button" className="btn rounded-0 border-0 text-center bg-white inner-block">
                          <h5 className="fz14 fw600 mb-0">
                            Want to know more about terms and conditions?
                          </h5>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="mt-auto w-100 msg-sender">
              <ul className="list-inline suggestions">
                <li>
                  <a href="#" className="btn rounded-0 primary-box text-white fz12 btn--primary tag-s">
                    Suggestion
                  </a>
                </li>
                <li>
                  <a href="#" className="btn rounded-0 primary-box text-white fz12 btn--primary tag-s">
                    Suggestion
                  </a>
                </li>
                <li>
                  <a href="#" className="btn rounded-0 primary-box text-white fz12 btn--primary tag-s">
                    Suggestion
                  </a>
                </li>
                <li>
                  <a href="#" className="btn rounded-0 primary-box text-white fz12 btn--primary tag-s">
                    Suggestion
                  </a>
                </li>
              </ul>
              <div className="bg-white mb-0 form-group">
                <a href="#" className="border-0 rounded-0 btn p-0 ts03 btn-transparent">
                  <img src="./../assets/images/icons/file-attachment-btn-icon.svg" alt="file attachment icon" />
                </a>
                <input value={message} onChange={(e) => setMessage(e.target.value)} type="text" className="border-0 rounded-0 fz12 form-control" placeholder="Write your issue here, you can attach link or upload screenshoot or file for more assistance..." />
                <a href="#" className="border-0 rounded-0 btn p-0 ts03 btn-transparent">
                  <img src="./../assets/images/icons/microphone-icon.svg" alt="microphone icon" />
                </a>
                <a role='button' onClick={createTicket} className="rounded-0 btn p-0 ts03 btn--primary-outlined-hovered ms-1">
                  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.56322 7.88394C2.02122 8.11144 1.6848 8.51643 1.67972 9.08976C1.67622 9.4906 1.94672 10.0289 2.48872 10.2489C2.71197 10.3398 5.84655 10.7614 5.84655 10.7614C5.84655 10.7614 6.67622 13.3839 6.97522 14.3056C7.0618 14.5723 7.11114 14.7031 7.30114 14.8773C7.62347 15.1723 8.16839 15.0798 8.40405 14.8431C9.02755 14.2181 10.0131 13.2539 10.0131 13.2539L10.4278 13.5914C10.4278 13.5914 12.2696 15.0606 13.2763 15.7523C13.8691 16.1598 14.2805 16.5848 14.9476 16.5873C15.2875 16.5889 15.8326 16.4198 16.1929 16.0073C16.431 15.7348 16.5837 15.2989 16.6428 14.9081C16.7771 14.0206 18.3531 4.42394 18.3464 4.08811C18.3357 3.55227 17.885 3.2506 17.5103 3.25393C17.275 3.25643 17.0811 3.32478 16.6496 3.45644C13.3117 4.47561 2.7843 7.79144 2.56322 7.88394ZM15.0131 5.75394C15.0131 5.75394 10.6147 9.58228 8.85714 11.3439C8.29405 11.9081 8.2543 12.8764 8.2543 12.8764L7.34581 9.96976L15.0131 5.75394Z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
