import { useEffect, useState } from "react";
import { axiosClientPy } from "../axiosClient";

const useFetch = (url, options) => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  const reFetch = async () => {
    try {
      const resp = await axiosClientPy.get(url, { headers: { Authorization: "Token " + window.sessionStorage.getItem("USER_TOKEN") } });
      const data = await resp?.data;
      setData(data?.data);
      setLoading(false);
    } catch (error) {
      setError(error?.response?.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    reFetch();
  }, [url]);

  return { data, error, loading, reFetch };
}

export default useFetch;