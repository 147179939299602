import React, { useState } from 'react'
import TopRightProfile from '../../TopRightProfile'
import { toast } from 'react-toastify';
import { axiosClientPy } from '../../../utils';

export default function CreateProjectDaoStep2({ projectData, setStep }) {
  const [input, setInput] = useState({
    proposalFee: '',
    votingFee: '',
    votingBalance: '',
    votingType: 'VotingFee'
  })

  const onSubmit = async (e) => {
    e.preventDefault();
    const headers = {
      Authorization: "Token " + window.sessionStorage.getItem("USER_TOKEN")
    }
    try {
      const mData = new FormData();
      mData.append("project_id", projectData?.uuid);
      mData.append("proposal_fee", input.proposalFee);
      mData.append("voting_fee", input.votingType === "VotingFee" ? input.votingFee + "-F" : input.votingBalance + "-B");
      mData.append("step_count", 5);
      const res = await axiosClientPy.patch("/project/project-creation", mData, { headers });
      window.location.reload();
    } catch (error) {
      toast.error("Internal Server Error!!!")
    }
  }

  return (
    <section className="pe-0 main-content">
      <div className="pt-0 main-content-inner box-steps--illustration">
        <TopRightProfile />
        {/*----- Completion stats row -----*/}
        <div className="primary-box completion-row completion-row--back">
          <div className="completion-row__left">
            <a href="#" className="redirect-back">
              <img src="../assets/images/icons/back-arrow.svg" alt="Back arrow" />
            </a>
            <div className>
              <h1 className="fw600 text-white">DAO</h1>
              <p className="m-0 grayText">
                Merging technology to remove third parties and centralized
                institutions from financial transactions.
              </p>
            </div>
          </div>
          <div className="completion-row__right">
            <img className="logo-crypto" src="../assets/images/defi-logo.svg" alt="" />
          </div>
        </div>
        {/* ============================== */}
        <div className="box-steps">
          <h2 className="text-center mb-0 fst-italic fw700">
            Adjust Your Token Fee
          </h2>
          <p className="text-center grayText--lighter">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vehicula
            massa in enim luctus. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Vehicula massa in enim luctus. Rutrum arcu.
          </p>
          <div className="w-100 form-box form-box--purple">
            <form onSubmit={onSubmit} className="form">
              <div className="top">
                <div className="form-group">
                  <div className="form-group__inner">
                    <label htmlFor className="label">
                      <div className="primary-box label__icon">
                        <img src="../assets/images/icons/nft-name.svg" alt="User icon" />
                      </div>
                    </label>
                    <input type="text" value={input.proposalFee} onChange={(e) => { setInput({ ...input, proposalFee: e.target.value }) }} className="rounded-0 border-0 bg-transparent p-0 form-control italic-placeholder" placeholder="Proposal Fee" />
                  </div>
                </div>
                <div className="form-group d-flex align-items-center justify-content-between">
                  <div className="m-0 fw600 form-group form-group--checkbox">
                    <input id="one" type="radio" onChange={() => { setInput({ ...input, votingType: "VotingFee" }) }} checked={input.votingType === "VotingFee"} name='votingtype' />
                    <label htmlFor="one">
                      <b />Voting Fee
                    </label>
                  </div>
                  <div className="checkbox-selection">
                    <h6 className="fw600 m-0">Or</h6>
                  </div>
                  <div className="m-0 fw600 form-group form-group--checkbox">
                    <input id="two" type="radio" onChange={() => { setInput({ ...input, votingType: "VotingBalance" }) }} checked={input.votingType === "VotingBalance"} name='votingtype' />
                    <label htmlFor="two">
                      <b />Voting Balance
                    </label>
                  </div>
                </div>
                {
                  input.votingType === "VotingFee"
                    ?
                    <div className="form-group">
                      <div className="form-group__inner">
                        <label htmlFor className="label">
                          <div className="primary-box label__icon">
                            <img src="../assets/images/icons/nft-name.svg" alt="User icon" />
                          </div>
                        </label>
                        <input type="text" value={input.votingFee} onChange={(e) => { setInput({ ...input, votingFee: e.target.value }) }} className="rounded-0 border-0 bg-transparent p-0 form-control italic-placeholder" placeholder="Voting Fee" />
                      </div>
                    </div>
                    :
                    <div className="form-group">
                      <div className="form-group__inner">
                        <label htmlFor className="label">
                          <div className="primary-box label__icon">
                            <img src="../assets/images/icons/nft-name.svg" alt="User icon" />
                          </div>
                        </label>
                        <input type="text" value={input.votingBalance} onChange={(e) => { setInput({ ...input, votingBalance: e.target.value }) }} className="rounded-0 border-0 bg-transparent p-0 form-control italic-placeholder" placeholder="Voting Balance" />
                      </div>
                    </div>
                }
              </div>
              <div className="btnGroup">
                <button type="button" className="btn rounded-0 btn--primary-outlined">
                  Cancel
                </button>
                <button type="submit" className="btn rounded-0 primary-box btn--primary">
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}