import Cookies from 'js-cookie';
import React, { useState } from 'react'
import TopRightProfile from '../../TopRightProfile';
import { axiosClientPy } from '../../../utils';
import { toast } from 'react-toastify';

export default function CreateProjectERC721Step3({ projectData, setStep }) {
  let mOldFun;
  if (projectData?.contract_functions_details?.length > 0) {
    mOldFun = {
      AutoIncrement: projectData?.contract_functions_details.filter(ele => ele.name === "AutoIncrement").length > 0,
      Burnable: projectData?.contract_functions_details.filter(ele => ele.name === "Burnable").length > 0,
      Mintable: projectData?.contract_functions_details.filter(ele => ele.name === "Mintable").length > 0,
      MaxCap: projectData?.contract_functions_details.filter(ele => ele.name === "MaxCap").length > 0,
      Pauseable: projectData?.contract_functions_details.filter(ele => ele.name === "Pauseable").length > 0,
      Upgradeable: projectData?.contract_functions_details.filter(ele => ele.name === "Upgradeable").length > 0
    }
  } else {
    mOldFun = {
      AutoIncrement: false,
      Burnable: false,
      Pauseable: false,
      Enumerable: false,
      ChangeURL: false,
      Upgradeable: false
    }
  }
  const [checkFunctions, setCheckFunctions] = useState(mOldFun);

  const onSubmit = async (e) => {
    e.preventDefault();
    const headers = {
      Authorization: "Token " + window.sessionStorage.getItem("USER_TOKEN")
    }
    try {
      let typeRes = await axiosClientPy.get("/main/project-function", { headers });
      let selectedType = typeRes?.data?.data;
      selectedType = selectedType?.filter(ele => checkFunctions[ele.name]);
      const mData = {
        "project_id": projectData?.uuid,
        "contract_functions": selectedType.map(ele => ele.uuid),
        "step_count": 7
      }
      if (selectedType.length > 0) {
        const res = await axiosClientPy.patch("/project/project-creation", mData, { headers });
        window.location.reload();
      } else {
        toast.error("Please select a valid Contract Type!!!");
      }
    } catch (error) {
      toast.error("Internal Server Error!!!");
    }
  }
  return (
    <section className="pe-0 main-content stepper-content">
      <div className="main-content-inner">
        <TopRightProfile />
        <div className="stepperForm">
          <div className="leftStepper">
            {/*----- Completion stats row -----*/}
            <div className="primary-box completion-row completion-row--back">
              <div className="completion-row__left">
                <button onClick={() => setStep(2)} className="redirect-back">
                  <img src="./assets/images/icons/back-arrow.svg" alt="Back arrow" />
                </button>
                <div >
                  <h1 className="fw600 text-white">ERC - 721</h1>
                  <p className="m-0 grayText">
                    Most basic token standard, used to create interchangeable
                    tokens.
                  </p>
                </div>
              </div>
              <div className="completion-row__right">
                <img className="#" src="./assets/images/wolf-logo-single.svg" alt="" />
                <img className="#" src="./assets/images/nft-logo.svg" alt="" />
              </div>
            </div>
            <div className="profile-edit erc721">
              <div className="left_stepperwrapper">
                <h3 className="fw700 grayText--lighter fz14">
                  Step 3.
                  <strong className="darkText fw700 fz14">Functions</strong>
                </h3>
                <p className="grayText--lighter fz12">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vehicula massa in enim luctus. Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit. Vehicula massa in enim luctus.
                  Rutrum arcu.
                </p>
                <div className="form-box form-box--grey profile-edit__left p-4 stepper-form-details">
                  <form onSubmit={onSubmit} className="form">
                    <div className="expandables mb-2">
                      <div className="expandable">
                        <div className="form-group--checkbox">
                          <input id="expandable-auto-increment" checked={checkFunctions?.AutoIncrement} onChange={() => { setCheckFunctions(prev => ({ ...prev, AutoIncrement: !prev.AutoIncrement })) }} type="checkbox" />
                          <label htmlFor="expandable-auto-increment">
                            <b /> Auto Increment
                          </label>
                          {/* <div class="expandable__inputs">
                                                  <div class="form-group">
                                                      <div class="form-group__inner">
                                                          <label for="" class="label">
                                                              <div class="primary-box label__icon">
                                                                  <img src="./assets/images/icons/nft-label.svg" alt="User icon" />
                                                              </div>
                                                          </label>
                                                          <input type="text" class="rounded-0 border-0 bg-transparent p-0 form-control italic-placeholder" placeholder="Add your Auto Increment" />
                                                      </div>
                                                  </div>
                                              </div> */}
                        </div>
                      </div>
                      <div className="expandable">
                        <div className="form-group--checkbox">
                          <input id="burnable" type="checkbox" checked={checkFunctions?.Burnable} onChange={() => { setCheckFunctions(prev => ({ ...prev, Burnable: !prev.Burnable })) }} />
                          <label htmlFor="burnable"> <b /> Burnable </label>
                          {/* <div class="expandable__inputs">
                                                  <div class="form-group">
                                                      <div class="form-group__inner">
                                                          <label for="" class="label">
                                                              <div class="primary-box label__icon">
                                                                  <img src="./assets/images/icons/nft-label.svg" alt="User icon" />
                                                              </div>
                                                          </label>
                                                          <input type="text" class="rounded-0 border-0 bg-transparent p-0 form-control italic-placeholder" placeholder="Add your Burnable" />
                                                      </div>
                                                  </div>
                                              </div> */}
                        </div>
                      </div>
                      <div className="expandable">
                        <div className="form-group--checkbox">
                          <input id="pauseable" type="checkbox" checked={checkFunctions?.Pauseable} onChange={() => { setCheckFunctions(prev => ({ ...prev, Pauseable: !prev.Pauseable })) }} />
                          <label htmlFor="pauseable"> <b /> Pauseable </label>
                          {/* <div class="expandable__inputs">
                                                  <div class="form-group">
                                                      <div class="form-group__inner">
                                                          <label for="" class="label">
                                                              <div class="primary-box label__icon">
                                                                  <img src="./assets/images/icons/nft-label.svg" alt="User icon" />
                                                              </div>
                                                          </label>
                                                          <input type="text" class="rounded-0 border-0 bg-transparent p-0 form-control italic-placeholder" placeholder="Add your Pauseable" />
                                                      </div>
                                                  </div>
                                              </div> */}
                        </div>
                      </div>
                      <div className="expandable">
                        <div className="form-group--checkbox">
                          <input id="enumerable" type="checkbox" checked={checkFunctions?.Enumerable} onChange={() => { setCheckFunctions(prev => ({ ...prev, Enumerable: !prev.Enumerable })) }} />
                          <label htmlFor="enumerable"> <b /> Enumerable </label>
                          {/* <div class="expandable__inputs">
                                                  <div class="form-group">
                                                      <div class="form-group__inner">
                                                          <label for="" class="label">
                                                              <div class="primary-box label__icon">
                                                                  <img src="./assets/images/icons/nft-label.svg" alt="User icon" />
                                                              </div>
                                                          </label>
                                                          <input type="text" class="rounded-0 border-0 bg-transparent p-0 form-control italic-placeholder" placeholder="Add your Enumerable" />
                                                      </div>
                                                  </div>
                                              </div> */}
                        </div>
                      </div>
                      <div className="expandable">
                        <div className="form-group--checkbox">
                          <input id="change-URL" type="checkbox" checked={checkFunctions?.ChangeURL} onChange={() => { setCheckFunctions(prev => ({ ...prev, ChangeURL: !prev.AutoIncrement })) }} />
                          <label htmlFor="change-URL"> <b /> Change URL </label>
                          {/* <div class="expandable__inputs">
                                                  <div class="form-group">
                                                      <div class="form-group__inner">
                                                          <label for="" class="label">
                                                              <div class="primary-box label__icon">
                                                                  <img src="./assets/images/icons/nft-label.svg" alt="User icon" />
                                                              </div>
                                                          </label>
                                                          <input type="text" class="rounded-0 border-0 bg-transparent p-0 form-control italic-placeholder" placeholder="Add your URL" />
                                                      </div>
                                                  </div>
                                              </div> */}
                        </div>
                      </div>
                      {/* <div className="expandable">
                        <div className="form-group--checkbox">
                          <input id="upgradable" type="checkbox" checked={checkFunctions?.Upgradeable} onChange={() => { setCheckFunctions(prev => ({ ...prev, Upgradeable: !prev.Upgradeable })) }} />
                          <label htmlFor="upgradable">
                            <b /> Upgradeable
                          </label>
                        </div>
                      </div> */}
                    </div>
                    <div className="btnGroup">
                      <button type="button" className="btn rounded-0 px-2 text-nowrap btn--transparent-outlined">
                        Cancel
                      </button>
                      <button type="submit" className="btn rounded-0 primary-box btn--primary">
                        Next
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="right_stepperwrapper">
                <h3 className="fw700 fz14">Next Step: Overview</h3>
                <p className="grayText--lighter fz12">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vehicula massa in enim luctus. Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit. Vehicula massa in enim luctus.
                  Rutrum arcu.
                </p>
                <div className="text-center profile-edit__right">
                  <img className="w-100" src="./assets/images/stepper1.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
          {/* leftStepper */}
          <div className="rightStepper">
            <div className="stepper_Steps">
              <div className="stepper-head">
                <a href="#" className="stepper-card-head">
                  <div className="d-flex justify-content-between title-row stepper_Links">
                    <button onclick=" location.href='./create-new-ERC721-step2.html' " className="stepper-redirect-link">
                      <img className="rounded mx-auto d-block" style={{ transform: 'rotate(180deg)' }} src="assets/images/icons/arrow-right.svg" alt="" />
                    </button>
                    <h2 className="fw700">Smart Contract Steps</h2>
                    <button onclick=" location.href='./create-new-ERC721-step4.html' " className="stepper-redirect-link">
                      <img className="rounded mx-auto d-block" src="assets/images/icons/arrow-right.svg" alt="" />
                    </button>
                  </div>
                </a>
              </div>
              <hr className="mt-0" />
              <div className="stepper-counts">
                <div className="outlined-card">
                  <a role="button" className="stepper-card stepper-card--pink">
                    <div className="title-row stepper_Links">
                      <div className="number">
                        <h2>1.</h2>
                      </div>
                      <h2 className="fw500">Token Name</h2>
                    </div>
                  </a>
                </div>
                <div className="outlined-card">
                  <a role="button" className="stepper-card stepper-card--orange">
                    <div className="title-row stepper_Links">
                      <div className="number">
                        <h2>2.</h2>
                      </div>
                      <h2 className="fw500">Token Symbol</h2>
                    </div>
                  </a>
                </div>
                <div className="outlined-card">
                  <a role="button" className="stepper-card stepper-card--yellow">
                    <div className="title-row stepper_Links">
                      <div className="number">
                        <h2>3.</h2>
                      </div>
                      <h2 className="fw500">Functions</h2>
                    </div>
                  </a>
                </div>
                <div className="outlined-card outlined-card--inactive">
                  <a role="button" className="stepper-card stepper-card--blue">
                    <div className="title-row stepper_Links">
                      <div className="number">
                        <h2>4.</h2>
                      </div>
                      <h2 className="fw500">Overview</h2>
                    </div>
                  </a>
                </div>
                <div className="outlined-card outlined-card--inactive">
                  <a role="button" className="stepper-card stepper-card--pink">
                    <div className="title-row stepper_Links">
                      <div className="number">
                        <h2>5.</h2>
                      </div>
                      <h2 className="fw500">Deploy ERC-721</h2>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* rightStepper */}
        </div>
      </div>
    </section>
  )
}
