import axios from "axios";

export const axiosClient = axios.create({
  baseURL: 'http://44.239.177.29:81/api/',
  // baseURL: 'http://localhost:5000/api/',
  headers: {
    "Content-Type": "application/json",
    "Accept": "*/*"
  },
})

export const axiosClientPy = axios.create({
  baseURL: 'https://demo.chainery.io/api/',
  // baseURL: 'http://localhost:5000/api/',
  headers: {
    "Content-Type": "application/json",
    "Accept": "*/*"
  },
})