import React, { useEffect, useState } from 'react'
import { getDateFormat } from '../utils'

const mScript = `
// Toggle btw services' & projects' GRIDS on dropdown-item selection //
$(".dd-item-custom").on("click", function () {
  // Remove the "dd-active" class from all dropdown items
  $(".dd-item-custom").removeClass("dd-active");
  $(".dd-item-line").removeClass("dd-active");

  // Get the selected class and add the "dd-active" class to the clicked item
  var selectedClass = $(this).data("class");
  
  $(this).addClass("dd-active");

  // Toggle the selected class in div.toggle-grid
  $("#toggle-grid")
    .removeClass()
    .addClass("toggle-grid")
    .addClass(selectedClass);

  $("#toggle-line-chart")
    .removeClass()
    .addClass("toggle-line-chart")
    .addClass(selectedClass);

  if (selectedClass == "product-grid") {
    $("#js--deploy").html("Add new product");
    $("#dropdownMenuButton").html("Products");
    $(".dd-item-line").first().addClass("dd-active")
    $("#ddLineMenuBtn").html("Products");
  } else {
    $("#js--deploy").html("Create new services");
    $("#dropdownMenuButton").html("Services");
    $(".dd-item-line").last().addClass("dd-active")
    $("#ddLineMenuBtn").html("Services");
  }
});
// =================================================================== //
`;

function ProjectListWidget({ data }) {
  const [filter, setFilter] = useState("Year");

  useEffect(() => {
    if (data) {
      const script = document.createElement("script");
      script.innerHTML = mScript;
      script.id = "dashboardScript";
      document.body.append(script);

      return () => {
        document.getElementById("dashboardScript").remove();
      }
    }
  }, [data]);

  return (
    <>
      <div id="projectList" className="bg-white project-table-list">
        <div className="top-row">
          <div className="title-row">
            <h2 className="mb-0 lh1-5 fw500">Project(s) List</h2>
            <div className="mb-0">
              <span>Total Projects - </span>
              <span className="ms-1 fw700 grayText">{data?.length}</span>
            </div>
          </div>
          <div className="top-row__options">
            {/* <button className="btn border-0 rounded-0 fw500 btn--deploy d-none" id="js--deploy" type="button">
              Add new product
            </button> */}
            <label htmlFor className="fw500">Project Type:</label>
            <div className="dropdown">
              <button className="btn border-0 dropdown-toggle btn-dd btn-dd--primary" type="button" data-bs-toggle="dropdown" aria-expanded="false" id="dropdownMenuButton">
                Products
              </button>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item dd-item-custom dd-active" role='button' data-class="product-grid">Products</a>
                </li>
                <li>
                  <a className="dropdown-item dd-item-custom" role='button' data-class="service-grid">Services</a>
                </li>
              </ul>
            </div>
            <div className="dropdown">
              <button className="btn border-0 dropdown-toggle btn-dd btn-dd--primary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {filter}
              </button>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" role="button" onClick={() => setFilter("Day")} >Day</a>
                </li>
                <li>
                  <a className="dropdown-item" role="button" onClick={() => setFilter("Week")} >Week</a>
                </li>
                <li>
                  <a className="dropdown-item" role="button" onClick={() => setFilter("Year")} >Year</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* DataTable */}
        <div id="toggle-grid" className="toggle-grid product-grid">
          <div className="table-responsive chainery-table-structure table-structure-products">
            {
              data?.length > 0 ?
                <table id="example" className="w-100 mb-0 table">
                  <thead>
                    <tr>
                      <th className="fw600 text-start">
                        <span>Sr.No</span>
                      </th>
                      <th className="fw600 text-start">
                        <span>Contract Name</span>
                      </th>
                      <th className="fw600">
                        <span>Contract Type</span>
                      </th>
                      <th className="fw600">
                        <span>Created</span>
                      </th>
                      <th className="text-start fw600">
                        <span>Contract Address</span>
                      </th>
                      <th className="fw600">
                        <span>Network</span>
                      </th>
                      <th className="fw600">
                        <span>Status</span>
                      </th>
                      <th className="fw600">
                        <span>Action</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.filter(ele => ele.project_type == "product").map((element, index) => (
                      <tr key={index}>
                        <td>
                          <h4 className="mb-0 fw700">{index + 1}.</h4>
                        </td>
                        <td>
                          <h4 className="mb-0 fw500">{element.name}</h4>
                        </td>
                        <td>
                          <h4 className="mb-0 fw700">{element?.contract_type_details?.name}</h4>
                        </td>
                        <td>
                          <div className="when-created">
                            <div className="grayText">
                              <img src="../assets/images/icons/calender-neutral.svg" alt="" />
                              <span >{getDateFormat(element.created_at)[0]}</span>
                            </div>
                            <div>
                              <img src="../assets/images/icons/clock.svg" alt="" />
                              <span >{getDateFormat(element.created_at)[1]}</span>
                            </div>
                          </div>
                        </td>
                        <td className="text-start">
                          <div className="mb-0 grayText--lighter fw400 c-address">
                            {element.contract_address ? element.contract_address : "N/A"}
                          </div>
                        </td>
                        <td>
                          <div className="blockchain">
                            <div className="primary-box imgCover">
                              <img src={`../assets/images/${element?.contract_network_details?.name?.toLowerCase()}-light.png`} alt={`${element?.contract_network_details?.name} Logo`} />
                            </div>
                            <strong className="fw500">{element?.contract_network_details?.name}</strong>
                          </div>
                        </td>
                        <td>
                          {
                            element.project_status === "verified" ?
                              <div className="status status--green">Verified</div>
                              : element.contract_address ? <div className="status status--active">Deployed</div>
                                : <div className="status status--red">Inprogress</div>
                          }
                        </td>
                        <td>
                          <a href={"/new-project?projectID=" + element.uuid} className="fz12 fw700">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18.827" height="22.783" viewBox="0 0 18.827 22.783">
                              <g id="Icon_feather-share" data-name="Icon feather-share" transform="translate(-4.5 -1.5)">
                                <path id="Path_15839" data-name="Path 15839" d="M6,18v7.913a1.978,1.978,0,0,0,1.978,1.978h11.87a1.978,1.978,0,0,0,1.978-1.978V18" transform="translate(0 -5.108)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} />
                                <path id="Path_15840" data-name="Path 15840" d="M19.913,6.957,15.957,3,12,6.957" transform="translate(-2.043)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} />
                                <path id="Path_15841" data-name="Path 15841" d="M18,3V15.859" transform="translate(-4.087)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} />
                              </g>
                            </svg>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                : <div class="chart-container illustrationDash">
                  <img src="../assets/images/icons/dashboard-nothing.svg" />
                  <img src="../assets/images/icons/dashboard-background-inject.svg" />
                </div>
            }
          </div>
          <div className="table-responsive chainery-table-structure table-structure-services">
            {
              data?.length > 0 ?
                <table id="example" className="w-100 mb-0 table">
                  <thead>
                    <tr>
                      <th className="fw600 text-start">
                        <span>Sr.No</span>
                      </th>
                      <th className="fw600 text-start">
                        <span>Services Name</span>
                      </th>
                      <th className="fw600">
                        <span>Created</span>
                      </th>
                      <th className="fw600">
                        <span>Status</span>
                      </th>
                      <th className="fw600">
                        <span>Action</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.filter(ele => ele.project_type == "service").map((element, index) => (
                      <tr key={index}>
                        <td>
                          <h4 className="mb-0 fw700">{index + 1}.</h4>
                        </td>
                        <td>
                          <h4 className="mb-0 fw500">{element.name}</h4>
                        </td>
                        <td>
                          <div className="when-created">
                            <div className="grayText">
                              <img src="../assets/images/icons/calender-neutral.svg" alt="" />
                              <span >{getDateFormat(element.created_at)}</span>
                            </div>
                            <div>
                              <img src="../assets/images/icons/clock.svg" alt="" />
                              <span >16hrs:44min (EST)</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          {
                            element.project_status === "in_progress" ?
                              <div className="status status--inactive">Waiting</div>
                              : <div className="status status--active">Responded</div>
                          }
                        </td>
                        <td>
                          <a href={"/new-project?projectID=" + element.uuid} className="fz12 fw700">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18.827" height="22.783" viewBox="0 0 18.827 22.783">
                              <g id="Icon_feather-share" data-name="Icon feather-share" transform="translate(-4.5 -1.5)">
                                <path id="Path_15839" data-name="Path 15839" d="M6,18v7.913a1.978,1.978,0,0,0,1.978,1.978h11.87a1.978,1.978,0,0,0,1.978-1.978V18" transform="translate(0 -5.108)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} />
                                <path id="Path_15840" data-name="Path 15840" d="M19.913,6.957,15.957,3,12,6.957" transform="translate(-2.043)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} />
                                <path id="Path_15841" data-name="Path 15841" d="M18,3V15.859" transform="translate(-4.087)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} />
                              </g>
                            </svg>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                : <div class="chart-container illustrationDash">
                  <img src="../assets/images/icons/dashboard-nothing.svg" />
                  <img src="../assets/images/icons/dashboard-background-inject.svg" />
                </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default ProjectListWidget