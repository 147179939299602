import React, { useState } from 'react'
import TopRightProfile from '../../TopRightProfile'
import { axiosClientPy } from '../../../utils';
import { toast } from 'react-toastify';


export default function CreateProjectMarketplace721Step2({ projectData }) {
  const [input, setInput] = useState({
    ChangeListingPrice: false,
    ChangeListedItemPrice: false,
    ChangeOwner: false,
    CancelListing: false
  })

  const onSubmit = async (e) => {
    e.preventDefault();
    const headers = {
      Authorization: "Token " + window.sessionStorage.getItem("USER_TOKEN")
    }
    try {
      let typeRes = await axiosClientPy.get("/main/project-function", { headers });
      let selectedType = typeRes?.data?.data;
      selectedType = selectedType?.filter(ele => input[ele.name]);
      const mData = {
        "project_id": projectData?.uuid,
        "contract_functions": selectedType.map(ele => ele.uuid),
        "step_count": 5
      }
      const res = await axiosClientPy.patch("/project/project-creation", mData, { headers });
      window.location.reload();
    } catch (error) {
      toast.error("Internal Server Error!!!")
    }
  }
  return (
    <section className="pe-0 main-content">
      <div className="pt-0 main-content-inner box-steps--illustration">
        <TopRightProfile />
        {/*----- Completion stats row -----*/}
        <div className="primary-box completion-row completion-row--back">
          <div className="completion-row__left">
            <a href="/new-project" className="redirect-back">
              <img src="../assets/images/icons/back-arrow.svg" alt="Back arrow" />
            </a>
            <div className>
              <h1 className="fw600 text-white">Marketplace 721</h1>
              <p className="m-0 grayText">
                Merging technology to remove third parties and centralized
                institutions from financial transactions.
              </p>
            </div>
          </div>
          <div className="completion-row__right">
            <img className="logo-crypto" src="../assets/images/defi-logo.svg" alt="" />
          </div>
        </div>
        {/* ============================== */}
        <div className="box-steps">
          <h2 className="text-center mb-0 fst-italic fw700">
            Active Functions For Your Marketplace 721
          </h2>
          <p className="text-center grayText--lighter">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vehicula
            massa in enim luctus. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Vehicula massa in enim luctus. Rutrum arcu.
          </p>
          <div className="w-100 form-box form-box--purple">
            <form onSubmit={onSubmit} className="form">
              <div className="top">
                <div className="fw600 m-0 form-group form-group--checkbox">
                  <input id="one" type="checkbox" checked={input.ChangeListingPrice} onChange={() => { setInput(prev => ({ ...prev, ChangeListingPrice: !prev.ChangeListingPrice })) }} />
                  <label htmlFor="one">
                    <b />
                    Change Listing Price
                  </label>
                </div>
                <div className="mt-4 fw600 m-0 form-group form-group--checkbox">
                  <input id="two" type="checkbox" checked={input.ChangeListedItemPrice} onChange={() => { setInput(prev => ({ ...prev, ChangeListedItemPrice: !prev.ChangeListedItemPrice })) }} />
                  <label htmlFor="two">
                    <b />
                    Change Listed Item Price
                  </label>
                </div>
                <div className="mt-4 fw600 m-0 form-group form-group--checkbox">
                  <input id="three" type="checkbox" checked={input.ChangeOwner} onChange={() => { setInput(prev => ({ ...prev, ChangeOwner: !prev.ChangeOwner })) }} />
                  <label htmlFor="three">
                    <b />
                    Change Owner
                  </label>
                </div>
                <div className="mt-4 fw600 m-0 form-group form-group--checkbox">
                  <input id="four" type="checkbox" checked={input.CancelListing} onChange={() => { setInput(prev => ({ ...prev, CancelListing: !prev.CancelListing })) }} />
                  <label htmlFor="four">
                    <b />
                    Cancel Listing
                  </label>
                </div>
              </div>
              <div className="btnGroup mt-5">
                <button type="submit" className="btn rounded-0 primary-box btn--primary">
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}
