import { useEffect } from "react";

export default function AboutProjectWidget({ data, left = true, right = true }) {

  const mScript = `
// POLAR-PIE chart using chart.js - https://www.chartjs.org/ //
if (document.getElementById("polarChartProj")) {
  let progPieChart = document.getElementById("polarChartProj").getContext("2d");

  let data = {
    labels: [
      "Ethereum Project(s)",
      "Binance Project(s)",
      "Polygon Project(s)",
      "Cardano Project(s)",
    ],
    datasets: [
      {
        label: "",
        data: [${data?.filter(ele => ele.contract_network_details?.name === "Ethereum").length}, 
        ${data?.filter(ele => ele.contract_network_details?.name === "Binance").length}, 
        ${data?.filter(ele => ele.contract_network_details?.name === "Polygon").length},
        ${data?.filter(ele => ele.contract_network_details?.name === "Cardano").length}],
        backgroundColor: ["#EBEFF4", "#D2F4FF", "#616C7C", "#EFDFEF"],
      },
    ],
  };

  let maxVal1 = 0;
  for (var i = 0; i < data.datasets[0].data.length; i++) {
    if (data.datasets[0].data[i] > maxVal1) {
      maxVal1 = data.datasets[0].data[i];
    }
  }

  var myChart = new Chart(progPieChart, {
    plugins: [ChartDataLabels],
    type: "polarArea",
    data: data,
    options: {
      borderWidth: 0,
      responsive: true,
      scales: {
        r: {
          min: -maxVal1,
          max: maxVal1,
          beginAtZero: true,
          ticks: {
            display: false, // Remove vertical numbers
          },
          grid: {
            display: false, // Removes the circulair lines
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          formatter: (value, polarData1Ctx) => {
            let sum = 0;
            let dataArr = polarData1Ctx.chart.data.datasets[0].data;
            dataArr.map((data) => {
              sum += data;
            });
            let percentage = value;
            return percentage;
          },
          color: "#0D121C",
          font: {
            weight: "400",
            size: 14,
          },
        },
      },
    },
  });
}
  `;

  useEffect(() => {
    if (data) {
      if (document.getElementById("polarChartAboutProjectWidget")) {
        document.body.removeChild(document.getElementById("polarChartAboutProjectWidget"))
        const script = document.createElement("script");
        script.innerHTML = mScript;
        script.id = "polarChartAboutProjectWidget";
        document.body.append(script);
      } else {
        const script = document.createElement("script");
        script.innerHTML = mScript;
        script.id = "polarChartAboutProjectWidget";
        document.body.append(script);
      }
    }
  }, [data])

  return (
    <>
      <div className="about-blockchain-projects">
        {left &&
          <div className="primary-box left">
            <div className="top-row">
              <div className="title-row">
                <h2 className="mb-0 lh1-5 fw500">About Projects</h2>
                <div className="mb-0">
                  <span>Total Projects</span>
                  <span className="ms-1 fw700 grayText">{data?.length}</span>
                </div>
              </div>
              <div className="dropdown">
                <button className="btn mb-2 border-0 dropdown-toggle btn-dd btn-dd--white" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Year
                </button>
                <ul className="dropdown-menu">
                  <li><a className="dropdown-item" href="#">Month</a></li>
                  <li><a className="dropdown-item" href="#">Year</a></li>
                </ul>
              </div>
            </div>
            <div className="about-projects">
              <div className="polarChart">
                <div className="polarChartInner">
                  <canvas id="polarChartProj" />
                </div>
              </div>
              <ul className="list-inline legends">
                <li>
                  <div className="pair">
                    <div className="pair__color pair__color--off-white" />
                    <div className="pair__text">
                      Ethereum Project(s)
                      <br />
                      <b className="fw700">{data?.filter(ele => ele.contract_network_details?.name === "Ethereum").length}</b>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="pair">
                    <div className="pair__color pair__color--blue" />
                    <div className="pair__text">
                      Cardano Project(s)
                      <br />
                      <b className="fw700">{data?.filter(ele => ele.contract_network_details?.name === "Cardano").length}</b>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="pair">
                    <div className="pair__color pair__color--pink" />
                    <div className="pair__text">
                      Binance Project(s) <br />
                      <b className="fw700">{data?.filter(ele => ele.contract_network_details?.name === "Binance").length}</b>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="pair">
                    <div className="pair__color pair__color--gray" />
                    <div className="pair__text">
                      Polygon Project(s) <br />
                      <b className="fw700">{data?.filter(ele => ele.contract_network_details?.name === "Polygon").length}</b>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>}
        {right &&
          <div className="right">
            <div className="chainery-card chainery-card--blue">
              <div className="w-100 top">
                <div className="title-row">
                  <h2 className="fw600">
                    <img src="./assets/images/cardano-full.svg" alt="" />
                  </h2>
                </div>
                <button className="redirect-link">
                  <img src="assets/images/icons/arrow-right.svg" alt="" />
                </button>
              </div>
              <div className="bottom">
                <div className="text">
                  <h6 className="lh-1 fw500">Total: {data?.filter(ele => ele?.contract_network_details?.name === "Cardano").length}</h6>
                  {/* <div className="rounded-circle imgCover trending-up">
                    <svg width={13} height={7} viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.313 1L7.13627 4.95835L4.93796 2.87504L1.64062 6" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M8.67578 1H11.3137V3.5" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                  <span className="ms-1 fw700 greenText">105.23%</span> */}
                </div>
                <div className="sparkline">
                  <canvas id="sparkLine1" />
                </div>
              </div>
            </div>
            <div className="chainery-card chainery-card--green">
              <div className="w-100 top">
                <div className="title-row">
                  <h2 className="fw600">
                    <img src="./assets/images/polygon-full.svg" alt="" />
                  </h2>
                </div>
                <button className="redirect-link">
                  <img src="assets/images/icons/arrow-right.svg" alt="" />
                </button>
              </div>
              <div className="bottom">
                <div className="text">
                  <h6 className="lh-1 fw500">Total: {data?.filter(ele => ele?.contract_network_details?.name === "Polygon").length}</h6>
                  {/* <div className="rounded-circle imgCover trending-up">
                    <svg width={13} height={7} viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.313 1L7.13627 4.95835L4.93796 2.87504L1.64062 6" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M8.67578 1H11.3137V3.5" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                  <span className="ms-1 fw700 greenText">105.23%</span> */}
                </div>
                <div className="sparkline">
                  <canvas id="sparkLine2" />
                </div>
              </div>
            </div>
            <div className="chainery-card chainery-card--red">
              <div className="w-100 top">
                <div className="title-row">
                  <h2 className="fw600">
                    <img src="./assets/images/binance-full.svg" alt="" />
                  </h2>
                </div>
                <button className="redirect-link">
                  <img src="assets/images/icons/arrow-right.svg" alt="" />
                </button>
              </div>
              <div className="bottom">
                <div className="text">
                  <h6 className="lh-1 fw500">Total: {data?.filter(ele => ele?.contract_network_details?.name === "Binance").length}</h6>
                  {/* <div className="rounded-circle imgCover trending-up">
                    <svg width={13} height={7} viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.313 1L7.13627 4.95835L4.93796 2.87504L1.64062 6" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M8.67578 1H11.3137V3.5" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                  <span className="ms-1 fw700 greenText">105.23%</span> */}
                </div>
                <div className="sparkline">
                  <canvas id="sparkLine3" />
                </div>
              </div>
            </div>
            <div className="chainery-card chainery-card--purple-noGradient">
              <div className="w-100 top">
                <div className="title-row">
                  <h2 className="fw600">
                    <img src="./assets/images/ethereum-full.svg" alt="" />
                  </h2>
                </div>
                <button className="redirect-link">
                  <img src="assets/images/icons/arrow-right.svg" alt="" />
                </button>
              </div>
              <div className="bottom">
                <div className="text">
                  <h6 className="lh-1 fw500">Total: {data?.filter(ele => ele?.contract_network_details?.name === "Ethereum").length}</h6>
                  {/* <div className="rounded-circle imgCover trending-up">
                    <svg width={13} height={7} viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.313 1L7.13627 4.95835L4.93796 2.87504L1.64062 6" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M8.67578 1H11.3137V3.5" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                  <span className="ms-1 fw700 greenText">105.23%</span> */}
                </div>
                <div className="sparkline">
                  <canvas id="sparkLine4" />
                </div>
              </div>
            </div>
          </div>}
      </div>
    </>
  )
}
