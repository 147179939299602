import React, { useState } from 'react'
import TopRightProfile from '../../TopRightProfile'

export default function CreateProjectServiceType2({ projectData, setStep }) {


  return (
    <>
      <section className="pe-0 main-content stepper-content">
        <div className="main-content-inner">
          <TopRightProfile />
          <div className="stepperForm">
            <div className="leftStepper">
              {/*----- Completion stats row -----*/}
              <div className="primary-box completion-row completion-row--back">
                <div className="completion-row__left">
                  <a onClick={() => { setStep(1) }} role="button" className="redirect-back">
                    <img src="../assets/images/icons/back-arrow.svg" alt="Back arrow" />
                  </a>
                  <div >
                    <h1 className="fw600 text-white">
                      Project By Services - {projectData?.name}
                    </h1>
                    <p className="m-0">List on DEX, Task Management, Tokenomics</p>
                    <div className="mb-0">
                      <span className="fw700">Last Updated: </span>
                      <span className="ms-1"> 01-June-2022 - 03hr:55min (EST)</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile-edit bg-white">
                <div className="white-paper">
                  <div className="editable-section editable-latest">
                    <div className="preview-details">
                      <h1>Build a dynamite UI from scratch 🧨</h1>
                      <p>
                        CKE editor comes with a rich, customizable UI that you
                        can easily adapt to your needs. But it's an option, not
                        a must! If you app requires a completly new look , you
                        can ditch the UI altogether and
                        <strong>use CKEditor 5 as a headless editor.</strong>
                      </p>
                      <h2>
                        Work with React, Vue, Angular, or any other framework
                      </h2>
                      <p>If you take the headless route,the sky is limit.</p>
                      <p>
                        This demo has a interfaced developed with
                        <a href>React</a>, but that's just an example.With
                        the headless approach,you can develop your perfect UI in
                        <strong>any framework you want.</strong>
                      </p>
                      <h2>
                        Use an existing UI framework or your own design system
                      </h2>
                      <p>
                        A headless editior seamlessly blends with your existing
                        design.This ensures a
                        <strong>consistent user experience. </strong>no matter
                        which UI framework you use.
                      </p>
                      <p>
                        You can easily integrate CKEditor with any solution,
                        including:
                      </p>
                      <ul>
                        <li>Bootstrap</li>
                        <li>Material UI</li>
                        <li>Tailwind CSS</li>
                        <li>Or your own design System 💪</li>
                      </ul>
                    </div>
                    <div className="text-end">
                      <a onClick={() => { setStep(1) }} role="button" className="btn me-3 rounded-0 btn--primary-outlined">
                        Edit
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
