import React, { useState } from 'react'
import ShowCodeModal from '../Modals/ShowCodeModal';
import { DAO } from '../../../utils/contracts/contract';
import VerifiedSuccessModal from '../Modals/VerifiedSuccessModal';
import Loading from '../../Loading';
import TopRightProfile from '../../TopRightProfile';
import { axiosClientPy } from '../../../utils';
import { toast } from 'react-toastify';
import { Marketplace721 } from '../../../utils/contracts/Marketplace721';

export default function CreateProjectMarketplace721Step6({ projectData }) {
  const [loading, setLoading] = useState(false);
  const DeployedAddress = projectData?.contract_address;
  const [isVerified, setIsVerified] = useState(projectData?.project_status === "verified" ? true : false);
  const [scValues, setScValues] = useState({
    name: projectData?.name ? projectData?.name : '',
    ChangeListingPrice: projectData?.contract_functions_details.filter(ele => ele.name === "ChangeListingPrice").length > 0,
    ChangeListedItemPrice: projectData?.contract_functions_details.filter(ele => ele.name === "ChangeListedItemPrice").length > 0,
    ChangeOwner: projectData?.contract_functions_details.filter(ele => ele.name === "ChangeOwner").length > 0,
    GetAuctionItemList: projectData?.contract_functions_details.filter(ele => ele.name === "GetAuctionItemList").length > 0,
    CancelListing: projectData?.contract_functions_details.filter(ele => ele.name === "CancelListing").length > 0,
    UpdatePrice: projectData?.contract_functions_details.filter(ele => ele.name === "UpdatePrice").length > 0,
    MyListedAuctionList: projectData?.contract_functions_details.filter(ele => ele.name === "MyListedAuctionList").length > 0,
    Auction: projectData?.contract_functions_details.filter(ele => ele.name === "Auction").length > 0,
    Bid: projectData?.contract_functions_details.filter(ele => ele.name === "Bid").length > 0,
    EndAuction: projectData?.contract_functions_details.filter(ele => ele.name === "EndAuction").length > 0,
    ChangeAuctionFee: projectData?.contract_functions_details.filter(ele => ele.name === "ChangeAuctionFee").length > 0,
    FetchAuctionItem: projectData?.contract_functions_details.filter(ele => ele.name === "FetchAuctionItem").length > 0,
    FetchListedMyAuctionItem: projectData?.contract_functions_details.filter(ele => ele.name === "FetchListedMyAuctionItem").length > 0,
  });

  const verify = async (e) => {
    if (!document.getElementById("VerifyButton").getAttribute("data-bs-target")) {
      setLoading(true);
      const headers = {
        Authorization: "Token " + window.sessionStorage.getItem("USER_TOKEN")
      }
      try {
        const mPayload1 = {
          project_id: projectData?.uuid
        }
        await axiosClientPy.post("project/verify-contract", mPayload1, { headers });
        setIsVerified(true);
        setLoading(false);
        document.getElementById("VerifyButton").setAttribute("data-bs-target", "#verifiedModal");
        document.getElementById("VerifyButton").setAttribute("data-bs-toggle", "modal");
        document.getElementById("VerifyButton").click();
      } catch (error) {
        setLoading(false);
        toast.error("Internal Server Error!!!");
        console.log(error);
      }
    }
  }

  return (
    <>
      <Loading loading={loading} />
      <section className="pe-0 main-content">
        <div className="pt-0 main-content-inner">
          <TopRightProfile />
          {/*----- Completion stats row -----*/}
          <div className="primary-box completion-row completion-row--back">
            <div className="completion-row__left">
              <a href="/new-project" className="redirect-back">
                <img src="../assets/images/icons/back-arrow.svg" alt="Back arrow" />
              </a>
              <div >
                <h1 className="fw600 text-white">Dao</h1>
                <p className="m-0 grayText">
                  Merging technology to remove third parties and centralized
                  institutions from financial transactions.
                </p>
              </div>
            </div>
            <div className="completion-row__right">
              <img className="logo-crypto" src="/assets/images/dao-logo.svg" alt="" />
              {
                isVerified ?
                  <button className="btn rounded-0 btn--white-outlined" data-bs-target="#showCodeModal" data-bs-toggle="modal">
                    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.3337 10C18.3337 10 15.8337 15 10.0003 15C4.16699 15 1.66699 10 1.66699 10C1.66699 10 4.16699 5 10.0003 5C15.8337 5 18.3337 10 18.3337 10Z" strokeLinecap="round" />
                      <circle cx={10} cy={10} r="2.5" strokeLinecap="round" />
                    </svg>
                    <span className="fw600">Show code</span>
                  </button>
                  :
                  <button className="btn rounded-0 btn--deploy" onClick={verify} id="VerifyButton">
                    <span className="fw400">Verify</span>
                  </button>
              }
            </div>
          </div>
          {/* ============================== */}
          <div className="box-steps box-steps--successfully">
            <h5 className="text-center mb-0 text-capitalize fw700">
              Dao - Deployed Successfully!
            </h5>
            <h5 className="text-center mb-0 text-capitalize fw500">
              Deployed Contract Address : <span className='fw700'>{DeployedAddress}</span>
            </h5>
            <a href={
              `${projectData?.contract_network_details?.name === "Binance" ? "https://testnet.bscscan.com/"
                : projectData?.contract_network_details?.name === "Ethereum" ? "https://goerli.etherscan.io/"
                  : "https://mumbai.polygonscan.com/"}address/${DeployedAddress}`
            } target='__blank' className='mt-3 btn rounded-0 primary-box btn--primary'>
              View on {projectData?.contract_network_details?.name} BlockChain.
            </a>
            <p className="text-center grayText--lighter">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vehicula
              massa in enim luctus. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Vehicula massa in enim luctus. Rutrum arcu.
            </p>
            <div className="w-100">
              <img src="/assets/images/animations/deployed-successfully/deployed-successfully.svg" alt="Deployed successfully illustration" />
            </div>
          </div>
        </div>
      </section>

      <VerifiedSuccessModal name="Dao" />
      <ShowCodeModal data={projectData} read={true} write={true} code={Marketplace721(scValues)} />
    </>
  )
}