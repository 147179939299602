import { createGlobalStyle } from "styled-components";

const PageStyle = createGlobalStyle`
`;

const Loading = ({ loading }) => {
  if (!loading) return null;
  return (
    <>
      <PageStyle />
      <div className="loading">
        <div className="loading__inner">
          <div className="loading__logo">
            <img alt="logo" src="../assets/images/logo-dark.svg" />
          </div>
          <h2 className="loading__title">Sit back and relax!</h2>
          <div className="loading__desc">
            <p>Please be patient we are redirecting it will take few moments!</p>
          </div>
          <div className="loading__animation">
            <img alt="loading" src="../assets/images/animations/loading/loading.svg" />
          </div>
        </div>
      </div>

    </>
  )
}

export default Loading;