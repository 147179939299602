import React, { useEffect, useState } from 'react'
import TopRightProfile from '../../TopRightProfile'
import { useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import Loading from '../../Loading';
import ShowCodeModal from '../Modals/ShowCodeModal';
import { ERC721 } from '../../../utils/contracts/contract';
import { APP_DATA, axiosClient, axiosClientPy } from '../../../utils';
import { toast } from 'react-toastify';
import VerifiedSuccessModal from '../Modals/VerifiedSuccessModal';

export default function CreateProjectERC721Step5({ projectData }) {
  const [loading, setLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(projectData?.project_status === "verified" ? true : false);

  const verify = async (e) => {
    if (!document.getElementById("VerifyButton").getAttribute("data-bs-target")) {
      setLoading(true);
      const headers = {
        Authorization: "Token " + window.sessionStorage.getItem("USER_TOKEN")
      }
      try {
        const mPayload1 = {
          project_id: projectData?.uuid
        }
        await axiosClientPy.post("project/verify-contract", mPayload1, { headers });
        setIsVerified(true);
        setLoading(false);
        document.getElementById("VerifyButton").setAttribute("data-bs-target", "#verifiedModal");
        document.getElementById("VerifyButton").setAttribute("data-bs-toggle", "modal");
        document.getElementById("VerifyButton").click();
      } catch (error) {
        setLoading(false);
        toast.error("Internal Server Error!!!");
        console.log(error);
      }
    }
  }

  return (
    <>
      <Loading loading={loading} />
      <section className="pe-0 main-content stepper-content">
        <div className="main-content-inner">
          <TopRightProfile />
          <div className="stepperForm">
            <div className="leftStepper">
              {/*----- Completion stats row -----*/}
              <div className="primary-box completion-row completion-row--back">
                <div className="completion-row__left">
                  <a href="/dashboard" className="redirect-back">
                    <img src="./assets/images/icons/back-arrow.svg" alt="Back arrow" />
                  </a>
                  <div >
                    <h1 className="fw600 text-white">ERC - 721</h1>
                    <p className="m-0 grayText">
                      Most basic token standard, used to create interchangeable
                      tokens.
                    </p>
                  </div>
                </div>
                <div className="completion-row__right">
                  <img className="#" src="./assets/images/wolf-logo-single.svg" alt="" />
                  <img className="#" src="./assets/images/nft-logo.svg" alt="" />
                  {
                    isVerified ?
                      <button className="btn rounded-0 btn--white-outlined" data-bs-target="#showCodeModal" data-bs-toggle="modal">
                        <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M18.3337 10C18.3337 10 15.8337 15 10.0003 15C4.16699 15 1.66699 10 1.66699 10C1.66699 10 4.16699 5 10.0003 5C15.8337 5 18.3337 10 18.3337 10Z" strokeLinecap="round" />
                          <circle cx={10} cy={10} r="2.5" strokeLinecap="round" />
                        </svg>
                        <span className="fw600">Show code</span>
                      </button>
                      :
                      <button onClick={verify} id="VerifyButton" className="btn rounded-0 btn--deploy">
                        <span className="fw400">Verify</span>
                      </button>
                  }
                </div>
              </div>
              <div className="profile-edit Erc-overview">
                <div className="box-steps box-steps--successfully">
                  <h5 className="text-center mb-0 text-capitalize fw700">
                    ERC -721 Smart contract deployed Successfully!
                  </h5>
                  <h5 className="text-center mb-0 text-capitalize fw500">
                    Deployed Address : <span className='fw700'>{projectData?.contract_address}</span>
                  </h5>
                  <a href={
                    `${projectData?.contract_network_details?.name === "Binance" ? "https://testnet.bscscan.com/"
                      : projectData?.contract_network_details?.name === "Ethereum" ? "https://goerli.etherscan.io/"
                        : "https://mumbai.polygonscan.com/"}address/${projectData?.contract_address}`
                  } target='__blank' className='btn rounded-0 primary-box btn--primary'>
                    View on {projectData?.contract_network_details?.name} BlockChain.
                  </a>
                  <p className="text-center grayText--lighter">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vehicula massa in enim luctus. Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Vehicula massa in enim luctus.
                    Rutrum arcu.
                  </p>
                  <div className="w-100">
                    <img src="./assets/images/erc-deployed.svg" alt="Deployed successfully illustration" />
                  </div>
                </div>
              </div>
            </div>
            {/* leftStepper */}
            <div className="rightStepper">
              <div className="stepper_Steps">
                <div className="stepper-head">
                  <a href="#" className="stepper-card-head">
                    <div className="d-flex justify-content-between title-row stepper_Links">
                      <button onclick=" location.href='./create-new-ERC721-step4.html' " className="stepper-redirect-link">
                        <img className="rounded mx-auto d-block" style={{ transform: 'rotate(180deg)' }} src="assets/images/icons/arrow-right.svg" alt="" />
                      </button>
                      <h2 className="fw700">Smart Contract Steps</h2>
                      <button onclick=" location.href='./create-new-ERC721-step5.html' " className="stepper-redirect-link">
                        <img className="rounded mx-auto d-block" src="assets/images/icons/arrow-right.svg" alt="" />
                      </button>
                    </div>
                  </a>
                </div>
                <hr className="mt-0" />
                <div className="stepper-counts">
                  <div className="outlined-card">
                    <a role="button" className="stepper-card stepper-card--pink">
                      <div className="title-row stepper_Links">
                        <div className="number">
                          <h2>1.</h2>
                        </div>
                        <h2 className="fw500">Token Name</h2>
                      </div>
                    </a>
                  </div>
                  <div className="outlined-card">
                    <a role="button" className="stepper-card stepper-card--orange">
                      <div className="title-row stepper_Links">
                        <div className="number">
                          <h2>2.</h2>
                        </div>
                        <h2 className="fw500">Token Symbol</h2>
                      </div>
                    </a>
                  </div>
                  <div className="outlined-card">
                    <a role="button" className="stepper-card stepper-card--yellow">
                      <div className="title-row stepper_Links">
                        <div className="number">
                          <h2>3.</h2>
                        </div>
                        <h2 className="fw500">Functions</h2>
                      </div>
                    </a>
                  </div>
                  <div className="outlined-card">
                    <a role="button" className="stepper-card stepper-card--blue">
                      <div className="title-row stepper_Links">
                        <div className="number">
                          <h2>4.</h2>
                        </div>
                        <h2 className="fw500">Overview</h2>
                      </div>
                    </a>
                  </div>
                  <div className="outlined-card">
                    <a role="button" className="stepper-card stepper-card--black">
                      <div className="title-row stepper_Links stepper_Links_white">
                        <div className="number">
                          <h2>5.</h2>
                        </div>
                        <h2 className="fw500">Deploy ERC-721</h2>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* rightStepper */}
          </div>
        </div>
      </section>

      <VerifiedSuccessModal name="ERC721" />
      <ShowCodeModal data={projectData} read={true} write={true} code={ERC721(projectData?.name, projectData?.token_symbol, projectData?.contract_functions_details?.map(ele => ele.name), "")} />
    </>
  )
}
