export const Marketplace721 = (data) => {
  return `// SPDX-License-Identifier: MIT
  pragma solidity ^0.8.4;
  import "@openzeppelin/contracts/token/ERC721/ERC721.sol";
  import "@openzeppelin/contracts/utils/Counters.sol";
  import "@openzeppelin/contracts/security/ReentrancyGuard.sol";
  
  contract ${data.name} is ReentrancyGuard{
      receive() external payable {}
      fallback() external payable {}
      address payable Owner;
      uint public listingPrice;
      using Counters for Counters.Counter;
      Counters.Counter public itemIds;
      Counters.Counter private _itemsSold;
      ${data.Auction ? `Counters.Counter public auctionIds;
      uint public auctionFee;` : ''}
      
      struct MarketItem {
        uint itemId;
        address nftaddress;
        uint256 tokenId;
        address payable seller;
        address payable buyer;
        uint256 totalprice;
        uint time;
        bool sold;
      }
      event MarketItemCreated (
        uint indexed itemId,
        address nftaddress,
        uint256 tokenId,
        address seller,
        address buyer,
        uint256 totalprice,
        uint time,
        bool sold
      );
      ${data.Auction ? `event AuctionItemCreated (
        uint indexed auctionId,
        address nftaddress,
        uint256 tokenId,
        address seller,
        uint256 staringbid,
        uint startedtime,
        uint endtime,
        uint hightestbid,
        //uint buynowAmount,
        address  hightestbidderAddress
      );
       struct AuctionItem {
        uint auctionId;
        address nftaddress;
        uint256 tokenId;
        address payable seller;
        uint256 staringbid;
        uint startedtime;
        uint endtime;
        uint hightestbid;
        //uint buynowAmount;
        address payable hightestbidderAddress;
      }`: ''}
  
      mapping(uint256 => MarketItem) public idToMarketItem;
      mapping(uint256 => AuctionItem) public idToAuctionItem;
      mapping (uint => uint[]) public history_Ap;
      mapping (uint => uint[]) public history_At;
      mapping (uint => uint[]) public history_Aamt;
      mapping (uint =>address[]) public history_Aa;
  
      constructor(uint Listingprice,uint AuctionFee){
        listingPrice=Listingprice;
        auctionFee = AuctionFee;
        Owner = payable(msg.sender);
      }
      ${data.FetchListedMyAuctionItem ? `function changeauctionFee(uint AuctionFee) public {
        require (msg.sender == Owner,"Not an owner");
        auctionFee=AuctionFee;
      }`: ''}
      ${data.ChangeListingPrice ? `function changeListingprice(uint Listingprice) public {
        require (msg.sender == Owner,"Not an owner");
        listingPrice=Listingprice;
      }` : ''}
      function getlistingprice()public view returns(uint){
        return listingPrice;
      }
      ${data.ChangeOwner ? `function changeOwner(address payable _owner) public {
        require(msg.sender == Owner);
        Owner = _owner;
      }` : ''}
      function listNFT(address NFTaddress, uint token_id, uint price) public payable nonReentrant {
        require(msg.value == listingPrice, "Price must be equal to listing price");
        itemIds.increment(); 
        uint256 itemId = itemIds.current();
       
        idToMarketItem[itemId] = MarketItem(
            itemId,
            NFTaddress,
            token_id,
            payable(msg.sender), 
            payable(address(0)), 
            price,
            block.timestamp,
            false
        );
        IERC721(NFTaddress).transferFrom(msg.sender, address(this), token_id);
        emit MarketItemCreated(
          itemId,
          NFTaddress,
          token_id,
          msg.sender,
          address(0),
          price,
          block.timestamp,
          false
        );
      }
      function buyMarketItem(address nftContract,uint256 itemId) public payable nonReentrant{
        uint price = idToMarketItem[itemId].totalprice;
        uint tokenId = idToMarketItem[itemId].tokenId;
        require(msg.value == price, "Please submit the asking price in order to complete purchase");
        idToMarketItem[itemId].seller.transfer(msg.value);
  
        IERC721(nftContract).transferFrom(address(this), msg.sender, tokenId);
        idToMarketItem[itemId].buyer = payable(msg.sender); 
        idToMarketItem[itemId].sold = true; 
        _itemsSold.increment(); 
        payable(Owner).transfer(listingPrice); 
      }
      function getMyAssets() public view returns (MarketItem[] memory){
        uint totalItemCount = itemIds.current();
        uint itemCount = 0;
        uint currentIndex = 0;
        for(uint i = 0; i < totalItemCount; i++){
            if(idToMarketItem[i+1].buyer == msg.sender){
                itemCount += 1; 
            }
        }
  
        MarketItem[] memory items = new MarketItem[](itemCount);
        for(uint i = 0; i < totalItemCount; i++){
            if(idToMarketItem[i+1].buyer == msg.sender){
                uint currentId = idToMarketItem[i+1].itemId;
                MarketItem storage currentItem = idToMarketItem[currentId];
                items[currentIndex] = currentItem;
                currentIndex += 1;
            }
        }
        return items;
      }
      function getmyListedAsssets() public view returns (MarketItem[] memory){
        uint totalItemCount = itemIds.current();
        uint itemCount = 0;
        uint currentIndex = 0;
  
        for(uint i = 0; i < totalItemCount; i++){
            if(idToMarketItem[i+1].seller == msg.sender){
                itemCount += 1; 
            }
        }
  
        MarketItem[] memory items = new MarketItem[](itemCount);
        for(uint i = 0; i < totalItemCount; i++){
            if(idToMarketItem[i+1].seller == msg.sender){
                uint currentId = idToMarketItem[i+1].itemId;
                MarketItem storage currentItem = idToMarketItem[currentId];
                items[currentIndex] = currentItem;
                currentIndex += 1;
            }
        }
        return items;
  
      }
      function getMarketlisted() public view returns (MarketItem[] memory){
        uint itemCount = itemIds.current(); 
        uint unsoldItemCount = itemIds.current() - _itemsSold.current();
        uint currentIndex = 0;
        MarketItem[] memory items =  new MarketItem[](unsoldItemCount);
        for(uint i = 0; i < itemCount; i++){
            if(idToMarketItem[i+1].buyer == address(0)){
                uint currentId = idToMarketItem[i + 1].itemId;
                MarketItem storage currentItem = idToMarketItem[currentId];
                items[currentIndex] = currentItem;
                currentIndex += 1;

            }
        }
        return items; 
      }
      ${data.CancelListing ? `function Canclelisting(uint id) public {
        require (msg.sender == idToMarketItem[id].seller);
        require(idToMarketItem[id].sold == false,"closed");
        idToMarketItem[id].sold = true;
        address nftContract = idToMarketItem[id].nftaddress;
        uint tokenId = idToMarketItem[id].tokenId;
        IERC721(nftContract).transferFrom(address(this), msg.sender, tokenId);
      }`: ''}
      ${data.ChangeListedItemPrice ? `function updatelistingPrice(uint id, uint price) public {
        require (msg.sender == idToMarketItem[id].seller);
        require(idToMarketItem[id].sold == false,"closed");
        idToMarketItem[id].totalprice = price;
      }`: ''}
  
      function AuctionNFT(address NFTaddress, uint token_id, uint price, uint endtime) public payable nonReentrant{
        require(msg.value == listingPrice, "Price must be equal to listing price");
        auctionIds.increment(); /* use when auto incremental selected*///remove the // comment from auto selected
        uint256 itemId = auctionIds.current();//remove the // comment from auto selected
        idToAuctionItem[itemId] = AuctionItem(
          itemId,
          NFTaddress,
          token_id,
          payable(msg.sender), 
          price,
          block.timestamp,
          endtime,
          0,
          payable(address(0))
        );
        IERC721(NFTaddress).transferFrom(msg.sender, address(this), token_id);
        emit AuctionItemCreated(
          itemId,
          NFTaddress,
          token_id,
          msg.sender,
          price,
          block.timestamp,
          endtime,
          0,
          address(0)
        );
      }
      ${data.Auction ? `function bid(uint newauctionIds, uint bidamount) payable public {
          require(idToAuctionItem[newauctionIds].endtime > block.timestamp, "Auction is end");
          require(idToAuctionItem[newauctionIds].hightestbid < bidamount,"the bid should be higher than the previeous bid");
          uint fees = auctionFee;
          require(msg.value == bidamount+ fees);
          payable(Owner).transfer(fees);
          history_Aa[newauctionIds].push(msg.sender);
          history_Ap[newauctionIds].push(bidamount);
          history_At[newauctionIds].push(block.timestamp);
          uint bidAddress = history_Aa[newauctionIds].length;
          if (bidAddress==1) {
            payable(address(this)).transfer(bidamount); 
            idToAuctionItem[newauctionIds].hightestbid = bidamount;
            idToAuctionItem[newauctionIds].hightestbidderAddress = payable (address (msg.sender));
          } else {
            payable(address(this)).transfer(bidamount);
            payable(idToAuctionItem[newauctionIds].hightestbidderAddress).transfer(idToAuctionItem[newauctionIds].hightestbid);
            idToAuctionItem[newauctionIds].hightestbid = bidamount;
            idToAuctionItem[newauctionIds].hightestbidderAddress = payable (address (msg.sender));
          }
      }
      function endAuction (uint newauctionIds) public {
          require(block.timestamp > idToAuctionItem[newauctionIds].endtime, "Auction not end");
          require (msg.sender == Owner,"the owner has a right");
          uint _tokenID = idToAuctionItem[newauctionIds].tokenId;
          address NFTaddress = idToAuctionItem[newauctionIds].nftaddress;
          //uint _numberOfUnit = max_supply[_tokenID];
          address _seller = idToAuctionItem[newauctionIds].seller;
          address bidder = idToAuctionItem[newauctionIds].hightestbidderAddress;
          uint bidAddress = history_Aa[newauctionIds].length;
  
          if (bidAddress==0) {
               IERC721(NFTaddress).transferFrom(bidder, address(this), _tokenID);
          } else {
            payable(_seller).transfer(idToAuctionItem[newauctionIds].hightestbid);
            IERC721(NFTaddress).transferFrom(bidder, address(this), _tokenID);
          }
      }`: ''}

      ${data.FetchAuctionItem ? `function fetchAuctionItem() public view returns (AuctionItem[] memory){
          uint AuctionItemCount = auctionIds.current();
          uint activeAuctionCount =0;
          uint current1 = 0;
          for (uint i=0; i<AuctionItemCount; i++){
              if(idToAuctionItem[i+1].endtime > block.timestamp){
                  activeAuctionCount +=1;
              }
          }
          AuctionItem[] memory items1 = new AuctionItem[](activeAuctionCount);
          for (uint i=0; i<AuctionItemCount; i++){
              if(idToAuctionItem[i+1].endtime > block.timestamp){
                  uint currentId1 = idToAuctionItem[i+1].auctionId;
                  AuctionItem storage currentItem = idToAuctionItem[currentId1];
                  items1[current1] = currentItem;
                  current1 +=1;
              }
              }
              return items1;
      }` : ''}
      ${data.FetchListedMyAuctionItem ? `function fetchmyListedAuction() public view returns(AuctionItem[] memory) {
        uint AuctionItemCount = auctionIds.current();
        uint activeAuctionCount =0;
        uint current1 = 0;
        for (uint i=0; i<AuctionItemCount; i++){
            if(idToAuctionItem[i+1].seller == msg.sender){
                activeAuctionCount +=1;
            }
        }
        AuctionItem[] memory items1 = new AuctionItem[](activeAuctionCount);
        for (uint i=0; i<AuctionItemCount; i++){
          if(idToAuctionItem[i+1].seller == msg.sender){
            uint currentId1 = idToAuctionItem[i+1].auctionId;
            AuctionItem storage currentItem = idToAuctionItem[currentId1];
            items1[current1] = currentItem;
            current1 +=1;
          }
        }
        return items1;
      }` : ''}
  }`;
}