import Cookies from 'js-cookie';
import React from 'react'
import TopRightProfile from '../../TopRightProfile';
import { axiosClientPy } from '../../../utils';
import { toast } from 'react-toastify';

export default function CreateProjectDeFiStep6({ projectData, setStep }) {
  const [AddSponsor, setAddSponsor] = React.useState(projectData?.add_sponsor ? projectData.add_sponsor === 'true' : false);
  console.log(AddSponsor)

  const onSubmit = async (e) => {
    e.preventDefault();
    const headers = {
      Authorization: "Token " + window.sessionStorage.getItem("USER_TOKEN")
    }
    try {
      const mData = new FormData();
      mData.append("project_id", projectData?.uuid);
      mData.append("add_sponsor", AddSponsor);
      mData.append("step_count", 8);
      const res = await axiosClientPy.patch("/project/project-creation", mData, { headers });
      setStep(7);
    } catch (error) {
      toast.error("Internal Server Error!!!")
    }
  }

  return (
    <section className="pe-0 main-content">
      <div className="pt-0 main-content-inner box-steps--setting-illustration">
        <TopRightProfile />
        {/*----- Completion stats row -----*/}
        <div className="primary-box completion-row completion-row--back">
          <div className="completion-row__left">
            <button onClick={() => setStep(5)} className="redirect-back">
              <img src="./assets/images/icons/back-arrow.svg" alt="Back arrow" />
            </button>
            <div >
              <h1 className="fw600 text-white">DeFi</h1>
              <p className="m-0 grayText">
                Merging technology to remove third parties and centralized
                institutions from financial transactions.
              </p>
            </div>
          </div>
          <div className="completion-row__right">
            <img className="logo-crypto" src="./assets/images/defi-logo.svg" alt="" />
          </div>
        </div>
        {/* ============================== */}
        <div className="box-steps">
          <h2 className="text-center mb-0 fst-italic fw700">
            Active Functions for your DeFi- Token
          </h2>
          <p className="text-center grayText--lighter">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vehicula
            massa in enim luctus. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Vehicula massa in enim luctus. Rutrum arcu.
          </p>
          <div className="w-100 form-box form-box--withCheckbox form-box--blue">
            <form onSubmit={onSubmit} className="form">
              <div className="top">
                <div className="fw600 form-group form-group--checkbox">
                  <input id="one" type="checkbox" checked={AddSponsor} onChange={() => setAddSponsor(prev => !prev)} />
                  <label htmlFor="one">
                    <b />
                    Add Sponsor
                  </label>
                </div>
                <p className="text-center grayText--lighter fz12 mx-auto">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vehicula massa in enim luctus. Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit. Vehicula massa in enim luctus.
                  Rutrum arcu.
                </p>
              </div>
              <div className="btnGroup">
                <button type="submit" className="btn rounded-0 primary-box btn--primary">
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}
