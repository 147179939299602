import React, { useEffect, useState } from 'react'
import LeftSidebar from '../components/LeftSidebar'
import TopRightProfile from "../components/TopRightProfile";
import useFetch from '../utils/hooks/useFetch';
import Loading from '../components/Loading';
import { getDateFormat } from '../utils';

export default function TransactionHistory() {
  const { data, loading } = useFetch("/main/transaction-history");
  const [amount, setAmount] = useState({
    inProgress: 0,
    deployed: 0
  })

  const myScript = `
  if (document.getElementById("polarChartTransactionHistory")) {
    let transactionHistoryChart = document.getElementById("polarChartTransactionHistory").getContext("2d");
    
    // Define the radial gradient
    let gradient1 = transactionHistoryChart.createRadialGradient(150, 150, 20, 150, 150, 150);
    gradient1.addColorStop(0, 'rgba(165, 255, 172, 0.25)');
    gradient1.addColorStop(1, '#E8F5E9');
  
    let gradient2 = transactionHistoryChart.createRadialGradient(150, 150, 20, 150, 150, 150);
    gradient2.addColorStop(0, 'rgba(226, 206, 255, 0.38)');
    gradient2.addColorStop(1, '#DFCAFF');
  
    let gradient3 = transactionHistoryChart.createRadialGradient(150, 150, 20, 150, 150, 150);
    gradient3.addColorStop(0, 'rgba(255, 220, 202, 0.22)');
    gradient3.addColorStop(1, '#FFE1D2');
  
    let gradient4 = transactionHistoryChart.createRadialGradient(150, 150, 20, 150, 150, 150);
    gradient4.addColorStop(0, 'rgba(206, 206, 206, 0.24)');
    gradient4.addColorStop(1, '#CECECE');
  
    let data = {
      labels: [
        "Inprogress",
        "Transferred",
        "Refunded",
        "Declined",
      ],
      datasets: [
        {
          label: "",
          data: [
            ${data?.filter(ele => !ele.is_captured).map(item => item.amount).length > 0 ? data?.filter(ele => !ele.is_captured).map(item => item.amount).reduce((prev, next) => prev + next) : 0}, 
            ${data?.filter(ele => ele.is_captured).map(item => item.amount).length > 0 ? data?.filter(ele => ele.is_captured).map(item => item.amount).reduce((prev, next) => prev + next) : 0}, 0, 0],
          backgroundColor: [gradient1, gradient2, gradient3, gradient4],
        },
      ],
    };
  
    let maxVal1 = 0;
    for (var i = 0; i < data.datasets[0].data.length; i++) {
      if (data.datasets[0].data[i] > maxVal1) {
        maxVal1 = data.datasets[0].data[i];
      }
    }
  
    var myChart = new Chart(transactionHistoryChart, {
      plugins: [ChartDataLabels],
      type: "polarArea",
      data: data,
      options: {
        borderWidth: 0,
        responsive: true,
        scales: {
          r: {
            min: -maxVal1*1.5,
            max: maxVal1,
            beginAtZero: true,
            ticks: {
              display: false, // Remove vertical numbers
            },
            grid: {
              display: false, // Removes the circulair lines
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
          datalabels: {
            formatter: (value, polarData1Ctx) => {
              let sum = 0;
              let dataArr = polarData1Ctx.chart.data.datasets[0].data;
              dataArr.map((data) => {
                sum += data;
              });
              let percentage =  "$" + value.toFixed(0);
              return percentage;
            },
            color: "#0D121C",
            font: {
              weight: "500",
              size: 12,
            },
            anchor: "end",
            align: "start",
          },
        },
      },
    });
  }

  if (document.getElementById("canvasBarAmtDate")) {
    var ctx_bar = document.getElementById("canvasBarAmtDate").getContext("2d");
  
    var barChartData = {
      labels: " ",
      datasets: [
        {
          label: "",
          barPercentage: 1, // Adjust the width of the bars
          categoryPercentage: 0.5, // Adjust the space between the bars
          barThickness: "flex",
          backgroundColor: "#D0B3FF",
          borderColor: "#D0B3FF",
          borderWidth: 1,
          data: [0, 0, 0, 0, ${data?.filter(ele => ele.is_captured).map(item => item.amount).length > 0 ? data?.filter(ele => ele.is_captured).map(item => item.amount).reduce((prev, next) => prev + next) : 0}],
        },
        {
          label: "",
          barPercentage: 1,
          categoryPercentage: 0.5,
          barThickness: "flex",
          backgroundColor: "#86D78B",
          borderColor: "#86D78B",
          borderWidth: 1,
          data: [0, 0, 0, 0,
            ${data?.filter(ele => !ele.is_captured).map(item => item.amount).length > 0 ? data?.filter(ele => !ele.is_captured).map(item => item.amount).reduce((prev, next) => prev + next) : 0}],
        },
      ],
    };
  
    var chartOptions = {
      responsive: false,
      maintainAspectRatio: false,
      indexAxis: "y",
      title: {
        display: false,
      },
      scales: {
        labels: [2000, 3000, 4000, 5000, 6000, 7000],
        x: {
          grid: {
            color: "#f7f8fe",
          },
          ticks: {
            color: "#8F92A1",
          }, 
        },
  
        y: {
          labels: [2027, 2026, 2025, 2024, 2023],
          grid: {
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            minRotation: 90,
            padding: 15,
            color: "#8F92A1",
          }, 
        },
      },  
      plugins: {
        legend: {
          display: false,
        },
      },
    };
  
    var myBar = new Chart(ctx_bar, {
      type: "bar",
      data: barChartData,
      options: chartOptions,
    });
  }
  `;

  useEffect(() => {
    if (data) {
      const script = document.createElement("script");
      script.innerHTML = myScript;
      script.id = "transactionHistoryCharts";
      document.body.append(script);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      let a = data.filter(ele => !ele.is_captured).map(item => item.amount);
      let b = data.filter(ele => ele.is_captured).map(item => item.amount);
      let inP = 0;
      let dep = 0;
      if (a.length > 0) {
        inP = a.reduce((prev, next) => prev + next);
      }
      if (b.length > 0) {
        dep = b.reduce((prev, next) => prev + next);
      }
      setAmount({ inProgress: inP, deployed: dep })
    }
  }, [data])

  return (
    <>
      <Loading loading={loading} />
      <div className="mob-menu-btn"><span /><span /><span /></div>
      <section className='page-wrapper'>
        <LeftSidebar active={"report"} />

        <section className="pe-0 main-content">
          <div className="py-0 main-content-inner">
            <TopRightProfile />
            {/*----- Completion stats row -----*/}
            <div className="primary-box completion-row">
              <div className="completion-row__left">
                <a href="/reports" className="redirect-back">
                  <img src="../assets/images/icons/back-arrow.svg" alt="Back arrow" />
                </a>
                <div >
                  <h1 className="fw600 text-white">Transaction History</h1>
                  <div className="mb-0">
                    <span className="fw700">Last Updated: </span>
                    <span className="ms-1"> 01-June-2022 - 03hr:55min (EST)</span>
                  </div>
                </div>
              </div>
              <div className="completion-row__right">
                <a href="#" className="btn rounded-0 btn--deploy">
                  <span className="fw400">Download PDF</span>
                </a>
              </div>
            </div>
            {/* ============================== */}
            <div className="transactions">
              <div className="transactions__left">
                <div className="bg-white transactionStats">
                  <div className="top-row">
                    <div className="title-row">
                      <h2 className="mb-0 lh1-5 fw500">Transaction Stats</h2>
                    </div>
                    <div className="top-row__options">
                      <div className="dropdown">
                        <button className="btn border-0 dropdown-toggle btn-dd btn-dd--primary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          Year
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <a className="dropdown-item" href="#">Day</a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">Week</a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">Year</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="main-chart-content">
                    <div className="w-100 h-100">
                      <div className="h-100 row gx-2">
                        <div className="col-8">
                          <div className="chart">
                            <div className="polarChartInner">
                              <canvas id="polarChartTransactionHistory" />
                              <img className="transaction-chart-center" src="../assets/images/transaction-chart-center.svg" />
                              <span className="polarChartTH__percent polarChartTH__inprogress">
                                {parseInt(amount.inProgress * 100 / (amount.deployed + amount.inProgress))}%
                              </span>
                              <span className="polarChartTH__percent polarChartTH__transferred">
                                {parseInt(amount.deployed * 100 / (amount.deployed + amount.inProgress))}%
                              </span>
                              <span className="polarChartTH__percent polarChartTH__refunded">0%
                              </span>
                              <span className="polarChartTH__percent polarChartTH__declined">0%
                              </span>
                              <span className="polarChartTH__inprogress__line1" /><span className="polarChartTH__inprogress__line2" />
                              <span className="polarChartTH__transferred__line1" /><span className="polarChartTH__transferred__line2" />
                              <span className="polarChartTH__refunded__line1" /><span className="polarChartTH__refunded__line2" />
                              <span className="polarChartTH__declined__line1" /><span className="polarChartTH__declined__line2" />
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="h-100 d-flex primary-box">
                            <ul className="list-unstyled m-0 text-white legends">
                              <li>
                                <div className="pair">
                                  <div className="pair__color pair__color--inprogress" />
                                  <div className="pair__text fz12">
                                    Inprogress - <br />
                                    <b className="text-white fz14">${amount.inProgress}</b>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="pair">
                                  <div className="pair__color pair__color--transferred" />
                                  <div className="pair__text fz12">
                                    Transferred - <br />
                                    <b className="text-white fz14">${amount.deployed}</b>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="pair">
                                  <div className="pair__color pair__color--refunded" />
                                  <div className="pair__text fz12">
                                    Refunded - <br />
                                    <b className="text-white fz14">$0</b>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="pair">
                                  <div className="pair__color pair__color--declined" />
                                  <div className="pair__text fz12">
                                    Declined - <br />
                                    <b className="text-white fz14">$0</b>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white sale-activities__left">
                  <div className="top-row">
                    <div className="title-row">
                      <h2 className="mb-0 lh1-5 fw500">Amount vs Date</h2>
                    </div>
                  </div>
                  <div className="bar-container">
                    <canvas id="canvasBarAmtDate" style={{ minHeight: '325px', height: '325px', width: '100%' }} />
                  </div>
                  <ul className="list-inline mb-0 legends">
                    <li>
                      <div className="pair">
                        <div className="pair__color pair__color--inprogress" />
                        <div className="fz12 pair__text">
                          Inprogress -
                          <b className="fw700 fz14">${amount.inProgress}</b>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="pair">
                        <div className="pair__color pair__color--transferred" />
                        <div className="pair__text">
                          Transferred -
                          <b className="fw700 fz14">${amount.deployed}</b>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              {/* ================================================= */}
              {/*-------- Transaction History widget --------*/}
              <div className="w-100 bg-white project-table-list mt-0 transactions__right">
                <div className="top-row">
                  <div className="title-row">
                    <h2 className="mb-0 lh1-5 fw500">Transaction History</h2>
                    <div className="mb-0">
                      <span>Total Transactions - </span>
                      <span className="ms-1 fw700 grayText">{data?.length}</span>
                    </div>
                  </div>
                  <div className="top-row__options">
                    <div className="dropdown">
                      <button className="btn border-0 dropdown-toggle btn-dd btn-dd--primary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Year
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="#">Day</a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">Week</a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">Year</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* DataTable */}
                <div className="heightResolve">
                  <div className="table-responsive chainery-table-structure">
                    <table id="example" className="w-100 mb-0 table">
                      <thead>
                        <tr>
                          <th className="fw600 text-start">
                            <span>Reference ID</span>
                          </th>
                          <th className="fw600 text-start">
                            <span>Status</span>
                          </th>
                          <th className="fw600">
                            <span> Generated</span>
                          </th>
                          <th className="fw600">
                            <span>Amount</span>
                          </th>
                          <th className="text-start fw600">
                            <span>Type</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          data?.map((ele, index) => (
                            <tr key={index}>
                              <td>
                                <h5 className="mb-0 grayText--lighter fz10 fw700">
                                  #{index + 1}
                                </h5>
                              </td>
                              <td>
                                {
                                  ele?.is_captured ? <div className="status status--transferred">Transferred</div>
                                    : <div className="status status--inprogress">Inprogress</div>
                                }
                              </td>
                              <td>
                                <div className="when-created">
                                  <div className="grayText">
                                    <img src="../assets/images/icons/calender-neutral.svg" alt="" />
                                    <span >{getDateFormat(ele.created_at)[0]}</span>
                                  </div>
                                  <div>
                                    <img src="../assets/images/icons/clock.svg" alt="" />
                                    <span >{getDateFormat(ele.created_at)[1]}</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <h5 className="mb-0 fz10 fw700">${ele.amount}</h5>
                              </td>
                              <td>
                                <div className="type type--service">Service</div>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* =================================== */}
            </div>
          </div>
        </section>
      </section>
    </>
  )
}
