import React from 'react'
import TopRightProfile from '../../TopRightProfile'
import { axiosClientPy } from '../../../utils'

function CreateProjectServiceType({ projectData }) {
  const goToNext = async (data) => {
    const headers = {
      Authorization: "Token " + window.sessionStorage.getItem("USER_TOKEN")
    }
    const mData = {
      project_id: projectData?.uuid,
      name: data
    }
    const res = await axiosClientPy.patch("/project/project-creation", mData, { headers });
    window.location.reload();
  }
  return (
    <>
      <section className="pe-0 main-content">
        <div className="pt-0 main-content-inner">
          <TopRightProfile />
          {/*----- Completion stats row -----*/}
          <div className="primary-box completion-row completion-row--back">
            <div className="completion-row__left">
              <a href="#" className="redirect-back">
                <img src="../assets/images/icons/back-arrow.svg" alt="Back arrow" />
              </a>
              <div >
                <h1 className="fw600 text-white">Project By Services</h1>
                <p className="m-0 grayText">
                  Check services you want to avail and proceed for requirments.
                </p>
                <div className="mb-0">
                  <span className="fw700">Last Updated: </span>
                  <span className="ms-1"> 01-June-2022 - 03hr:55min (EST)</span>
                </div>
              </div>
            </div>
          </div>
          {/* ============================== */}
          <div className="servicesList">
            <div className="servicesList__left">
              <ul className="list-inline servicesList-cards">
                <li>
                  <div onClick={() => goToNext("Tokenomics")} className="cursor-pointer d-flex position-relative check-card">
                    <div className="card-wrapper card-box--lightblue">
                      <div className="top-row-card">
                        <div className="icon-cover">
                          <img src="../assets/images/Tokenomics-icon-small.svg" alt="" />
                        </div>
                        <h3 className="primaryText mb-0 fz18 fw700 lh1">
                          Tokenomics
                        </h3>
                      </div>
                      <p className="grayText">
                        A well-built platform often translates into higher
                        demand over time as new investors flock to the project,
                        which, in turn, boosts prices.
                      </p>
                      <ul className="list-inline lh-1 features">
                        <li>
                          <div className="pair">
                            <div className="imgCover primary-box">
                              <img src="./../assets/images/icons/Distribution-icon.svg" alt="Distribution icon" />
                            </div>
                            <span className="primaryText">Distribution</span>
                          </div>
                        </li>
                        <li>
                          <div className="pair">
                            <div className="imgCover primary-box">
                              <img src="./../assets/images/icons/Demand-icon.svg" alt="Demand icon" />
                            </div>
                            <span className="primaryText">Demand</span>
                          </div>
                        </li>
                        <li>
                          <div className="pair">
                            <div className="imgCover primary-box">
                              <img src="./../assets/images/icons/Scarcity-icon.svg" alt="Scarcity icon" />
                            </div>
                            <span className="primaryText">Scarcity</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li>
                  <div onClick={() => goToNext("ICO")} className="cursor-pointer d-flex position-relative check-card">
                    <div className="card-wrapper card-box--purple">
                      <div className="top-row-card">
                        <div className="icon-cover">
                          <img src="../assets/images/ICO-icon-small.svg" alt="" />
                        </div>
                        <h3 className="primaryText mb-0 fz18 fw700 lh1">ICO</h3>
                      </div>
                      <p className="grayText">
                        An initial coin offering to raise capital for your
                        project by selling blockchain-based digital assets.
                      </p>
                      <ul className="list-inline lh-1 features">
                        <li>
                          <div className="pair">
                            <div className="imgCover primary-box">
                              <img src="./../assets/images/icons/Accuracy-icon.svg" alt="Accuracy icon" />
                            </div>
                            <span className="primaryText">Accuracy</span>
                          </div>
                        </li>
                        <li>
                          <div className="pair">
                            <div className="imgCover primary-box">
                              <img src="./../assets/images/icons/Transparency-icon.svg" alt="Transparency icon" />
                            </div>
                            <span className="primaryText">Transparency</span>
                          </div>
                        </li>
                        <li>
                          <div className="pair">
                            <div className="imgCover primary-box">
                              <img src="./../assets/images/icons/Accountability-icon.svg" alt="Accountability icon" />
                            </div>
                            <span className="primaryText">Accountability</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li>
                  <div onClick={() => goToNext("ListOnDEX")} className="cursor-pointer d-flex position-relative check-card">
                    <div className="card-wrapper card-box--red">
                      <div className="top-row-card">
                        <div className="icon-cover">
                          <img src="../assets/images/DEX-icon-small.svg" alt="" />
                        </div>
                        <h3 className="primaryText mb-0 fz18 fw700 lh1">
                          List on DEX
                        </h3>
                      </div>
                      <p className="grayText">
                        A peer-to-peer marketplace where transactions occur
                        directly between crypto traders.
                      </p>
                      <ul className="list-inline lh-1 features">
                        <li>
                          <div className="pair">
                            <div className="imgCover primary-box">
                              <img src="./../assets/images/icons/Decentralization-icon.svg" alt="Decentralization icon" />
                            </div>
                            <span className="primaryText">Decentralization</span>
                          </div>
                        </li>
                        <li>
                          <div className="pair">
                            <div className="imgCover primary-box">
                              <img src="./../assets/images/icons/Security-icon.svg" alt="Security icon" />
                            </div>
                            <span className="primaryText">Security</span>
                          </div>
                        </li>
                        <li>
                          <div className="pair">
                            <div className="imgCover primary-box">
                              <img src="./../assets/images/icons/Peer-to-peer-icon.svg" alt="Peer-to-peer icon" />
                            </div>
                            <span className="primaryText">Peer-to-peer</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li>
                  <div onClick={() => goToNext("ListOnExchange")} className="cursor-pointer d-flex position-relative check-card">
                    <div className="card-wrapper card-box--seagreen">
                      <div className="top-row-card">
                        <div className="icon-cover">
                          <img src="../assets/images/Exchange-icon-small.svg" alt="" />
                        </div>
                        <h3 className="primaryText mb-0 fz18 fw700 lh1">
                          List on Exchange
                        </h3>
                      </div>
                      <p className="grayText">
                        A reliable, orderly, liquid and efficient digitized
                        market place where investors can buy and sell listed
                        companies' common stocks and other.
                      </p>
                      <ul className="list-inline lh-1 features">
                        <li>
                          <div className="pair">
                            <div className="imgCover primary-box">
                              <img src="./../assets/images/icons/Efficient-icon.svg" alt="Efficient icon" />
                            </div>
                            <span className="primaryText">Efficient</span>
                          </div>
                        </li>
                        <li>
                          <div className="pair">
                            <div className="imgCover primary-box">
                              <img src="./../assets/images/icons/Supervisory-icon.svg" alt="Supervisory icon" />
                            </div>
                            <span className="primaryText">Supervisory</span>
                          </div>
                        </li>
                        <li>
                          <div className="pair">
                            <div className="imgCover primary-box">
                              <img src="./../assets/images/icons/Intermediary-icon.svg" alt="Intermediary icon" />
                            </div>
                            <span className="primaryText">Intermediary</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li>
                  <div onClick={() => goToNext("CompetitorAnalysis")} className="cursor-pointer d-flex position-relative check-card">
                    <div className="card-wrapper card-box--blue">
                      <div className="top-row-card">
                        <div className="icon-cover">
                          <img src="../assets/images/analysis-icon-small.svg" alt="" />
                        </div>
                        <h3 className="primaryText mb-0 fz18 fw700 lh1">
                          Competitor Analysis
                        </h3>
                      </div>
                      <p className="grayText">
                        Process of identifying competitors in your industry and
                        researching their different marketing strategies.
                      </p>
                      <ul className="list-inline lh-1 features">
                        <li>
                          <div className="pair">
                            <div className="imgCover primary-box">
                              <img src="./../assets/images/icons/Ease-of-use-icon.svg" alt="Ease of use icon" />
                            </div>
                            <span className="primaryText">Ease of use</span>
                          </div>
                        </li>
                        <li>
                          <div className="pair">
                            <div className="imgCover primary-box">
                              <img src="./../assets/images/icons/Cost-efficient-icon.svg" alt="Cost efficient icon" />
                            </div>
                            <span className="primaryText">Cost efficient</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li>
                  <div onClick={() => goToNext("TaskManagement")} className="cursor-pointer d-flex position-relative check-card">
                    <div className="card-wrapper card-box--purpledark">
                      <div className="top-row-card">
                        <div className="icon-cover">
                          <img src="../assets/images/task-icon-small.svg" alt="" />
                        </div>
                        <h3 className="primaryText mb-0 fz18 fw700 lh1">
                          Task Management
                        </h3>
                      </div>
                      <p className="grayText">
                        Cut down on the 'work of work' by prioritizing the right
                        tasks at the right time.
                      </p>
                      <ul className="list-inline lh-1 features">
                        <li>
                          <div className="pair">
                            <div className="imgCover primary-box">
                              <img src="./../assets/images/icons/Efficient-workflow-icon.svg" alt="Efficient workflow icon" />
                            </div>
                            <span className="primaryText">Efficient workflow</span>
                          </div>
                        </li>
                        <li>
                          <div className="pair">
                            <div className="imgCover primary-box">
                              <img src="./../assets/images/icons/Focused-icon.svg" alt="Focused icon" />
                            </div>
                            <span className="primaryText">Focused</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li>
                  <div onClick={() => goToNext("SocialMediaManagement")} className="cursor-pointer d-flex position-relative check-card">
                    <div className="card-wrapper card-box--pinkdark">
                      <div className="top-row-card">
                        <div className="icon-cover">
                          <img src="../assets/images/social-icon-small.svg" alt="" />
                        </div>
                        <h3 className="primaryText mb-0 fz18 fw700 lh1">
                          Social Media <br />Management
                        </h3>
                      </div>
                      <p className="grayText">
                        Ongoing process of creating and scheduling content
                        designed to grow and nurture an audience across social
                        media platforms.
                      </p>
                      <ul className="list-inline lh-1 features">
                        <li>
                          <div className="pair">
                            <div className="imgCover primary-box">
                              <img src="./../assets/images/icons/Creativity-icon.svg" alt="Creativity icon" />
                            </div>
                            <span className="primaryText">Creativity</span>
                          </div>
                        </li>
                        <li>
                          <div className="pair">
                            <div className="imgCover primary-box">
                              <img src="./../assets/images/icons/Adaptability-icon.svg" alt="Adaptability icon" />
                            </div>
                            <span className="primaryText">Adaptability</span>
                          </div>
                        </li>
                        <li>
                          <div className="pair">
                            <div className="imgCover primary-box">
                              <img src="./../assets/images/icons/Target-Audience-icon.svg" alt="Target Audience icon" />
                            </div>
                            <span className="primaryText">Target Audience</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li>
                  <div onClick={() => goToNext("CommunityEngagement")} className="cursor-pointer d-flex position-relative check-card">
                    <div className="card-wrapper card-box--gray">
                      <div className="top-row-card">
                        <div className="icon-cover">
                          <img src="../assets/images/community-icon-small.svg" alt="" />
                        </div>
                        <h3 className="primaryText mb-0 fz18 fw700 lh1">
                          Community <br />Engagement
                        </h3>
                      </div>
                      <p className="grayText">
                        A strategic process with the specific purpose of working
                        with identified groups of people.
                      </p>
                      <ul className="list-inline lh-1 features">
                        <li>
                          <div className="pair">
                            <div className="imgCover primary-box">
                              <img src="./../assets/images/icons/Diversity-icon.svg" alt="Diversity icon" />
                            </div>
                            <span className="primaryText">Diversity</span>
                          </div>
                        </li>
                        <li>
                          <div className="pair">
                            <div className="imgCover primary-box">
                              <img src="./../assets/images/icons/Sustainability-icon.svg" alt="Sustainability icon" />
                            </div>
                            <span className="primaryText">Sustainability</span>
                          </div>
                        </li>
                        <li>
                          <div className="pair">
                            <div className="imgCover primary-box">
                              <img src="./../assets/images/icons/Impact-to-action-icon.svg" alt="Impact to action icon" />
                            </div>
                            <span className="primaryText">Impact to action</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="servicesList__right">
              <h3 className="fw600">Multiple services</h3>
              <p className="grayText--lighter">
                One Platform to avail all your services in one click that easily
                accessible for you all the way in hyping and growing your
                investment life!
              </p>
              <img src="../assets/images/animations/services-list/grow-project.svg" alt="" />
              {/* <div className="proceed-row">
                <div className="mb-0 grayText--lighter fz12 text-end">
                  Check services you want to avail and proceed for requirments.
                </div>
                <a href="./latest-services-list-details.html" role="button" className="primary-box rounded-0 btn fz16 btn--primary">
                  Proceed
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CreateProjectServiceType