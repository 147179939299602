import React, { useState } from 'react'
import LeftSidebar from '../components/LeftSidebar'
import LiveSupportStep1 from '../components/LiveSupport/LiveSupportStep1'
import LiveSupportStep2 from '../components/LiveSupport/LiveSupportStep2';
import LiveSupportStep3 from '../components/LiveSupport/LiveSupportStep3';
import RightSidebar from '../components/RightSidebar';
import { useSearchParams } from 'react-router-dom';

function LiveSupport() {
  const [searchParams] = useSearchParams();
  const ticketId = searchParams.get("ticketId");
  const [topic, setTopic] = useState("");
  const [step, setStep] = useState(ticketId ? 3 : 1);

  return (
    <>
      <section>
        <div className="mob-menu-btn"> <span /> <span /> <span /> </div>
        <main className="page-wrapper">
          <LeftSidebar active="LiveSupport" />

          {step === 1 && <LiveSupportStep1 topic={topic} setTopic={setTopic} setStep={setStep} />}
          {step === 2 && <LiveSupportStep2 topic={topic} setTopic={setTopic} setStep={setStep} />}
          {step === 3 && <LiveSupportStep3 setStep={setStep} />}

          <RightSidebar location="LiveSupport" />
        </main>
      </section>
    </>
  )
}

export default LiveSupport