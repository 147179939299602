export const ERC721 = (name, symbol, input, myAddress) => {
  return `// SPDX-License-Identifier: MIT
pragma solidity ^0.8.9;

import "@openzeppelin/contracts-upgradeable/token/ERC721/ERC721Upgradeable.sol";
import "@openzeppelin/contracts-upgradeable/token/ERC721/extensions/ERC721URIStorageUpgradeable.sol";
import "@openzeppelin/contracts-upgradeable/proxy/utils/Initializable.sol";
${input.AutoIncrement ? 'import "@openzeppelin/contracts-upgradeable/utils/CountersUpgradeable.sol";' : ''}
${input.Burnable ? 'import "@openzeppelin/contracts-upgradeable/token/ERC721/extensions/ERC721BurnableUpgradeable.sol";' : ''}
${input.Pausable ? 'import "@openzeppelin/contracts-upgradeable/security/PausableUpgradeable.sol";' : ''}
${input.Enumerable ? 'import "@openzeppelin/contracts-upgradeable/token/ERC721/extensions/ERC721EnumerableUpgradeable.sol";' : ''}

contract ${name} is Initializable, ERC721Upgradeable, ERC721URIStorageUpgradeable ${input.Burnable ? ',ERC721BurnableUpgradeable' : ''} ${input.Pausable ? ', PausableUpgradeable' : ''}${input.Enumerable ? ',ERC721EnumerableUpgradeable' : ''}{
  uint private set; 
  address public Owner;
    ${input.AutoIncrement ? `using CountersUpgradeable for CountersUpgradeable.Counter; 
  CountersUpgradeable.Counter private _tokenIdCounter;` : ''}

  ${input.upgradeable ? '' : `constructor() {
    initialize("${name}", "${symbol}", ${myAddress});
  }`}
  
  function initialize(string memory name, string memory symbol, address _address) initializer public {
    require (set ==0,"Already done");
    __ERC721_init(name, symbol);
    __ERC721URIStorage_init();
    set =1;
    Owner = _address;
  }

  function safeMint(address to, uint256 tokenId, string memory uri) public
  {
    require(msg.sender == Owner,"Not an Owner");
    ${input.AutoIncrement ? `tokenId = _tokenIdCounter.current();   
    _tokenIdCounter.increment();`: ''}
    _safeMint(to, tokenId);
    _setTokenURI(tokenId, uri);
  }

  function _burn(uint256 tokenId) internal override(ERC721Upgradeable, ERC721URIStorageUpgradeable)
  {
    super._burn(tokenId);
  }

  function tokenURI(uint256 tokenId)
    public
    view
    override(ERC721Upgradeable, ERC721URIStorageUpgradeable)
    returns (string memory)
  {
    return super.tokenURI(tokenId);
  }
    
  ${input.Pausable ? `function pause() public {
    require (msg.sender == Owner);
    _pause();
  } 

  function unpause() public  {
    require (msg.sender == Owner);
    _unpause();
  }
    
  function _beforeTokenTransfer(address from, address to, uint256 tokenId, uint256 batchSize)
    internal
    whenNotPaused
    ${input.Enumerable ? 'override(ERC721Upgradeable, ERC721EnumerableUpgradeable)' : ''}
  {
    super._beforeTokenTransfer(from, to, tokenId, batchSize);
  }` : ''}

  ${input.Enumerable ? `function supportsInterface(bytes4 interfaceId)
    public
    view
    override(ERC721Upgradeable, ERC721EnumerableUpgradeable)
    returns (bool)
  {
    return super.supportsInterface(interfaceId);
  }` : ''}
  
  ${input.ChangeURL ? `function changeURL(uint256 tokenId, string memory uri) public {
    require (ownerOf(tokenId)== msg.sender);
    _setTokenURI(tokenId, uri);
  }` : ''}
}`;
}

export const ERC20 = (name, symbol, primint, checked, myAddress) => {
  return `// SPDX-License-Identifier: MIT 
pragma solidity ^0.8.9; 

import "@openzeppelin/contracts-upgradeable/token/ERC20/ERC20Upgradeable.sol"; 
import "@openzeppelin/contracts-upgradeable/proxy/utils/Initializable.sol"; 
${checked.Burnable ? 'import "@openzeppelin/contracts-upgradeable/token/ERC20/extensions/ERC20BurnableUpgradeable.sol";' : ''}

contract ${name} is Initializable, ERC20Upgradeable ${checked.Burnable ? ',ERC20BurnableUpgradeable' : ''} { 
  uint private set;  
  bool private pause;
  address public owner;
  ${checked.MaxCap ? 'uint public maxcap;' : ''}

  ${checked.Upgradeable ? '' : `constructor() {
    initialize("${name}", "${symbol}", ${primint}${checked.MaxCap ? `, ${primint}` : ''}, ${myAddress});
  }`}

  function initialize(string memory name, string memory symbol, uint primint${checked.MaxCap ? ', uint _maxcap' : ''}, address _address) initializer public { 
    require (set ==0,"already done");
    __ERC20_init(name, symbol);
    owner = _address;
    _mint(_address, primint * 10 ** decimals()); 
    ${checked.MaxCap ? 'maxcap = _maxcap * 10 ** decimals();' : ''}
    pause = false;
    set = 1; 
  }

  ${checked.Mintable ? `function mint(address to, uint256 amount) public { 
    require( msg.sender == owner,"not an owner");
    ${checked.Pauseable ? 'require(pause == false,"token mint is paused");' : ''}
    ${checked.MaxCap ? 'uint supply = totalSupply();' : ''}
    ${checked.MaxCap ? 'require( supply < maxcap,"not an owner");' : ''}
    _mint(to, amount * 10 ** decimals());
  }` : ''}

  ${checked.Pauseable ? `function Pause() public  {
    require( msg.sender == owner,"not an owner");
    pause = false;
  }

  function UNPause() public  {
    require( msg.sender == owner,"not an owner");
    pause = true;  
  }`: ''}
}`;
}

export const ERC1155 = (name, symbol, checked, myAddress) => {
  return `// SPDX-License-Identifier: MIT
pragma solidity ^0.8.9;

import "@openzeppelin/contracts-upgradeable/token/ERC1155/ERC1155Upgradeable.sol";
import "@openzeppelin/contracts-upgradeable/proxy/utils/Initializable.sol";
import "@openzeppelin/contracts-upgradeable/token/ERC1155/extensions/ERC1155SupplyUpgradeable.sol";
import "@openzeppelin/contracts-upgradeable/utils/CountersUpgradeable.sol";
import "@openzeppelin/contracts-upgradeable/token/ERC1155/extensions/ERC1155BurnableUpgradeable.sol";

contract ${name} is Initializable, ERC1155Upgradeable,ERC1155SupplyUpgradeable,ERC1155BurnableUpgradeable {
  uint private set; 
  bool private pause; 
  address public Owner;
  string public symbol;
  string public name;

  ${checked.AutoIncrement ? `using CountersUpgradeable for CountersUpgradeable.Counter;
  CountersUpgradeable.Counter private _tokenIdCounter;` : ''}
  mapping(uint => string) public tokenURI;

  ${checked.Upgradeable ? '' : `constructor() {
    initialize("${name}", "${symbol}", ${myAddress});
  }`}
  
  function initialize(string memory _name,string memory _symbol, address _address) initializer public {
    require(set ==0,"Already done");
    __ERC1155_init("");
    __ERC1155Supply_init();
    set =1;
    Owner = _address;
    pause = false;
    name = _name;
    symbol = _symbol;
  }
  
  function _setURI(uint _id, string memory _uri) internal {
    tokenURI[_id] = _uri;
    emit URI(_uri, _id);
  }
  
  function uri(uint _id) public override view returns (string memory) {
    return tokenURI[_id];
  }
  
  function safemint(address _to,uint _amount,string memory _uri, uint tokenId) public {//remove tokenid when auto selected and add tokenID when not auto selected.
    require(msg.sender == Owner,"Not an Owner");//for not owner selected.
    ${checked.Pauseable ? 'require(pause == false,"token mint is paused");' : ''}
    ${checked.AutoIncrement ? `uint tokenId = _tokenIdCounter.current(); 
    _tokenIdCounter.increment();`: ''}
    _mint(_to, tokenId, _amount, "");
    _setURI(tokenId,_uri);
  }
  
  function _beforeTokenTransfer(address operator, address from, address to, uint256[] memory ids, uint256[] memory amounts, bytes memory data)
    internal
    override(ERC1155Upgradeable, ERC1155SupplyUpgradeable)
  {
    super._beforeTokenTransfer(operator, from, to, ids, amounts, data);
  }
  
  ${checked.Pausable ? `function Pause() public  {
    require( msg.sender == Owner,"not an owner");
    pause = false;  
  } 

  function UNPause() public  {// when paused is selected.
    require( msg.sender == Owner,"not an owner");
    pause = true;  
  }` : ''}
}`;
}

export const DEFI = (input, myAddress) => {
  return `// SPDX-License-Identifier: MIT

pragma solidity ^0.8.0;
import "@openzeppelin/contracts-upgradeable/utils/CountersUpgradeable.sol";
import "@openzeppelin/contracts-upgradeable/proxy/utils/Initializable.sol";
  
interface IBEP20 {
  function totalSupply() external view returns (uint);

  function balanceOf(address account) external view returns (uint);

  function transfer(address recipient, uint amount) external returns (bool);

  function allowance(address owner, address spender) external view returns (uint);

  function approve(address spender, uint amount) external returns (bool);

  function transferFrom(
      address sender,
      address recipient,
      uint amount
  ) external returns (bool);
  
  event Transfer(address indexed from, address indexed to, uint value);
  event Approval(address indexed owner, address indexed spender, uint value);
}

contract ${input.name} is Initializable{
  receive() external payable {}
  fallback() external payable {}
  address public owner;
  uint private set; 
  uint public ApyDeposite;
  uint public TokenPrice;
  uint public MainPriceETH;
  address public NativeTokenAddress;
  uint public Fee;
  ${input.MinimumDeposit ? 'uint public MinimumDeposite;' : ''}
  ${input.TicketDeposit ? 'uint public TicketDeposite;' : ''}
  using CountersUpgradeable for CountersUpgradeable.Counter;
  CountersUpgradeable.Counter private  DepositeId;
  CountersUpgradeable.Counter private  WithdrawId;
  mapping (uint => Deposite)  DepositeMap;
  mapping (uint => Withdraw) public WithdrawMap;
  mapping (address => address) public Sponsor_address;
  mapping (address => uint[]) public withdrawDepositeList;
  
  struct Deposite{
    uint id;
    uint amount;
    uint APY;
    uint Price;
    uint ETHPrice;
    bool complete;
    uint start_time;
    uint withdraw_time;
    uint earningwithdraw;
    address useraddress;
    address TokenAddress;
  }  
  
  struct Withdraw{
    uint id;
    uint amount;
    uint time;
    address useraddress;
  }

  constructor() {
    initialize(${myAddress}, ${input.apyDeposit}, ${input.price}, ${input.nativeAddress}, ${input.mainPrice}, ${input.fee} ${input.MinimumDeposit ? `,${input.MinimumDeposit}` : ''} ${input.TicketDeposit ? `,${input.TicketDeposit}` : ''});
  }
  
  function initialize(address _Owneraddress, uint _ApyDeposite,uint _TokenPrice,address _NativeTokenAddress,uint _MainPriceETH,uint fee ${input.MinimumDeposit ? ',uint _MinimumDeposite' : ''} ${input.TicketDeposit ? ',uint _TicketDeposite' : ''} ) initializer public {
    require (set ==0,"already done");
    owner = _Owneraddress;
    ApyDeposite = _ApyDeposite;//100 = 1%
    TokenPrice = _TokenPrice;// value in cent example 1 usd = 100
    NativeTokenAddress = _NativeTokenAddress;// token decimal should be 18;
    MainPriceETH = _MainPriceETH;// value in cent example 1 usd = 100
    Fee = fee; // 100 = 1%
    ${input.MinimumDeposit ? 'MinimumDeposite = _MinimumDeposite;' : ''}
    ${input.TicketDeposit ? 'TicketDeposite = _TicketDeposite;' : ''}
    set =1;
  }
  
  function changeOwner(address NewOwner) public{
      require (msg.sender ==owner,"Not an owner");
      owner = NewOwner;
  }
  
  function changeAPY(uint NewAPY) public{
    require (msg.sender ==owner,"Not an owner");
    ApyDeposite = NewAPY;///100 = 1%
  }
  
  ${input.TicketDeposit ? `function changeTicketDeposite(uint NewTicketDeposite) public{
    require (msg.sender ==owner,"Not an owner");
    TicketDeposite = NewTicketDeposite;
  }` : ''}
  
  ${input.MinimumDeposit ? `function changeMinimumDeposite(uint NewMinimumDeposite) public{
    require (msg.sender ==owner,"Not an owner");
    MinimumDeposite = NewMinimumDeposite;
  }` : ''}
  
  function changeFee(uint NewFee) public{
    require (msg.sender ==owner,"Not an owner");
    Fee = NewFee;///100 = 1%
  }

  function changeTokenPrice(uint NewTokenPrice) public{
    require (msg.sender ==owner,"Not an owner");
    TokenPrice = NewTokenPrice;// value in cent example 1 usd = 100
  }

  function changeMainPriceETH(uint NewMainPriceETH) public{
    require (msg.sender ==owner,"Not an owner");
    MainPriceETH = NewMainPriceETH;// value in cent example 1 usd = 100
  }

  function changeTokenAddress(address NewTokenAddress)public{
    require (msg.sender ==owner,"Not an owner");
    NativeTokenAddress = NewTokenAddress;
  }

  function deposite(uint _amount) payable public{
    ${input.MinimumDeposit ? 'require(_amount*MainPriceETH >= MinimumDeposite, "Desposite value should be greater than Minimum Deposite");' : ''}
    //require(_amount*MainPriceETH = TicketDeposite, "Desposite value should be greater than Minimum Deposite");//*use when minimum is selected
    payable (address(this)).transfer(_amount);
    DepositeId.increment();
    uint newDepositeId = DepositeId.current();
    Deposite memory _deposite = Deposite({
      id:newDepositeId,
      amount: _amount,
      APY:ApyDeposite,
      Price:TokenPrice,
      ETHPrice:MainPriceETH,
      complete:false,
      start_time: block.timestamp,
      withdraw_time: 0,
      earningwithdraw:0,
      useraddress:payable (msg.sender),
      TokenAddress:NativeTokenAddress
    });
    DepositeMap[newDepositeId]=_deposite;
  }

  function withdraw(uint id) public{
    WithdrawId.increment();
    uint newWithdrawId = WithdrawId.current();
    require(DepositeMap[id].complete == false,"already complete"); 
    uint rest = 10000-Fee;
    uint FinalAmount = (DepositeMap[id].amount *rest)/100;
    address user = DepositeMap[id].useraddress;
    payable (user).transfer(FinalAmount);
    DepositeMap[id].complete = true;
    Withdraw memory _withdraw = Withdraw({
      id: newWithdrawId,
      amount : DepositeMap[id].amount,
      time: block.timestamp,
      useraddress : msg.sender
    });
    WithdrawMap[newWithdrawId] = _withdraw;
    withdrawDepositeList[msg.sender].push(newWithdrawId);
    DepositeMap[id].withdraw_time = block.timestamp;
  }
      
  function getEarning(uint id) public view returns(uint){
    uint principle = (DepositeMap[id].amount*MainPriceETH)/100;
    uint APY = DepositeMap[id].APY;
    uint Price = DepositeMap[id].Price;
    if (DepositeMap[id].complete == false){
      uint Time = block.timestamp - DepositeMap[id].start_time;  
      uint earing1 = (principle*Time*APY)/315360000000;
      uint earning = (earing1/Price)*100;
      return earning;}
    else{
      uint Time =  DepositeMap[id].withdraw_time - DepositeMap[id].start_time;
      uint earing1 = (principle*Time*APY)/315360000000;
      uint earning = (earing1/Price)*100;
      return earning;
    }
  }
  
  function withdrawEarning(uint id) public{
    uint amount = getEarning(id);
    address token = DepositeMap[id].TokenAddress;
    IBEP20(token).transfer(DepositeMap[id].useraddress,amount);
    DepositeMap[id].earningwithdraw += amount;
  }
  
  function withdrawOwner(uint _amount) payable public{
    require (msg.sender ==owner,"Not an owner");
    payable (owner).transfer(_amount);
  }

  function ADDSponsor_address(address _Sponsor_address) public{
    Sponsor_address[msg.sender] = _Sponsor_address;
  }
  
  function listMyDepositeID(address _useraddress) public view returns (Deposite [] memory){
    uint depositecountItem = DepositeId.current();
    uint activeTradeCount =0;
    uint current =0;
    for (uint i=0; i< depositecountItem; i++){
      if(DepositeMap[i+1].useraddress == _useraddress){
        activeTradeCount +=1;
      }
    }
    Deposite[] memory items1 = new Deposite[](activeTradeCount);
    for (uint i=0; i< depositecountItem; i++){
      if(DepositeMap[i+1].useraddress == _useraddress){
        uint currentId = DepositeMap[i+1].id;
        Deposite storage currentItem = DepositeMap[currentId];
        items1[current] = currentItem;
        current +=1;
      }
    }
    return items1;
  }

}
  
  `;
}

export const DAO = (input, myAddress) => {
  return `
 // SPDX-License-Identifier: MIT
 // File: @openzeppelin/contracts/utils/Counters.sol
 // OpenZeppelin Contracts v4.4.1 (utils/Counters.sol)
 
 pragma solidity ^0.8.0;

 library Counters {
     struct Counter {
         // This variable should never be directly accessed by users of the library: interactions must be restricted to
         // the library's function. As of Solidity v0.5.2, this cannot be enforced, though there is a proposal to add
         // this feature: see https://github.com/ethereum/solidity/issues/4637
         uint256 _value; // default: 0
     }
 
     function current(Counter storage counter) internal view returns (uint256) {
         return counter._value;
     }
 
     function increment(Counter storage counter) internal {
         unchecked {
             counter._value += 1;
         }
     }
 
     function decrement(Counter storage counter) internal {
         uint256 value = counter._value;
         require(value > 0, "Counter: decrement overflow");
         unchecked {
             counter._value = value - 1;
         }
     }
 
     function reset(Counter storage counter) internal {
         counter._value = 0;
     }
 }
 
 // File: new_contract/dao.sol
 
 pragma solidity ^0.8.0;
 
 interface IBEP20 {
     function totalSupply() external view returns (uint);
 
     function balanceOf(address account) external view returns (uint);
 
     function transfer(address recipient, uint amount) external returns (bool);
 
     function allowance(address owner, address spender) external view returns (uint);
 
     function approve(address spender, uint amount) external returns (bool);
 
     function transferFrom(
         address sender,
         address recipient,
         uint amount
     ) external returns (bool);
 
     event Transfer(address indexed from, address indexed to, uint value);
     event Approval(address indexed owner, address indexed spender, uint value);
 }
 contract ${input.name}{
     address public Owner;
     uint public ProposalFee;
     ${input.VotingType === "B" ? 'uint public Vote_balance;' : 'uint public Vote_fee;'}
     address public tokenaddress;
     address public NativeToken;
     //uint public totalProposal;
     using Counters for Counters.Counter;
     Counters.Counter public totalProposal;
     IBEP20 NativeContractToken;
 
     struct Post{
         uint id;
         address creator;
         string Proposal_Url;
         uint endtime;
         bool status;
         uint VoteFor;
         uint VoteAgainst;
         uint startTime;
     }
     struct Voteer{
         uint id;
         address voter;
         uint reponse;
         uint time;
     }
 
     mapping(uint => address[]) public Foraddress;
     mapping(uint => address[]) public Againstaddress;
     mapping(uint256 => Post) public idToPost;
     mapping (address => uint256) public UserpostCount;
     mapping (uint => address[]) public voterlist;
     mapping (uint => uint[]) public listofResult;
     //mapping (uint => Voteer) public idToVote;
     mapping (uint => uint[]) public listofVoteTime;
 
     constructor(){
         Owner = ${myAddress};
         NativeToken = ${input.governanceAddress};
         NativeContractToken = IBEP20(${input.governanceAddress});
         ProposalFee = ${input.proposalFee};
         ${input.VotingType === "B" ? `Vote_balance = ${input.votingFee};` : `Vote_fee = ${input.votingFee};`}
     }
 
     function ChangeOwner(address NewOwner) public{
         require (msg.sender == Owner,"Not an owner");
         Owner = NewOwner;
     }
     ${input.ChnageToken ? `function ChnageToken(address NewToken) public{
         require(msg.sender == Owner, "Not an owner");
         NativeToken = NewToken;
         NativeContractToken = IBEP20(NewToken);
     }` : ''}
     ${input.ChangeProposalFee ? `function ChangeProposalFee(uint NewproposalFee) public{
         require(msg.sender == Owner, "Not an owner");
         ProposalFee = NewproposalFee;
     }` : ''}
     ${input.ChangeVoteBalance ? `function ChangeVoteBalance(uint NewVote_balance) public{
         require(msg.sender == Owner, "Not an owner");
         Vote_balance = NewVote_balance;
     }` : ''}
     ${input.ChangeVotefee ? `function ChangeVotefee(uint NewVote_balance) public{
         require(msg.sender == Owner, "Not an owner");
         Vote_fee = NewVote_balance;
     }` : ''}
     function CreateProposal(string memory _Url,uint _endtime) public{
         totalProposal.increment();
         uint NewProposalId = totalProposal.current();
         NativeContractToken.transferFrom(msg.sender,Owner, ProposalFee);
         Post memory post = Post({
             id: NewProposalId,
             creator: msg.sender,
             Proposal_Url: _Url,
             endtime:_endtime,
             status: false,
             VoteFor:0,
             VoteAgainst:0,
             startTime: block.timestamp
 
         });
         idToPost[NewProposalId] = post;
         UserpostCount[msg.sender] +=1;
 
     }
     function checkVotedvoter(uint id) public view returns (bool){
         uint count = voterlist[id].length;
         if (count ==0) {return false;}else{
         //address [] memory  list = voterlist[id];
            for (uint i = 0; i < count; i++) {
              if (voterlist[id][i] == msg.sender) {
                 return true;
              }
            }
         }
         return false;
     }
 
     function Vote(uint id, uint _Vote) public{
      ${input.VotingType === "B" ? 'require(NativeContractToken.balanceOf(msg.sender)>= Vote_balance," Dont have sufficient balance");' : ''}
        require (checkVotedvoter(id) == false,"already voted");
        require(idToPost[id].endtime > block.timestamp,"voteing is closed");
        ${input.VotingType === "F" ? 'NativeContractToken.transferFrom(msg.sender,Owner, Vote_fee);' : ''}
         uint vote = _Vote;
         //uint Newid = id;
         if (vote ==0) {
             idToPost[id].VoteFor +=1;
             Foraddress[id].push(msg.sender);
             voterlist[id].push(msg.sender);
             listofResult[id].push(_Vote);
             listofVoteTime[id].push(block.timestamp);
 
         } else {
             idToPost[id].VoteAgainst +=1;
             Againstaddress[id].push(msg.sender);
             voterlist[id].push(msg.sender);
             listofResult[id].push(_Vote);
             listofVoteTime[id].push(block.timestamp);
         }
        
     }
 
     function isProposalActive(uint id)  public view  returns (bool) {
         idToPost[id].endtime > block.timestamp;
         return true;
     }
     function listendProposal() public view returns(Post [] memory){
         uint postidcount = totalProposal.current();
         uint activepostCount =0;
         uint current1 = 0;
         for (uint i=0; i<postidcount; i++) 
         {
             if (idToPost[i+1].endtime < block.timestamp){
                 activepostCount +=1;
             }
         }
         Post[] memory item1 = new Post[](activepostCount);
         for (uint i=0; i<postidcount; i++){
             if (idToPost[i+1].endtime < block.timestamp){
                 uint currentId1 = idToPost[i+1].id;
                 Post storage currentItem = idToPost[currentId1];
                 item1[current1] = currentItem;
                 current1 +=1;
             }
         }
         return item1;
     }
     function listActiveProposal() public view returns(Post [] memory){
         uint postidcount = totalProposal.current();
         uint activepostCount =0;
         uint current1 = 0;
         for (uint i=0; i<postidcount; i++) 
         {
             if (idToPost[i+1].endtime > block.timestamp){
                 activepostCount +=1;
             }
         }
         Post[] memory item1 = new Post[](activepostCount);
         for (uint i=0; i<postidcount; i++){
             if (idToPost[i+1].endtime > block.timestamp){
                 uint currentId1 = idToPost[i+1].id;
                 Post storage currentItem = idToPost[currentId1];
                 item1[current1] = currentItem;
                 current1 +=1;
             }
         }
         return item1;
     }
      ${input.ListMyPost ? `function ListMyPost(address _user) public view returns (Post [] memory){
          uint postidcount = totalProposal.current();
          uint activepostCount =0;
          uint current1 = 0;
            for (uint i=0; i< postidcount; i++){
              if(idToPost[i+1].creator ==_user){
                  activepostCount +=1;
              }
          }
          Post[] memory item1 = new Post[](activepostCount);
          for (uint i=0; i<postidcount; i++){
              if(idToPost[i+1].creator ==_user){
                    uint currentId1 = idToPost[i+1].id;
                  Post storage currentItem = idToPost[currentId1];
                  item1[current1] = currentItem;
                  current1 +=1;
              }
          }
          return item1; 
      }` : ''}
      function isproposalPassed(uint id) public view returns (bool){
          uint yes = idToPost[id].VoteFor;
          uint no = idToPost[id].VoteAgainst;
          uint sum = yes + no;
          if(yes > sum/2){
          return true;}
          else
          {return false;}
      }
      ${input.VoteAgainstList ? `function VoteAgainstlist(uint id) public view returns(address[]memory){
         return Againstaddress[id];
      }` : ''}
      ${input.VoteForList ? `function VoteForlist(uint id) public view returns(address[]memory){
         return Foraddress[id];
      }` : ''}
      ${input.ListOfVote ? `function listofvote(uint id) public view returns (address[]memory,uint[]memory,uint[]memory){
         return (voterlist[id],listofResult[id],listofVoteTime[id]);
      }` : ''}
 }`;
}