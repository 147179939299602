import useFetch from '../utils/hooks/useFetch';

export default function NotificationPopup() {
  const { data, loading } = useFetch("/project/project-creation");

  return (
    <div className="btn-group ms-auto ">
      <button className="p-0 btn dropdown-toggle border-0 notify" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        {/* <span className="position-absolute rounded-circle dot" /> */}
        <img src="../assets/images/icons/notification.svg" alt="Bell icon" />
      </button>
      <div className="dropdown-menu dropdown-menu-end notifications">
        <h2>Notifications</h2>
        {
          data?.map((ele, index) => (
            <div key={index} className="notifications-item">
              <img src="../assets/images/icons/bar-chart-icon.svg" style={{borderRadius: 0, height: '30px'}} alt="img" />
              <div className="text">
                <h4>New Project Added</h4>
                <p>{ele.name} | Status: {ele.project_status === "in_progress" ? "Ingrogress" : (ele.project_status === "verified" ? "Verified" : "Deployed")}.</p>
              </div>
            </div>

          ))
        }
        {/* <div className="notifications-item">
          <img src="https://img.icons8.com/flat_round/64/000000/vote-badge.png" alt="img" />
          <div className="text">
            <h4>John Silvester</h4>
            <p>+20 vista badge earned</p>
          </div>
        </div> */}
      </div>
    </div>
  )
}
