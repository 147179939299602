import React, { useState } from 'react'
import TopRightProfile from '../../TopRightProfile'
import { axiosClientPy } from '../../../utils';
import { toast } from 'react-toastify';

export default function CreateProjectMarketplace721Step4({ projectData }) {
  const [input, setInput] = useState({
    GetMarketListing: true,
    GetMyListedAssets: true,
    GetMyAssets: true,
    ListTheNFT: true,
    BuyNFT: true,
    AutoIncrementalIds: true,
  })

  const onSubmit = async (e) => {
    e.preventDefault();
    const headers = {
      Authorization: "Token " + window.sessionStorage.getItem("USER_TOKEN")
    }
    try {
      let typeRes = await axiosClientPy.get("/main/project-function", { headers });
      let selectedType = typeRes?.data?.data;
      selectedType = selectedType?.filter(ele => input[ele.name]);
      const mData = {
        "project_id": projectData?.uuid,
        "step_count": 7
      }
      const res = await axiosClientPy.patch("/project/project-creation", mData, { headers });
      window.location.reload();
    } catch (error) {
      toast.error("Internal Server Error!!!")
    }
  }
  return (
    <section className="pe-0 main-content">
      <div className="pt-0 main-content-inner box-steps--illustration">
        <TopRightProfile />
        {/*----- Completion stats row -----*/}
        <div className="primary-box completion-row completion-row--back">
          <div className="completion-row__left">
            <a href="/new-project" className="redirect-back">
              <img src="../assets/images/icons/back-arrow.svg" alt="Back arrow" />
            </a>
            <div className>
              <h1 className="fw600 text-white">Marketplace 721</h1>
              <p className="m-0 grayText">
                Merging technology to remove third parties and centralized
                institutions from financial transactions.
              </p>
            </div>
          </div>
          <div className="completion-row__right">
            <img className="logo-crypto" src="../assets/images/defi-logo.svg" alt="" />
          </div>
        </div>
        {/* ============================== */}
        <div className="box-steps">
          <h2 className="text-center mb-0 fst-italic fw700">
            Standard Functions For Your Marketplace 721
          </h2>
          <p className="text-center grayText--lighter">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vehicula
            massa in enim luctus. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Vehicula massa in enim luctus. Rutrum arcu.
          </p>
          <div className="w-100 form-box form-box--purple">
            <form onSubmit={onSubmit} className="form">
              <div className='top'>
                <div className="fw600 m-0 form-group form-group--checkbox">
                  <input id="one" type="checkbox" checked={input.GetMarketListing} disabled />
                  <label htmlFor="one">
                    <b />
                    Get Market Listing
                  </label>
                </div>
                <div className="mt-3 fw600 m-0 form-group form-group--checkbox">
                  <input id="one" type="checkbox" checked={input.GetMyListedAssets} disabled />
                  <label htmlFor="one">
                    <b />
                    Get My Listed Assets
                  </label>
                </div>
                <div className="mt-3 fw600 m-0 form-group form-group--checkbox">
                  <input id="one" type="checkbox" checked={input.GetMyAssets} disabled />
                  <label htmlFor="one">
                    <b />
                    Get My Assets
                  </label>
                </div>
                <div className="mt-3 fw600 m-0 form-group form-group--checkbox">
                  <input id="one" type="checkbox" checked={input.ListTheNFT} disabled />
                  <label htmlFor="one">
                    <b />
                    List The NFT
                  </label>
                </div>
                <div className="mt-3 fw600 m-0 form-group form-group--checkbox">
                  <input id="one" type="checkbox" checked={input.BuyNFT} disabled />
                  <label htmlFor="one">
                    <b />
                    Buy NFT
                  </label>
                </div>
                <div className="mt-3 fw600 m-0 form-group form-group--checkbox">
                  <input id="one" type="checkbox" checked={input.AutoIncrementalIds} disabled />
                  <label htmlFor="one">
                    <b />
                    Auto Incremental Ids
                  </label>
                </div>
              </div>
              <div className="btnGroup mt-5">
                <button type="submit" className="btn rounded-0 primary-box btn--primary">
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}